export default function CollectionFolder({ ...props }: { props?: Record<string, unknown> }): JSX.Element {
  return (
    <svg width="205" height="205" viewBox="0 0 205 205" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.8"
        d="M198.592 32.0312C198.592 30.3322 197.917 28.7028 196.716 27.5013C195.515 26.2999 193.885 25.625 192.186 25.625H12.8137C11.1147 25.625 9.48525 26.2999 8.28386 27.5013C7.08247 28.7028 6.40754 30.3322 6.40754 32.0312V38.4375H198.592V32.0312ZM192.186 6.40625C192.186 4.70721 191.511 3.07775 190.31 1.87635C189.109 0.674942 187.479 0 185.78 0L19.2199 0C17.5208 0 15.8914 0.674942 14.69 1.87635C13.4886 3.07775 12.8137 4.70721 12.8137 6.40625V12.8125H192.186V6.40625ZM192.186 51.25H12.8137C5.28246 51.25 -0.627226 58.3049 0.0534289 66.4929L10.5355 192.288C11.1361 199.495 16.6694 205 23.2958 205H181.704C188.331 205 193.864 199.487 194.46 192.288L204.947 66.4929C205.627 58.3049 199.722 51.25 192.186 51.25ZM181.692 191.227C181.644 191.815 181.416 192.151 181.612 192.188L23.7322 192.296C23.4609 192.004 23.3081 191.621 23.3038 191.223L12.8137 65.4278C12.7416 64.591 13.082 64.0985 12.8617 64.0625H191.834C192.105 64.463 192.221 64.948 192.162 65.4278L181.692 191.227Z"
        fill="#E6E6E6"
      />
    </svg>
  );
}
