import React, { ReactNode, useState } from 'react';
import {
  IconButton,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Link,
  TableCellProps,
  styled,
  Accordion,
  AccordionSummary,
} from '@mui/material';
import { ExpandMore, InfoOutlined } from '@mui/icons-material';
import { StyledDialog, DialogTitle, DialogActions, DialogContent } from './UI/Dialog';

const StyledIconButton = styled(IconButton)(() => ({
  color: 'var(--nz-white-95)',
  marginRight: '-16px',
}));

const StyledBox = styled(Box)(() => ({
  padding: '32px',

  '& > p': {
    paddingBottom: '22px',
  },
}));

type AboutTableRow = ReactNode[];

const getCellProps = (defaultCellCount: number, currentCellCount: number, columnIndex: number): TableCellProps => {
  if (currentCellCount < defaultCellCount && columnIndex > 0) {
    const collumnSpan = defaultCellCount - 1;
    return { align: 'center', colSpan: collumnSpan };
  }

  return { align: 'left', style: { width: `calc(100% / ${defaultCellCount})` } };
};

const AboutTable = ({ rows, title, notes }: { rows: AboutTableRow[]; title?: ReactNode; notes?: ReactNode }) => (
  <Box mb={3}>
    {!!title && title}
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {rows.map((row, tabIndex) => (
            <TableRow key={tabIndex}>
              {row.map((rowData, columnIndex) => (
                <TableCell
                  key={tabIndex + '-' + columnIndex}
                  {...getCellProps(rows[0].length, row.length, columnIndex)}
                >
                  {rowData}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {!!notes && notes}
  </Box>
);

interface Props {
  open?: boolean;
  onClose?: () => void;
  hiddenIcon?: boolean;
}

const AboutLaundryLabDialog: React.FC<Props> = ({ open = false, onClose, hiddenIcon = false }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const toggleDialog = () => {
    if (open) {
      if (onClose) {
        onClose();
      }
    } else {
      setOpen(!isOpen);
    }
  };

  return (
    <>
      {!hiddenIcon && (
        <StyledIconButton onClick={toggleDialog}>
          <InfoOutlined color="inherit" />
        </StyledIconButton>
      )}
      <StyledDialog
        open={isOpen || open}
        onClose={toggleDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        // maxWidth={'md'}
        PaperProps={{
          style: {
            maxWidth: 960,
          },
        }}
      >
        <DialogTitle onClose={toggleDialog}>About LaundryLab®</DialogTitle>
        <DialogContent>
          <StyledBox>
            <Typography variant="body1" component="p">
              Welcome to LaundryLab® – an advanced simulation software to predict performance of consumer laundry
              formulations and calculate their associated costs and sustainability profiles.
            </Typography>
            <Typography variant="body1" component="p">
              LaundryLab can be used as a screening tool to select and validate in the lab only the most optimal
              formulations to cover your needs, allowing for significant savings in washing resources and faster time to
              market.
            </Typography>
            <Typography variant="body1" component="p">
              More on the{' '}
              <Link underline="hover" href="https://biosolutions.novozymes.com/en/laundry/insights/laundrylab">
                insights
              </Link>{' '}
              achieved with LaundryLab.
            </Typography>
            <Typography variant="body1" component="p">
              The first step when using LaundryLab is creating a collection. A collection is a group of formulations
              that have a common purpose for you as a user. When you create a collection, you need to define the region
              that represent the washing conditions you want to build your formulations from. Selecting the region
              defines the simulation model that will be used for the wash predictions of the formulations you will
              create in that collection.
            </Typography>
            <Typography variant="body1" component="p">
              Once you create your own formulations in the collection, you can choose any of your created formulations
              as baseline as well. For some regions, LaundryLab contains some model formulations preloaded that you may
              use as starting point (baseline) for your reformulation exercise. These model formulations represent
              formulations with concentrations of the ingredients represented in LaundryLab similar to those of
              commercial formulations based on publicly available sources. Commercial detergents and more complex
              formulations will naturally have a different performance than the performance predicted by LaundryLab.
            </Typography>
            <Typography variant="body1" component="p">
              It is recommended to use LaundryLab as a tool to compare different formulations and rely on the comparison
              trends. Absolute cleaning performance values are dependent on the exact composition of detergents, and
              therefore the absolute numbers predicted by LaundryLab must be taken with care.
            </Typography>
            <Typography variant="body1" component="p">
              All the information provided by LaundryLab is indicative only and should not substitute validation with
              real washes and any other assessment needed for compliance of final products.
            </Typography>
            <Typography variant="body1" component="p">
              For more information, questions or comments please contact{' '}
              <Link underline="hover" href="mailto:yse@novonesis.com">
                Yushu Shen Drehn
              </Link>
              .
            </Typography>

            <Typography variant="h2">Stain removal section</Typography>

            <Typography variant="body1" component="p">
              The stain removal performance values displayed in LaundryLab are obtained from a simulation model that has
              been built with the application of machine learning to experimental wash data in combination with
              Novonesis knowledge. The model is updated regularly with new wash data and the newest machine learning
              techniques. The model makes a prediction for each individual stain and includes a random component to
              account for the experimental variation in the underlying experiments as well as the uncertainty of the
              simulation.
            </Typography>
            <Typography variant="body1" component="p">
              When simulating cleaning performance of a detergent formulation, a set of samples is extracted from the
              model and a normal distribution is fitted to that set. The final simulated value for a stain and
              formulation is the mean of the fitted normal distribution. The error bars are given by the +/-2 * Standard
              Deviation of the fitted normal distribution.
            </Typography>
            <Typography variant="body1" component="p">
              The aggregated bars for the different stain groups represent the sum of all the individual means of each
              stain. Likewise, the error bars are the sum of all the individual error bars of the stains in that group.
            </Typography>

            <Typography variant="h2">Cost breakdown section</Typography>

            <Typography variant="body1" component="p">
              The formulation costs represent the total cost of that formulation (cost per tonne of formulation) based
              only on the prices of the ingredients that are available in LaundryLab when creating formulations.
            </Typography>
            <Typography variant="body1" component="p">
              LaundryLab contains default regional market prices for surfactants, citrate, and soap (based on 100 % pure
              ingredient). These prices are updated 1-4 times per year and are obtained through publicly available
              sources. Default regional market prices can be changed at any time by the user. Default enzyme prices are
              not available in LaundryLab, and the user can add the prices at any time during the reformulation
              exercise.
            </Typography>
            <Typography variant="body1" component="p">
              Formulation costs are given at the currency defined by the region chosen when creating a collection.
            </Typography>

            <Typography variant="h2">Sustainability section</Typography>
            <Typography variant="body1" component="p">
              LaundryLab’s sustainability figures are based on generic literature data and are calculated considering
              only the detergent ingredients that are available in LaundryLab to create formulations.
            </Typography>
            <Typography variant="body1" component="p">
              Results are indicative only and not intended for use in any form of public communication.
            </Typography>
            <Typography variant="body1" component="p">
              The sustainability figures are shown as yearly figures based on the regional consumption of the detergent.
            </Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <strong>Table 1.</strong> Assumptions basis for sustainability figure calculation
                </Typography>
              </AccordionSummary>

              <AboutTable
                rows={[
                  [
                    'Region',
                    'No. households',
                    'Source',
                    'Weekly washes per household',
                    'Source',
                    'Water volume (main wash)',
                  ],
                  ['Europe', '195,000,000', 'Eurostat, 2020', '5', 'Not documented estimate', '15.6 L (Front loader)'],
                  [
                    'China',
                    '456,000,000',
                    'National Bureau of Statistics, 2013',
                    '5',
                    'Not documented estimate',
                    '37 L (Top loader)',
                  ],
                  [
                    'North America',
                    '125,000,000',
                    'Census Bureau, 2019',
                    '8',
                    'Not documented estimate',
                    '65 L (Top loader)',
                  ],
                  [
                    'Latin America',
                    '170,000,000',
                    'United Nation Population Division, 2020',
                    '3',
                    'Not documented estimate',
                    '56 L (Top loader)',
                  ],
                  ['Africa', '243,200,000', 'Statistica, 2022', '3', 'Not documented estimate', '10 L (Hand wash)'],
                  [
                    'South East Asia',
                    '136,542,137',
                    'Worldometer, 2022',
                    '3',
                    'Not documented estimate',
                    '40 L (Top loader)',
                  ],
                  [
                    <>
                      India<sup>a</sup>
                    </>,
                    'Not relevant',
                    'Not relevant',
                    'Not relevant',
                    'Not relevant',
                    'Not relevant',
                  ],
                  [
                    'Middle East',
                    '125,876,998',
                    'Not documented estimate',
                    '3',
                    'Not documented estimate',
                    '20 L (Front loader)',
                  ],
                ]}
              />

              <em>
                a. For India, the sustainability calculation is based on the regional production (powder volume) at 3
                mil. tonne / year (2022). Hence household #, weekly wash # and wash volume are not relevant for the
                calculation here.
              </em>

              <br />
              <br />

              <Typography variant="body1" component="p">
                CO2, in million tonnes emitted in the selected region per year:
                <br />
                CO2 emission calculations are based on lifecycle assessment (LCA) principles. Estimation of the impact
                from enzymes is specific to Novozymes’ products and refer to Nielsen et al. (2007) (
                <Link
                  underline="hover"
                  href="https://www.novozymes.com/-/media/Project/Novozymes/Website/website/document-library/LCAs/CradletogateenvironmentalassessmentofenzymeproductsproducedindustriallyinDenmarkbyNovozymesAS.pdf?la=en"
                >
                  link
                </Link>
                ). CO2 emission data on other detergent ingredients refer to publicly available databases.
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                Chemicals, in million tonnes consumed in the selected region per year:
                <br />
                Chemicals refer to the mass of chemicals present in the detergent. Mass of enzyme products in the
                detergent is included in the estimate.
              </Typography>
              <br />
              <Typography variant="body1" component="p">
                Critical Dilution Volume (CDV), in billion m3 water in the selected region per year:
                <br />
                CDV refers CDVchronic which referes to the amount of clean water needed to dilute detergent ingredients
                to a level where they do not pose a risk to water living species (daphnia, algae and fish). Both enzymes
                and surfactants are degraded in sewer systems and in wastewater treatment plants and calculated CDV
                values are potential theoretical numbers intended for relative comparison only.
                <br />
                CDV calculations are based on principles and data from European Union Ecolabel based on chronic data
                from the Detergent Ingredient Database (
                <Link
                  underline="hover"
                  href="https://ec.europa.eu/environment/ecolabel/documents/DID%20List%20PART%20A%202016%20FINAL.pdf"
                >
                  DID list
                </Link>
                ).
                <br />
                More details about calculations and interpretation of climate impact and CDV can be found here:{' '}
                <Link
                  underline="hover"
                  href="https://biosolutions.novozymes.com/en/laundry/biological-detergent/insights/whitepaper"
                >
                  https://biosolutions.novozymes.com/en/laundry/biological-detergent/insights/whitepaper
                </Link>
              </Typography>
            </Accordion>
            <br />

            <Typography variant="h2">EU Ecolabel section</Typography>
            <Typography variant="body1" component="p">
              The EU Ecolabel section gives an indication of whether the formulations created in LaundryLab meet the
              criteria where LaundryLab is applicable. The purpose of this section is for guidance only, real
              formulations might have a valuation different than the proposed valuation by LaundryLab. Results are not
              intended for use in any form of public communication.
            </Typography>
            <Typography variant="body1" component="p">
              The calculations are based on “EU Ecolabel for detergents and cleaning products. User Manual”, October
              2018, Version 1.2. (
              <Link
                underline="hover"
                href="https://ec.europa.eu/environment/ecolabel/documents/DETERGENTS_User_Manual_V1.1_Jan_2018.pdf"
              >
                link
              </Link>
              ) and the accompanying DID list for the ingredients’ environmental properties (
              <Link
                underline="hover"
                href="https://ec.europa.eu/environment/ecolabel/documents/DID%20List%20PART%20A%202016%20FINAL.pdf"
              >
                link
              </Link>
              )
            </Typography>
            <Typography variant="body1" component="p">
              For this purpose, all calculations use as reference heavy-duty detergents, color safe detergents at the
              wash conditions covered by LaundryLab.
            </Typography>
            <Typography variant="h4">Criterion 1 – dosage requirements</Typography>
            <Typography variant="body1" component="p">
              The reference dosage of a heavy-duty detergent, color-safe detergent shall not exceed 16 g/kg of laundry
              for heavy-duty detergents, color-safe detergents.
            </Typography>
            <Typography variant="h4">Criterion 2 – toxicity to aquatic organisms</Typography>
            <Typography variant="body1" component="p">
              The critical dilution volume (CDVchronic) of a heavy-duty detergent, color-safe detergent shall not exceed
              31,500 l/kg of laundry for the reference dosage. The CDVchronic calculation are based on principles and
              data from the EU Ecolabel based on chronic data from the Detergent Ingredient Database.
            </Typography>
            <Typography variant="h4">Criterion 3 – biodegradability</Typography>
            <Typography variant="body1" component="p">
              Ingredient environmental properties can be found on the DID list. All the ingredients available to create
              formulations in LaundryLab are readily degradable (aerobically) according to the DID list.
            </Typography>
            <Typography variant="body1" component="p">
              LAS, however, is considered not to meet the biodegradability criteria since it is classified as hazardous
              to the aquatic environment (aquatic chronic 3; H412;{' '}
              <Link underline="hover" href="https://echa.europa.eu/da/brief-profile/-/briefprofile/100.079.513">
                ECHA
              </Link>
              ) and is not biodegradable under anaerobic conditions according to the DID list.
            </Typography>
          </StyledBox>
        </DialogContent>
        <DialogActions style={{ margin: '0', padding: '12px 24px', background: 'white' }}>
          <Button onClick={toggleDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default AboutLaundryLabDialog;
