import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, InputAdornment, styled } from '@mui/material';

import Slider from '@mui/material/Slider';

const StyledTypography = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '20px',
  color: 'var(--nz-black-47)',
  marginBottom: '-5px',
});

const StyledTextField = styled(TextField)({
  color: 'var(--nz-black-67)',
  fontWeight: 'bold',
  textAlign: 'right',
  width: '80px',
  marginLeft: '20px',
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& .MuiInput-input.Mui-disabled': {
    color: 'rgba(0,0,0,0.67)',
    width: '40px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  '& .Mui-disabled:before': {
    borderBottom: 'none !important',
    borderBottomStyle: 'hidden',
  },
  '& .MuiInput-root:before': {
    borderBottom: 'none',
  },
});

const StyledLabel = styled('label')({
  fontSize: '1rem',
  fontWeight: 'bold',
});

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#C5DA00',
  '& :hover': {
    boxShadow: '0px 0px 0px 8px #C5DA0028',
  },
  '& [class^="PrivateValueLabel-label"]': {
    color: 'rgba(0, 0, 0, 0.87);',
  },
  '& .MuiSlider-active': {
    boxShadow: '0px 0px 0px 8px #C5DA0028',
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.grey[400],
  },
  '& .MuiSlider-mark': {
    height: 10,
    top: 'calc(50% - 5px)',
    backgroundColor: theme.palette.grey[600],
  },
  '& .MuiSlider-markActive': {
    backgroundColor: theme.palette.grey[600],
  },
  '& .MuiSlider-markLabel': {
    fontSize: '12px',
  },
}));

interface Props {
  value: number;
  min: number;
  max: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  interval?: number;
  displayInterval?: boolean;
  label?: string;
  legend?: string;
  step?: number;
}

const DoseSlider: React.FunctionComponent<Props> = ({
  value,
  min = 30,
  max = 70,
  onChange,
  label,
  legend,
  interval = 5,
  step = 5,
  displayInterval = true,
  ...props
}: Props) => {
  const doseMarks = Array(Math.round((max + interval - min) / interval))
    .fill('')
    .map((_, i) => min + i * interval)
    .map((v) => ({
      value: v,
      label: v,
    }));

  const [sliderValue, setSliderValue] = useState(value);
  const [dosageInputValue, setDosageInputValue] = useState(value);

  const handleValueUpdating = (value: string | number) => {
    let newValue = parseFloat(String(value));
    const roundupVal = newValue % step ? step - (newValue % step) : 0; // round up to the next 'step' using the module
    newValue = newValue + roundupVal;
    if (newValue > max) {
      newValue = max;
    } else if (newValue < min) {
      newValue = min;
    }

    if (onChange) {
      onChange(newValue);
    }
    setSliderValue(newValue);
    setDosageInputValue(newValue);
  };

  const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    handleValueUpdating(Number(event.target.value));
  };

  const handleTempChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    setDosageInputValue(Number(event.target.value));
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (props.disabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    setSliderValue(newValue);
  };

  const handleSliderCommitChange = (event: any, value: number | number[]) => {
    if (props.disabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    handleValueUpdating(newValue);
  };

  return (
    <Box p={0}>
      <Grid container item alignItems="center">
        <StyledLabel>{label}</StyledLabel>
        <StyledTextField
          value={String(dosageInputValue)}
          id="dosage-number"
          name="dosageNumber"
          variant="standard"
          disabled={true}
          onBlur={handleChange}
          onChange={handleTempChange}
          InputProps={{
            inputProps: { min: min, max: max },
            endAdornment: (
              <InputAdornment position="end">
                <span style={{ fontWeight: 'bold', marginLeft: '-1px' }}>{legend}</span>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {!displayInterval && (
        <Grid container item xs={12} alignItems="baseline" justifyContent="space-between">
          <StyledTypography>{min}</StyledTypography>
          <StyledTypography>{max}</StyledTypography>
        </Grid>
      )}
      <StyledSlider
        value={sliderValue}
        marks={displayInterval ? doseMarks : undefined}
        step={step}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        onChange={onChange ? handleSliderChange : undefined}
        onChangeCommitted={handleSliderCommitChange}
        {...props}
      />
      <Grid container justifyContent="center">
        <Typography align="center" style={{ fontSize: '14px' }}>
          {legend}
        </Typography>
      </Grid>
    </Box>
  );
};

export default DoseSlider;
