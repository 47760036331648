import React from 'react';
import { Typography, Box, Grid, styled } from '@mui/material';
import { AboutLaundryLab } from '@novozymes-digital/laundry-lab/components/AboutLaundryLab';
import { SSOSignIn } from './SSOSignIn';
import Footer from '@novozymes-digital/laundry-lab/components/UI/footer/Footer';

const StyledGrid = styled(Grid)(() => ({
  minHeight: 'calc(100vh - 45px)',
  width: '100%',
  margin: 0,
  padding: 0,
  border: 0,
}));

const StyledBox = styled(Box)(() => ({
  flexGrow: 1,
  width: '500px',
  minHeight: '500px',
  backgroundColor: '#eefff0',
  boxSizing: 'border-box',
}));

const StyledInsideBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  width: '400px',
  margin: 'auto',
  height: '100%',
  paddingTop: '30px',
}));

const StyledContainer = styled('div')(() => ({
  '& p': {
    fontSize: '0.75rem !important',
    fontFamily: 'RaleGroteskBase !important',
  },
}));

export const SSOAuthPage: React.FC = () => {
  return (
    <StyledGrid container spacing={0} alignContent="center" justifyContent="center">
      <StyledGrid item md={6} xs={12}>
        <AboutLaundryLab />
      </StyledGrid>
      <StyledGrid item md={6} xs={12}>
        <StyledGrid container alignContent="center" justifyContent="center">
          <Grid item>
            <StyledBox boxShadow={0}>
              <StyledInsideBox>
                <Typography variant="h3">Sign in</Typography>
                <SSOSignIn />
              </StyledInsideBox>
            </StyledBox>
          </Grid>
        </StyledGrid>
      </StyledGrid>
      <Grid item xs={12}>
        <StyledContainer>
          <Footer />
        </StyledContainer>
      </Grid>
    </StyledGrid>
  );
};
