import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '16px',
  height: '16px',
}));

export default function CollectionPinSeCollectionPinUnselectedlected(): JSX.Element {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.03125 5.28125L6.79688 3.5H7.6875C7.99219 3.5 8.25 3.26562 8.25 2.9375V1.0625C8.25 0.757812 7.99219 0.5 7.6875 0.5H1.3125C0.984375 0.5 0.75 0.757812 0.75 1.0625V2.9375C0.75 3.26562 0.984375 3.5 1.3125 3.5H2.17969L1.94531 5.28125C0.796875 5.9375 0 7.03906 0 8.375C0 8.5625 0.164062 8.75 0.375 8.75H4.125V11.5625C4.125 11.5859 4.125 11.6094 4.125 11.6094L4.3125 12.3594C4.35938 12.5469 4.61719 12.5469 4.66406 12.3594L4.85156 11.6094C4.85156 11.6094 4.85156 11.5859 4.85156 11.5625V8.75H8.60156C8.8125 8.75 8.97656 8.5625 8.97656 8.375C9 7.03906 8.17969 5.9375 7.03125 5.28125ZM0.773438 8C0.9375 6.99219 1.6875 6.19531 2.625 5.77344L3 2.75H1.5V1.25H7.5V2.75H6L6.35156 5.77344C7.28906 6.19531 8.0625 6.99219 8.20312 8H0.773438Z"
          fill="black"
          fillOpacity="0.8"
        />
      </svg>
    </StyledGrid>
  );
}
