const APP_PREFIX = '@app';
const appActions = {
  APP_INIT: `${APP_PREFIX}/INIT`,
  LOGIN_INIT: `${APP_PREFIX}/LOGIN_INIT`,
  FETCH_USER_COLLECTIONS: `${APP_PREFIX}/FETCH_USER_COLLECTIONS`,
  FETCH_USER_COLLECTIONS_RESULT: `${APP_PREFIX}/FETCH_USER_COLLECTIONS_RESULT`,
  SELECT_COLLECTION: `${APP_PREFIX}/SELECT_COLLECTION`,
  CREATE_COLLECTION: `${APP_PREFIX}/CREATE_COLLECTION`,
  CREATE_COLLECTION_RESULT: `${APP_PREFIX}/CREATE_COLLECTION_RESULT`,
  UPDATE_COLLECTION: `${APP_PREFIX}/UPDATE_COLLECTION`,
  UPDATE_COLLECTION_RESULT: `${APP_PREFIX}/UPDATE_COLLECTION_RESULT`,
  PIN_COLLECTION: `${APP_PREFIX}/PIN_COLLECTION`,
  PIN_COLLECTION_RESULT: `${APP_PREFIX}/PIN_COLLECTION_RESULT`,
  DELETE_COLLECTION: `${APP_PREFIX}/DELETE_COLLECTION`,
  DELETE_COLLECTION_RESULT: `${APP_PREFIX}/DELETE_COLLECTION_RESULT`,
  CLONE_COLLECTION: `${APP_PREFIX}/CLONE_COLLECTION`,
  CLONE_COLLECTION_RESULT: `${APP_PREFIX}/CLONE_COLLECTION_RESULT`,
  SEND_COLLECTION: `${APP_PREFIX}/SEND_COLLECTION`,
  SEND_COLLECTION_RESULT: `${APP_PREFIX}/SEND_COLLECTION_RESULT`,
  CLONE_FORMULATION: `${APP_PREFIX}/CLONE_FORMULATION`,
  CLONE_FORMULATION_RESULT: `${APP_PREFIX}/CLONE_FORMULATION_RESULT`,
  DELETE_FORMULATION: `${APP_PREFIX}/DELETE_FORMULATION`,
  DELETE_FORMULATION_RESULT: `${APP_PREFIX}/DELETE_FORMULATION_RESULT`,
  FETCH_FORMULATIONS: `${APP_PREFIX}/FETCH_FORMULATIONS`,
  FETCH_FORMULATIONS_RESULT: `${APP_PREFIX}/FETCH_FORMULATIONS_RESULT`,
  TOGGLE_SELECT_FORMULATION: `${APP_PREFIX}/TOGGLE_SELECT_FORMULATION`,
  GO_TO_INDEX: `${APP_PREFIX}/GO_TO_INDEX`,
  CALCULATE_FORMULATION: `${APP_PREFIX}/CALCULATE_FORMULATION`,
  CALCULATE_STAIN_REMOVAL: `${APP_PREFIX}/CALCULATE_STAIN_REMOVAL`,
  CALCULATE_SUSTAINABILITY: `${APP_PREFIX}/CALCULATE_SUSTAINABILITY`,
  CALCULATE_ECO_LABEL: `${APP_PREFIX}/CALCULATE_ECO_LABEL`,
  CALCULATE_STAIN_REMOVAL_RESULT: `${APP_PREFIX}/CALCULATE_STAIN_REMOVAL_RESULT`,
  CALCULATE_SUSTAINABILITY_RESULT: `${APP_PREFIX}/CALCULATE_SUSTAINABILITY_RESULT`,
  CALCULATE_ECO_LABEL_RESULT: `${APP_PREFIX}/CALCULATE_ECO_LABEL_RESULT`,
  SET_BASELINE: `${APP_PREFIX}/SET_BASELINE`,
  OPEN_NEW_FORMULATION: `${APP_PREFIX}/OPEN_NEW_FORMULATION`,
  CLOSE_NEW_FORMULATION: `${APP_PREFIX}/CLOSE_NEW_FORMULATION`,
  UPDATE_NEW_FORMULATION: `${APP_PREFIX}/UPDATE_NEW_FORMULATION`,
  OPEN_EDIT_FORMULATION: `${APP_PREFIX}/OPEN_EDIT_FORMULATION`,
  CLOSE_EDIT_FORMULATION: `${APP_PREFIX}/CLOSE_EDIT_FORMULATION`,
  UPDATE_FORMULATION: `${APP_PREFIX}/UPDATE_FORMULATION`,
  UPDATE_FORMULATION_RESULT: `${APP_PREFIX}/UPDATE_FORMULATION_RESULT`,
  CREATE_FORMULATION: `${APP_PREFIX}/CREATE_FORMULATION`,
  CREATE_FORMULATION_RESULT: `${APP_PREFIX}/CREATE_FORMULATION_RESULT`,
  EXPORT_EXCEL: `${APP_PREFIX}/EXPORT_EXCEL`,
  EXPORT_EXCEL_RESULT: `${APP_PREFIX}/EXPORT_EXCEL_RESULT`,
  GET_STAIN_GROUPS_RESULT: `${APP_PREFIX}/GET_STAIN_GROUPS_RESULT`,
  GET_INGREDIENTS_RESULT: `${APP_PREFIX}/GET_INGREDIENTS_RESULT`,
  GET_BLENDS_RESULT: `${APP_PREFIX}/GET_BLENDS_RESULT`,
  GET_REVISION_RESULT: `${APP_PREFIX}/GET_REVISION_RESULT`,
  GET_OPPORTUNITIES_RESULT: `${APP_PREFIX}/GET_OPPORTUNITIES_RESULT`,
  GET_REGIONS_RESULT: `${APP_PREFIX}/GET_REGIONS_RESULT`,
  GET_STAINS_REGIONS_RESULT: `${APP_PREFIX}/GET_STAINS_REGIONS_RESULT`,
  RESPONSE_CURVE: `${APP_PREFIX}/RESPONSE_CURVE`,
  NOTIFICATION: `${APP_PREFIX}/NOTIFICATION`,
  CREATE_CUSTOM_STAIN_GROUP: `${APP_PREFIX}/CREATE_STAIN_GROUP`,
  CREATE_CUSTOM_STAIN_GROUP_RESULT: `${APP_PREFIX}/CREATE_CUSTOM_STAIN_GROUP_RESULT`,
  GET_STAIN_CUSTOM_GROUPS: `${APP_PREFIX}/GET_STAIN_CUSTOM_GROUPS`,
  GET_STAIN_CUSTOM_GROUPS_RESULT: `${APP_PREFIX}/GET_STAIN_CUSTOM_GROUPS_RESULT`,
  SET_CUSTOM_STAIN_GROUP: `${APP_PREFIX}/SET_CUSTOM_STAIN_GROUP`,
  DELETE_CUSTOM_STAIN_GROUP: `${APP_PREFIX}/DELETE_CUSTOM_STAIN_GROUP`,
  DELETE_CUSTOM_STAIN_GROUP_RESULT: `${APP_PREFIX}/DELETE_CUSTOM_STAIN_GROUP_RESULT`,
  VOID_RESULT: `${APP_PREFIX}/VOID_RESULT`,
  BACKEND_DATA: `${APP_PREFIX}/BACKEND_DATA`,
  SEND_FEEDBACK: `${APP_PREFIX}/SEND_FEEDBACK`,
  SEND_FEEDBACK_RESULT: `${APP_PREFIX}/SEND_FEEDBACK_RESULT`,
};

const AUTH_PREFIX = '@auth';
const authActions = {
  USER_AUTHENTICATED: `${AUTH_PREFIX}/USER_AUTHENTICATED`,
  USER_AUTHORIZED: `${AUTH_PREFIX}/USER_AUTHORIZED`,
  USER_SIGN_OUT: `${AUTH_PREFIX}/USER_SIGN_OUT`,
  USER_SIGN_IN_FAILED: `${AUTH_PREFIX}/USER_SIGN_IN_FAILED`,
  USER_SIGN_IN: `${AUTH_PREFIX}/USER_SIGN_IN`,
  ENVIRONMENT: `${AUTH_PREFIX}/ENVIRONMENT`,
};

export default {
  ...appActions,
  ...authActions,
} as const;
