/* eslint-disable no-case-declarations */
import { colors } from '@novozymes-digital/laundry-lab/components/Sections/Chart';
import actionTypes from '../actions/actionTypes';
import { Action, Formulation, FormulationColor, StateInterface } from '../types';

const getInitialFormulationsColor = (): FormulationColor[] => {
  return colors.map((color) => {
    if (color[0] == '#bfe6c4') {
      // by default #bfe6c4 is the new_formulation color
      // used both when creating or editing the formulation
      return {
        legendColor: color[0],
        color: color[1],
        prevFormId: '',
        currentFormId: 'new_formulation',
      };
    } else {
      return { legendColor: color[0], color: color[1], prevFormId: '', currentFormId: '' };
    }
  });
};

const initialState: StateInterface['appState'] = {
  collectionsStatus: 'idle',
  formulationsStatus: 'idle',
  calculationStatus: 'idle',
  collections: [],
  formulations: [],
  calculationResults: {},
  activeCollectionId: undefined,
  selectedFormulations: [],
  selectedBaseline: undefined,
  newFormulation: undefined,
  newFormulationPanelOpen: false,
  editFormulationId: undefined,
  editFormulationSelected: false,
  editFormulationPanelOpen: false,
  isGeneratingExcelExportData: false,
  notificationMessage: '',
  notificationStatus: 'info',
  selectedFormulationsColor: getInitialFormulationsColor(),
  stainGroups: {},
  ingredients: { eu: [], cn: [], na: [], la: [], afr: [], ind: [], me: [], sea: [] },
  blends: { eu: [], cn: [], na: [], la: [], afr: [], ind: [], me: [], sea: [] },
  revision: 20211022,
  regions: {},
  opportunities: [],
  stainsRegions: [],
  stainCustomGroups: [],
};

const setFormulationColorAndGetAll = (
  state: StateInterface['appState'],
  formulationId: string,
  isSelected: boolean
): FormulationColor[] => {
  const selectedFormulationsColor = [...state.selectedFormulationsColor];

  // Formulation is selected and already has a color
  // attributed in currentFormId field
  // return the selectedFormulationsColor
  if (isSelected && selectedFormulationsColor.find((f) => f.currentFormId === formulationId))
    return selectedFormulationsColor; // Prevents re-selecting the color

  // Formulation is select
  if (isSelected) {
    const foundPrevFColor = selectedFormulationsColor.find((f) => !f.currentFormId && f.prevFormId === formulationId);
    if (foundPrevFColor) {
      // Has a previously saved color in prevFormId
      foundPrevFColor.currentFormId = foundPrevFColor.prevFormId;
    } else {
      // No previously saved color, assign a new one
      let unassignedColor = selectedFormulationsColor.find((f) => !f.currentFormId && !f.prevFormId);
      if (!unassignedColor) unassignedColor = selectedFormulationsColor.find((f) => !f.currentFormId);

      if (unassignedColor) unassignedColor.currentFormId = formulationId;
      else throw new Error(`All the colors are assigned to a formulation. Max: ${colors.length}`);
    }
  } else {
    // Unselect formulation
    // clear current color formulation id
    // save previous formulation id in previous
    const foundFColor = selectedFormulationsColor.find((f) => f.currentFormId === formulationId);
    if (foundFColor) {
      foundFColor.prevFormId = foundFColor.currentFormId;
      foundFColor.currentFormId = '';
    }
  }

  /*
  if (selectedFormulationsColor[0].currentFormId != 'new_formulation') {
    console.log(selectedFormulationsColor);
  }
  */

  return selectedFormulationsColor;
};

// why a switch/case instead of a dictionary?
const appState = (state: StateInterface['appState'] = initialState, action: Action): Record<string, unknown> => {
  switch (action.type) {
    case actionTypes.FETCH_USER_COLLECTIONS:
      return {
        ...state,
        collectionsStatus: 'loading',
      };
    case actionTypes.FETCH_USER_COLLECTIONS_RESULT:
      return {
        ...state,
        collectionsStatus: action.error ? 'failed' : 'succeeded',
        collections: action.payload.collections || [],
      };
    case actionTypes.SELECT_COLLECTION:
      return {
        ...state,
        activeCollectionId: action.payload.collectionId,
        selectedFormulationsColor: getInitialFormulationsColor(),
        newFormulation: initialState.newFormulation,
        newFormulationPanelOpen: false,
        editFormulationId: undefined,
        editFormulationSelected: false,
        editFormulationPanelOpen: false,
        selectedBaseline: undefined,
        selectedFormulations: [],
      };

    case actionTypes.GO_TO_INDEX:
      return {
        ...state,
        formulations: [],
        activeCollectionId: undefined,
        newFormulationPanelOpen: false,
        editFormulationId: undefined,
        editFormulationSelected: false,
        editFormulationPanelOpen: false,
        selectedBaseline: undefined,
        selectedFormulations: [],
      };

    case actionTypes.DELETE_FORMULATION_RESULT:
      // Unselect formulation and baseline if match the removed formulation ID
      const selectedFormulationAfterDelete = [...state.selectedFormulations];
      let selectedBaseLineAfterDelete = state.selectedBaseline;
      let selectedFormulationsColorAfterDelete = [...state.selectedFormulationsColor];

      if (!action.error) {
        const deletedFormulationId: string = action.payload.deletedFormulationId;
        if (deletedFormulationId === state.selectedBaseline) selectedBaseLineAfterDelete = undefined;
        const indexOfSelectedFormulation = state.selectedFormulations.indexOf(deletedFormulationId);
        if (indexOfSelectedFormulation > -1) selectedFormulationAfterDelete.splice(indexOfSelectedFormulation, 1);

        selectedFormulationsColorAfterDelete = setFormulationColorAndGetAll(state, deletedFormulationId, false);
      }

      return {
        ...state,
        selectedBaseline: selectedBaseLineAfterDelete,
        selectedFormulations: selectedFormulationAfterDelete,
        selectedFormulationsColor: selectedFormulationsColorAfterDelete,
      };

    case actionTypes.FETCH_FORMULATIONS:
      return {
        ...state,
        formulationsStatus: 'loading',
      };
    case actionTypes.FETCH_FORMULATIONS_RESULT:
      return {
        ...state,
        formulationsStatus: action.error ? 'failed' : 'succeeded',
        formulations: action.payload.formulations || [],
      };

    case actionTypes.TOGGLE_SELECT_FORMULATION:
      const newSelectedFormulations = state.selectedFormulations.filter((id) => id !== action.payload.formulationId);
      if (!action.payload.isSelected) {
        newSelectedFormulations.push(action.payload.formulationId);
      }

      const selectedFormulationsColor = setFormulationColorAndGetAll(
        state,
        action.payload.formulationId,
        !action.payload.isSelected
      );
      return {
        ...state,
        selectedFormulations: newSelectedFormulations,
        selectedFormulationsColor: selectedFormulationsColor,
      };

    case actionTypes.CALCULATE_FORMULATION:
      return {
        ...state,
        calculationStatus: 'loading',
      };

    case actionTypes.CALCULATE_STAIN_REMOVAL_RESULT:
      return {
        ...state,
        calculationStatus: action.error ? 'failed' : 'succeeded',
        calculationResults: {
          ...state.calculationResults,
          [action.payload.formulationId]: {
            ...state.calculationResults[action.payload.formulationId],
            stainRemovalResult: action.payload.stainRemovalResult,
          },
        },
      };

    case actionTypes.CALCULATE_SUSTAINABILITY_RESULT:
      return {
        ...state,
        calculationStatus: action.error ? 'failed' : 'succeeded',
        calculationResults: {
          ...state.calculationResults,
          [action.payload.formulationId]: {
            ...state.calculationResults[action.payload.formulationId],
            sustainabilityResult: action.payload.sustainabilityResult,
          },
        },
      };
    case actionTypes.CALCULATE_ECO_LABEL_RESULT:
      return {
        ...state,
        calculationStatus: action.error ? 'failed' : 'succeeded',
        calculationResults: {
          ...state.calculationResults,
          [action.payload.formulationId]: {
            ...state.calculationResults[action.payload.formulationId],
            ecolabelResult: action.payload.ecolabelResult,
          },
        },
      };

    case actionTypes.SET_BASELINE:
      return {
        ...state,
        selectedBaseline: action.payload.formulationId,
      };

    case actionTypes.OPEN_NEW_FORMULATION:
      return {
        ...state,
        newFormulation: initialState.newFormulation,
        newFormulationPanelOpen: true,
        editFormulationId: undefined,
        editFormulationSelected: false,
        editFormulationPanelOpen: false,
      };

    case actionTypes.UPDATE_NEW_FORMULATION:
      return {
        ...state,
        newFormulation: action.payload.formulationData,
        selectedFormulationsColor: setFormulationColorAndGetAll(state, 'new_formulation', true),
      };

    case actionTypes.UPDATE_FORMULATION_RESULT:
      const updateFormulations = [...state.formulations];
      if (!action.error) {
        const editedFormulation: Formulation = action.payload.formulation;
        const editedFormulationIndex = state.formulations.findIndex(
          (formulation) => formulation.id === editedFormulation.id
        );

        updateFormulations[editedFormulationIndex] = editedFormulation;
      }
      return { ...state, formulations: updateFormulations };

    case actionTypes.CLOSE_NEW_FORMULATION:
      return {
        ...state,
        newFormulation: initialState.newFormulation,
        newFormulationPanelOpen: false,
        editFormulationId: undefined,
        editFormulationSelected: false,
        editFormulationPanelOpen: false,
      };

    case actionTypes.OPEN_EDIT_FORMULATION:
      return {
        ...state,
        newFormulation: initialState.newFormulation,
        newFormulationPanelOpen: false,
        editFormulationId: action.payload.formulationId,
        editFormulationSelected: action.payload.isSelected,
        editFormulationPanelOpen: true,
      };

    case actionTypes.CLOSE_EDIT_FORMULATION:
      return {
        ...state,
        newFormulation: initialState.newFormulation,
        newFormulationPanelOpen: false,
        editFormulationId: undefined,
        editFormulationSelected: false,
        editFormulationPanelOpen: false,
      };

    case actionTypes.EXPORT_EXCEL:
      return {
        ...state,
        isGeneratingExcelExportData: true,
      };

    case actionTypes.EXPORT_EXCEL_RESULT:
      return {
        ...state,
        isGeneratingExcelExportData: false,
      };

    case actionTypes.NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.payload.message,
        notificationStatus: action.payload.status ? action.payload.status : 'success',
      };

    case actionTypes.GET_STAIN_GROUPS_RESULT:
      return {
        ...state,
        stainGroups: action.payload.stainGroups || {},
      };

    case actionTypes.GET_INGREDIENTS_RESULT:
      return {
        ...state,
        ingredients: action.payload.ingredients || { eu: [], cn: [], na: [], afr: [], ind: [], me: [], sea: [] },
      };

    case actionTypes.GET_BLENDS_RESULT:
      return {
        ...state,
        blends: action.payload.blends || { eu: [], cn: [], na: [], afr: [], ind: [], me: [], sea: [] },
      };

    case actionTypes.GET_REVISION_RESULT:
      return {
        ...state,
        revision: action.payload.revision || { revision: 20211022 },
      };

    case actionTypes.GET_REGIONS_RESULT:
      return {
        ...state,
        regions: action.payload.regions || [],
      };
    case actionTypes.GET_OPPORTUNITIES_RESULT:
      return {
        ...state,
        opportunities: action.payload.opportunities || [],
      };
    case actionTypes.GET_STAINS_REGIONS_RESULT:
      return {
        ...state,
        stainsRegions: action.payload.stainsRegions || [],
      };
    case actionTypes.GET_STAIN_CUSTOM_GROUPS_RESULT:
      return {
        ...state,
        stainCustomGroups: action.payload.stainCustomGroups || [],
      };
    default:
      return state;
  }
};

export default appState;
