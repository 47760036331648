import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Grid, styled } from '@mui/material';
import { selectCollection } from '@novozymes-digital/laundry-lab/store/actions/actions';
import { getCollectionById } from '@novozymes-digital/laundry-lab/store/selectors';
import LeftPanel from './LeftPanel';
import { MainPanel } from './MainPanel';
import { TopPanel } from './TopPanel';

const StyledGrid = styled(Grid)(() => ({
  paddingLeft: '30px',
  paddingRight: '30px',
}));

const ComparePage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const idAsNumber = Number(id);
  const collectionWithId = useSelector(getCollectionById(idAsNumber));

  useEffect(() => {
    if (!Number.isNaN(idAsNumber) && collectionWithId) {
      dispatch(selectCollection(idAsNumber));
    }
  }, [dispatch, idAsNumber, collectionWithId]);

  return (
    <>
      <Grid container justifyContent="center" alignContent="center">
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <TopPanel />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <LeftPanel />
          </Grid>
          <StyledGrid item xs={9}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <MainPanel />
              </Grid>
            </Grid>
          </StyledGrid>
        </Grid>
      </Grid>
    </>
  );
};

export default ComparePage;
