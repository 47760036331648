import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '14px',
  height: '14px',
  marginRight: '12px',
}));

export default function EditIcon(): JSX.Element {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.92073 9.50469L9.2124 2.21302C9.67237 1.75305 10.3428 1.57341 10.9711 1.74177C11.5995 1.91013 12.0902 2.40092 12.2586 3.02925C12.427 3.65758 12.2473 4.32801 11.7874 4.78798L4.49569 12.0796C4.42093 12.1544 4.32726 12.2074 4.22469 12.2331L1.89136 12.8164C1.46414 12.9232 1.07716 12.5362 1.18396 12.109L1.7673 9.77569C1.79294 9.67312 1.84597 9.57945 1.92073 9.50469ZM11.1317 3.3312C11.0712 3.10548 10.8949 2.92917 10.6692 2.86868L10.5935 2.85312C10.3909 2.82378 10.1842 2.8911 10.0374 3.03798L2.85996 10.2152L2.55138 11.4484L3.78455 11.1398L10.9624 3.96302C11.1093 3.81614 11.1766 3.60952 11.1473 3.40688L11.1317 3.3312ZM12.8332 12.2505C12.8332 11.9283 12.572 11.6672 12.2499 11.6672H6.99988L6.93185 11.6711C6.64174 11.7048 6.41655 11.9513 6.41655 12.2505C6.41655 12.5727 6.67771 12.8338 6.99988 12.8338H12.2499L12.3179 12.8299C12.608 12.7962 12.8332 12.5497 12.8332 12.2505Z"
          fill="black"
        />
      </svg>
    </StyledGrid>
  );
}
