import { AllStainCodesType } from '@novozymes-digital/laundry-lab/types/FormulationSpace';
import { StainRemovalResult, StainRemovalResultData } from '@novozymes-digital/laundry-lab/store/types';

export interface Ingredients {
  [key: string]: number;
}

export const parseResult = (value: number): number => {
  return parseFloat(value.toFixed(2));
};

export const computeTotal = (arrayData: number[]): number => {
  return arrayData.reduce((totalValue: number, numValue: number) => totalValue + numValue, 0);
};

export const sortStains = (AllStainCodes: AllStainCodesType): AllStainCodesType => {
  AllStainCodes.sort((a, b) => {
    const nameA = a.display_name_eng.toUpperCase();
    const nameB = b.display_name_eng.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return AllStainCodes;
};

export const sortStainsResults = (stainRemovalResult: StainRemovalResult): StainRemovalResult => {
  const aux: any = [];

  for (const stainGroupKey in stainRemovalResult) {
    const stainList = Object.keys(stainRemovalResult[stainGroupKey]).map(
      (steinKey) => stainRemovalResult[stainGroupKey][steinKey]
    );

    stainList.sort((obj1: StainRemovalResultData, obj2: StainRemovalResultData) => {
      const nameA = obj1.name.toUpperCase();
      const nameB = obj2.name.toUpperCase();
      if (nameA < nameB) return -1;
      else if (nameA > nameB) return 1;
      else return 0;
    });

    aux.push({ key: stainGroupKey, stains: stainList.map((st) => st.stainCode) });
  }

  // Retrieve object sorted by stain names
  const stainRemovalSorted: any = {};
  for (const stainGroup of aux) {
    let index = 0;
    stainRemovalSorted[stainGroup.key] = {};
    for (const stain of stainGroup.stains) {
      stainRemovalSorted[stainGroup.key][index] = stainRemovalResult[stainGroup.key][stain];
      index++;
    }
  }

  return stainRemovalSorted;
};

export const round = (number: number, decimals: number) => {
  const factor = 10 ** decimals;
  return Math.round((number + Number.EPSILON) * factor) / factor;
};

export const trunc = (number: any, decimals: number) => {
  const numS = number.toString(),
    decPos = numS.indexOf('.'),
    substrLength = decPos == -1 ? numS.length : 1 + decPos + decimals,
    trimmedResult = numS.substr(0, substrLength),
    finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

  return parseFloat(finalResult);
};

export const formatSustainabilityDynamic = (value: number, reference_value: number, measure: string): any => {
  switch (measure) {
    case 'co2':
    case 'chemical':
      // BE values are in million tonnes
      if (reference_value >= 1) return { value: round(value, 2), suffix: 'million tonnes' };
      else if (reference_value < 1 && reference_value >= 0.001)
        return { value: round(value * 1000, 2), suffix: 'thousand tonnes' };
      else return { value: round(value * 1000000, 2), suffix: 'tonnes' };
    case 'cdv':
      // BE values are in billion m3 water
      if (reference_value >= 1) return { value: round(value, 0), suffix: 'billion m3 water' };
      else if (reference_value < 1 && reference_value >= 0.001)
        return { value: round(value * 1000, 0), suffix: 'million m3 water' };
      else return { value: round(value * 1000000, 0), suffix: 'thousand m3 water' };
    default:
      console.log(`unknow measure ${measure}.`);
  }
};
