import { Button, Grid, styled, Typography } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';

import ExportDataDialog from '@novozymes-digital/laundry-lab/pages/Compare/ExportData/ExportDataDialog';
import ScrollNav from '@novozymes-digital/laundry-lab/pages/Compare/ScrollNav';
import {
  getCurrentCollectionRegion,
  getIsEditFormulationPanelOpen,
  getIsCreateFormulationPanelOpen,
  getIsExportEnabled,
  getIsResponseCurveEnabled,
  getSelectedFormulations,
} from '@novozymes-digital/laundry-lab/store/selectors';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ResponseCurveDialog from './ResponseCurve/ResponseCurveDialog';
import { IconDownload } from '@novozymes-digital/dezymer-icons';

const StyledScrollNav = styled(ScrollNav)``;

const IconDownloadStyled = styled(IconDownload)`
  width: 20px;
  & path {
    fill: var(--nz-white-95);
  }
`;

const StyledGrid = styled(Grid)(() => ({
  zIndex: 1300,
  position: 'fixed',
  top: '68px',
  left: 0,
  right: 0,
  height: '42px',
  background: '#173629',
  padding: '0 20px',
  color: 'var(--nz-white-95)',

  '& .activeNav span': {
    color: 'rgba(255, 255, 255, 0.95) !important',
    fontWeight: 'bold',
    textDecoration: 'none',
    borderBottom: '1px solid #bfe6c4',
  },
}));

const StyledButton = styled(Button)(() => ({
  color: 'var(--nz-white-95)',
  opacity: 0.95,
  '&:disabled': {
    color: 'var(--nz-white-95)',
    opacity: 0.45,
    pointerEvents: 'auto',
  },
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '14px',
}));

export const TopPanel: React.FC = () => {
  const selectedFormulations = useSelector(getSelectedFormulations);
  const isEditFormulationPanelOpen = useSelector(getIsEditFormulationPanelOpen);
  const isCreateFormulationPanelOpen = useSelector(getIsCreateFormulationPanelOpen);
  const region = useSelector(getCurrentCollectionRegion);
  const responseCurveEnabled = useSelector(getIsResponseCurveEnabled(region));
  const exportEnabled = useSelector(getIsExportEnabled);

  const steps = ['la', 'afr', 'ind', 'me', 'sea'].includes(region)
    ? ['Formulations', 'Stain removal', 'Cost breakdown', 'Sustainability']
    : ['Formulations', 'Stain removal', 'Cost breakdown', 'Sustainability', 'EU Ecolabel'];
  const links = ['la', 'afr', 'ind', 'me', 'sea'].includes(region)
    ? ['#formulations', '#stainremoval', '#costbreakdown', '#sustainabilityimpact']
    : ['#formulations', '#stainremoval', '#costbreakdown', '#sustainabilityimpact', '#sustainabilitylabel'];
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [responseCurveDialogOpen, setResponseCurveDialogOpen] = useState(false);

  const formulationSelected = useMemo(
    () => selectedFormulations.length > 0 || isEditFormulationPanelOpen || isCreateFormulationPanelOpen,
    [selectedFormulations, isEditFormulationPanelOpen, isCreateFormulationPanelOpen]
  );

  return (
    <div>
      <ResponseCurveDialog
        isOpen={responseCurveDialogOpen}
        onClose={() => {
          setResponseCurveDialogOpen(false);
        }}
      />
      <ExportDataDialog
        isOpen={exportDialogOpen}
        onClose={() => {
          setExportDialogOpen(false);
        }}
      />
      <StyledScrollNav data-cy="scroll-nav" activeNavClass="activeNav" reload={formulationSelected}>
        <StyledGrid container justifyContent="center">
          {steps.map(
            (label, index) =>
              formulationSelected && (
                <Grid container item alignItems="center" xs key={index}>
                  <a key={index} href={links[index]} style={{ textDecoration: 'none' }}>
                    <span style={{ color: '#CCC' }}>
                      {index + 1}. {label}
                    </span>
                  </a>
                </Grid>
              )
          )}
          <Grid container item alignItems="center" justifyContent="flex-end" xs={formulationSelected ? 3 : 12}>
            <StyledButton
              data-cy="btn-response-curve-dialog"
              id={`btn-response-curve-dialog-${region}`}
              disabled={!responseCurveEnabled}
              onClick={() => {
                setResponseCurveDialogOpen(true);
              }}
            >
              <ShowChartIcon style={{ fontSize: 'medium' }} />
              <StyledTypography style={{ marginLeft: '5px' }}>Response curve</StyledTypography>
            </StyledButton>
            <StyledButton
              data-cy="btn-export-dialog"
              id={`btn-export-data-dialog-${region}`}
              disabled={!exportEnabled}
              onClick={() => {
                setExportDialogOpen(true);
              }}
            >
              <IconDownloadStyled />
              <StyledTypography style={{ marginLeft: '5px' }}>Export</StyledTypography>
            </StyledButton>
          </Grid>
        </StyledGrid>
      </StyledScrollNav>
    </div>
  );
};
