import { ComponentPropsWithoutRef, ReactNode } from 'react';
import styled from 'styled-components';
import { NzIcon } from '@novozymes-digital/dezymer-icons';
import NzFooterLink from './FooterLink';

export interface NzFooterProps extends ComponentPropsWithoutRef<'footer'> {
  showConsent?: boolean;
  showLinks?: boolean;
  children?: ReactNode;
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 16px 48px;
  background-color: rgb(249, 248, 245); //var(--nz-bg-3);
  color: var(--nz-black-47);
  height: 48px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    flex-direction: column;
    height: 116px;
  }
  border-top: 1px solid #13291f;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 164px;
  justify-content: flex-end;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileLinksContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 220px;
  }
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SvgIconWrapper = styled.div`
  background-color: #e6e6e6;
  width: 20px;
  height: 20px;

  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 20%),
    0 2px 1px -1px rgb(0 0 0 / 12%),
    0 1px 1px 0 rgb(0 0 0 / 14%);

  &:hover {
    background-color: var(--nz-green-hover);
  }

  svg path:first-child {
    fill: rgb(230, 230, 230);
  }
`;

export function Footer({ children, showConsent, showLinks = true, ...rest }: NzFooterProps) {
  return (
    <FooterContainer {...rest} className={`NzFooter ${rest.className ?? ''}`} role="contentinfo">
      <IconsContainer>
        {children}
        <NzFooterLink
          aria-label="facebook"
          href="https://www.facebook.com/novonesisbio"
          rel="noopener noreferrer"
          target="_blank"
        >
          <SvgIconWrapper>
            <NzIcon name="facebook" size={20} />
          </SvgIconWrapper>
        </NzFooterLink>
        <NzFooterLink
          aria-label="twitter"
          href="https://twitter.com/novonesis"
          rel="noopener noreferrer"
          target="_blank"
        >
          <SvgIconWrapper>
            <NzIcon name="twitter" size={20} />
          </SvgIconWrapper>
        </NzFooterLink>
        <NzFooterLink
          aria-label="linkedin"
          href="https://www.linkedin.com/company/novonesis"
          rel="noopener noreferrer"
          target="_blank"
        >
          <SvgIconWrapper>
            <NzIcon name="linkedin" size={20} />
          </SvgIconWrapper>
        </NzFooterLink>
        <NzFooterLink
          aria-label="youtube"
          href="https://www.youtube.com/@Novonesis"
          rel="noopener noreferrer"
          target="_blank"
        >
          <SvgIconWrapper>
            <NzIcon name="youtube" size={20} />
          </SvgIconWrapper>
        </NzFooterLink>
      </IconsContainer>

      {showLinks && (
        <LinksContainer>
          <NzFooterLink aria-label="home" href="https://www.novonesis.com" rel="noopener noreferrer" target="_blank">
            Home
          </NzFooterLink>

          <NzFooterLink
            aria-label="legal"
            href="https://www.novonesis.com/legal-notice"
            rel="noopener noreferrer"
            target="_blank"
          >
            Legal
          </NzFooterLink>

          <NzFooterLink
            aria-label="privacy"
            href="https://www.novonesis.com/en/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy
          </NzFooterLink>

          <NzFooterLink
            aria-label="contact"
            href="https://www.novonesis.com/en/contact"
            rel="noopener noreferrer"
            target="_blank"
          >
            Contact
          </NzFooterLink>

          {!!showConsent && (
            <NzFooterLink
              aria-label="update consent"
              id="footer consent"
              href="https://biosolutions.novonesis.com/#Coi-Renew"
              rel="noopener noreferrer"
              target="_blank"
            >
              Update consent
            </NzFooterLink>
          )}

          <NzFooterLink
            aria-label="corporate news"
            href="https://www.novonesis.com/en/get-our-news"
            rel="noopener noreferrer"
            target="_blank"
          >
            Corporate news
          </NzFooterLink>
        </LinksContainer>
      )}

      <BrandContainer>
        {`Copyright © ${new Date().getFullYear()}`}
        <NzFooterLink
          aria-label="novonesis"
          color="inherit"
          href="https://www.novonesis.com/"
          style={{ marginLeft: '2px' }}
        >
          Novozymes A/S, part of Novonesis Group
        </NzFooterLink>
      </BrandContainer>

      <MobileLinksContainer>
        <NzFooterLink
          aria-label="facebook"
          id="footer-facebook"
          bold
          href="https://www.facebook.com/novonesisbio"
          rel="noopener noreferrer"
          target="_blank"
        >
          Facebook
        </NzFooterLink>

        <NzFooterLink
          aria-label="twitter"
          bold
          href="https://twitter.com/novonesis"
          rel="noopener noreferrer"
          target="_blank"
        >
          Twitter
        </NzFooterLink>

        <NzFooterLink
          aria-label="linkedin"
          bold
          href="https://www.linkedin.com/company/novonesis"
          rel="noopener noreferrer"
          target="_blank"
        >
          Linkedin
        </NzFooterLink>

        <NzFooterLink
          aria-label="youtube"
          bold
          href="https://www.youtube.com/@Novonesis"
          rel="noopener noreferrer"
          target="_blank"
        >
          Youtube
        </NzFooterLink>
      </MobileLinksContainer>
    </FooterContainer>
  );
}

export default Footer;
