/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';

import { Auth } from 'aws-amplify';
import { FormErrors } from './FormErrors';
import {
  FormControl,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Box,
  FilledInput,
  Checkbox,
  FormControlLabel,
  Link,
  styled,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import { userAuthenticated } from '@novozymes-digital/laundry-lab/store/actions/actions';
import { formatUnixDateTime } from '@novozymes-digital/laundry-lab/utility/Date';
import { getLocalStorageItem, setLocalStorageItem } from '@novozymes-digital/laundry-lab/utility/LocalStorage';
import { validateForm } from '@novozymes-digital/laundry-lab/utility/FormValidation';

import { GtmAuthenticateFailedEvent } from '@novozymes-digital/laundry-lab/utility/TagManager';
import CheckboxUnchecked from '@novozymes-digital/laundry-lab/icons/CheckboxUnchecked';
import CheckboxChecked from '@novozymes-digital/laundry-lab/icons/CheckboxChecked';

interface Status {
  acceptedTerms?: boolean;
  acceptedDate?: string;
}

function getStatus(): Status {
  try {
    const status: Status | undefined = getLocalStorageItem('ACCEPTED_T&C_STATUS') as Status;

    if (status === undefined) {
      return { acceptedTerms: false, acceptedDate: '' };
    }
    return status;
  } catch (e) {
    console.error(e);
    return { acceptedTerms: false, acceptedDate: '' };
  }
}

function setStatus(status: Status) {
  try {
    setLocalStorageItem('ACCEPTED_T&C_STATUS', status);
  } catch (e) {
    console.error(e);
  }
}

const StyledFormControl = styled(FormControl)(() => ({
  fontSize: '16px',
  lineHeight: '19px',
  paddingBottom: '10px',
  fontWeight: 'bold',
}));

const StyledFilledInput = styled(FilledInput)(() => ({
  fontSize: '16px',
  lineHeight: '19px',
  paddingBottom: '10px',
  backgroundColor: 'var(--nz-bg-3)',
}));

const StyledLinkButton = styled(Link)(() => ({
  fontSize: '16px',
  margin: '0px 20px',
  textDecoration: 'underline',
  textDecorationColor: '#bfe6c4',
  textDecorationThickness: '3px',
  cursor: 'pointer',
}));

const StyledLinkTC = styled(Link)(() => ({
  fontSize: '16px',
  textDecoration: 'underline',
}));

const StyledButton = styled(Button)(() => ({
  width: 'auto',
  height: '40px',
  borderRadius: '58px',
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'none',
  color: 'primary',
  padding: '8px',
  fontWeight: 400,
}));

interface Props {
  onClickResetPassword: () => void;
}

export const SignIn: React.FC<Props> = ({ onClickResetPassword }: Props) => {
  const initialState = {
    username: '',
    email: '',
    currentPassword: '',
    showCurrentPassword: false,
    password: '',
    showPassword: false,
    newPasswordRequired: false,
    errors: {
      cognito: null,
      blankfield: false,
    },
    user: {},
  };

  const dispatch = useDispatch();

  const [formState, setFormState] = useState(initialState);

  const status = getStatus();
  const [acceptTerms, setAcceptTerms] = useState(status.acceptedTerms);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.persist();
    setFormState((formState) => ({ ...formState, [event.target.name]: event.target.value }));
  };

  const clearErrorState = () => {
    setFormState({
      ...formState,
      errors: {
        ...formState.errors,
        cognito: null,
        blankfield: false,
      },
    });
  };

  const handleClickShowCurrentPassword = () => {
    setFormState({
      ...formState,
      showCurrentPassword: !formState.showCurrentPassword,
    });
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validateCodeForm = () => {
    return formState.password.length > 3;
  };

  const handleSubmitNewPassword = async (event: React.FormEvent<HTMLElement>) => {
    if (event) {
      event.preventDefault();
      try {
        const user = await Auth.signIn(formState.username, formState.currentPassword);
        await Auth.completeNewPassword(user, formState.password, {}).then(() => {
          setFormState({
            ...formState,
            newPasswordRequired: false,
          });
        });
      } catch (error: any) {
        console.error(error);

        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setFormState({
          ...formState,
          errors: {
            ...formState.errors,
            cognito: err,
            blankfield: false,
          },
        });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    if (event) {
      event.preventDefault();
      clearErrorState();
      const error = validateForm(event, formState);
      if (error) {
        setFormState({ ...formState, errors: { ...formState.errors, ...error } });
        return;
      }
      try {
        // TODO: Move to epic?
        const user = await Auth.signIn(formState.username, formState.password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setFormState({
            ...formState,
            newPasswordRequired: true,
            user: { ...user },
            password: '',
          });
        } else {
          dispatch(userAuthenticated(user));
        }
      } catch (error: any) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setFormState({
          ...formState,
          errors: {
            ...formState.errors,
            cognito: err,
            blankfield: false,
          },
        });
        GtmAuthenticateFailedEvent();
      }
    }
  };

  const renderRequiredNewPassword = () => {
    return (
      <Grid
        component="form"
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        onSubmit={handleSubmitNewPassword}
      >
        <Grid item>
          <StyledFormControl fullWidth>
            <label htmlFor="currentPassword">Current password</label>
            <StyledFilledInput
              required
              id="currentPassword"
              name="currentPassword"
              type={formState.showCurrentPassword ? 'text' : 'password'}
              value={formState.currentPassword}
              onChange={(e) => handleInputChange(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {formState.showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              style={{ height: '50px', padding: 0 }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl fullWidth>
            <label htmlFor="password">New password</label>
            <StyledFilledInput
              required
              id="password"
              name="password"
              type={formState.showPassword ? 'text' : 'password'}
              onChange={(e) => handleInputChange(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {formState.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              style={{ height: '50px', padding: 0 }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" direction="column">
            <Button type="submit" variant="contained" color="primary" disabled={!validateCodeForm()}>
              Change password
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleAcceptTerms = (event: any) => {
    const accepted: boolean = event.target.checked;
    setAcceptTerms(accepted);
    setStatus({ acceptedTerms: accepted, acceptedDate: accepted ? formatUnixDateTime(new Date()) : '' });
  };

  const renderSignInForm = () => {
    return (
      <Box component="form" onSubmit={handleSubmit}>
        <FormErrors formerrors={formState.errors} />

        <Grid container direction="column" justifyContent="center" spacing={3}>
          <Grid item>
            <Grid container direction="column">
              <FormControlLabel
                label={
                  <>
                    I accept Novozymes' &nbsp;
                    <StyledLinkTC
                      data-cy="open-tc"
                      id="open-tc"
                      color="inherit"
                      variant="body2"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
                        e.preventDefault();
                        window.open(
                          'https://www.novozymes.com/en/legal-notice?_gl=1*dk9qab*_ga*NjQxNTA2NDk4LjE2NzM4NzgxNTg.*_gid*MTI1MzI5MjU0MS4xNjgxODEyODEz*_fplc*elk0azE3QTRlVDFQS3BGSk5KSkN0WWVyWUtyM3clMkZJNWFXSnNJJTJGVWNMTnhuaEtvY21uZ1c3UEslMkZyUUFRVGhjSk5PUlJBZHJaQ3BIS0tOUiUyQm5RdlRvU2pDNGpjYW9jamNjU2lNaGQwWWFxNjlTck5UODglMkZ4RmVFM24lMkZRQ3pRJTNEJTNE',
                          '_blank'
                        );
                      }}
                    >
                      legal notice
                    </StyledLinkTC>
                    &nbsp; and&nbsp;
                    <StyledLinkTC
                      data-cy="open-pp"
                      id="open-pp"
                      color="inherit"
                      variant="body2"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
                        e.preventDefault();
                        window.open('https://www.novozymes.com/en/privacy-policy', '_blank');
                      }}
                    >
                      privacy policy
                    </StyledLinkTC>
                  </>
                }
                control={
                  <Checkbox
                    data-cy="agree-to-tc"
                    id="agree-to-tc"
                    name="agreeToTC"
                    checked={acceptTerms}
                    onClick={handleAcceptTerms}
                    color="primary"
                    checkedIcon={<CheckboxChecked />}
                    icon={<CheckboxUnchecked />}
                  />
                }
              />
            </Grid>
          </Grid>

          <Grid item>
            <StyledFormControl fullWidth>
              <label htmlFor="username">Username</label>
              <StyledFilledInput
                required
                data-cy="username"
                id="username"
                name="username"
                autoComplete="email"
                disableUnderline
                value={formState.username}
                onChange={(e) => handleInputChange(e)}
                fullWidth
                style={{ height: '50px', padding: 0 }}
              />
            </StyledFormControl>
          </Grid>
          <Grid item>
            <StyledFormControl fullWidth>
              <label htmlFor="password">Password</label>
              <StyledFilledInput
                required
                data-cy="password"
                id="password"
                name="password"
                type={formState.showPassword ? 'text' : 'password'}
                value={formState.password}
                onChange={(e) => handleInputChange(e)}
                disableUnderline
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {formState.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                style={{ height: '50px', padding: 0 }}
              />
            </StyledFormControl>
          </Grid>

          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center" direction="row">
              <StyledLinkButton color="inherit" variant="body2" onClick={onClickResetPassword}>
                Reset password
              </StyledLinkButton>
              <StyledButton
                data-cy="sign-in"
                id="sign-in"
                type="submit"
                variant="contained"
                color="primary"
                disabled={!acceptTerms}
              >
                Sign in
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box pt={1} pb={3}>
      {!formState.newPasswordRequired ? renderSignInForm() : renderRequiredNewPassword()}
    </Box>
  );
};
