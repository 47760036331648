import { createStore, combineReducers, applyMiddleware, Middleware, compose, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-most';
import { createMemoryHistory } from 'history';
import appState from './reducers/AppState';
import authState from './reducers/AuthState';
import state from './reducers/AppState';
import getReducer from './reducers';
import rootEpic from './epics';
import { createReduxHistoryContext } from 'redux-first-history';

const memoHistory = createMemoryHistory();
const epicMiddleware = createEpicMiddleware(rootEpic);

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: memoHistory });

const getStoreEnhancers = () => {
  const middleware: Middleware[] = [routerMiddleware, epicMiddleware];

  if (process.env.NODE_ENV === 'development') {
    return composeWithDevTools(applyMiddleware(...middleware));
  } else {
    return compose(applyMiddleware(...middleware));
  }
};

const replaceRootEpic = () => {
  import('./epics').then(({ default: nextRootEpic }) => {
    epicMiddleware.replaceEpic(nextRootEpic);
  });
};

export const setUpStore = (): Store => {
  const storeEnhancers = getStoreEnhancers();
  const store = createStore(
    combineReducers({
      router: routerReducer,
      authState,
      appState,
      state,
    }),
    storeEnhancers
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(getReducer());
      });
      module.hot.accept('./epics', replaceRootEpic);
    }
  }

  return store;
};

export const store = setUpStore();

export const history = createReduxHistory(store);
