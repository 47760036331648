export function setLocalStorageItem(key: string, data: unknown): void {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorageItem<Type>(key: string): Type | undefined {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : undefined;
}

export function removeLocalStorageItem(key: string): void {
  localStorage.removeItem(key);
}
