import React from 'react';
import ButtonLayout from '../UI/ButtonLayout';
import NewChip from '../UI/NewChip';
import { Button, styled } from '@mui/material';
import { NzIcon } from '@novozymes-digital/dezymer-icons';

interface Props {
  groupStains: StainGroup[];
  setTab: any;
  selected: StainGroup;
  standartType: string;
  customType: string;
  editingGroup: any;
  setEditingGroup: any;
  setCreatMode: any;
}

interface StainGroup {
  label: string;
  key: string;
  type: string;
  id: string;
}

const StyledButton = styled(Button)({
  height: '32px',
  minWidth: '112px',
});

const AddIcon = styled(NzIcon)({
  marginLeft: '4px',
});

const StainTabs: React.FunctionComponent<Props> = ({
  groupStains,
  setTab,
  selected,
  standartType,
  customType,
  editingGroup,
  setCreatMode,
  setEditingGroup,
}: Props) => {
  const hasCustom = groupStains.filter((x) => x.type === customType && x.key !== customType).length > 0;

  return (
    <div style={{ fontSize: '14px' }} data-cy="stain-removal">
      <ButtonLayout>
        {groupStains
          .map((group) => (
            <NewChip
              label={group.label}
              checked={group.label == selected.label && group.type == selected.type}
              key={group.key + group.type}
              onClick={() => {
                setTab(group);
              }}
              variant={group.type === standartType ? 'primary' : 'secondary'}
              disabled={
                (group.key === customType && !hasCustom) ||
                (editingGroup && group.id !== editingGroup.id) ||
                (editingGroup && group.type !== customType)
              }
              isEditing={!!editingGroup && group.key == editingGroup.key && group.type == editingGroup.type}
              onChange={(label) => {
                setEditingGroup({ ...editingGroup, label: label });
              }}
            />
          ))
          .concat(
            <>
              <StyledButton
                variant="contained"
                disabled={!!editingGroup}
                onClick={() => setCreatMode()}
                size="medium"
                key="create"
              >
                Create New
                <AddIcon name="add-outlined" size={16} />
              </StyledButton>
            </>
          )}
      </ButtonLayout>
    </div>
  );
};

export default StainTabs;
