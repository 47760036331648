import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { userSignOut } from '@novozymes-digital/laundry-lab/store/actions/actions';

export const UnauthorizedMessage: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    window.location.href = window.location.href.split('?')[0];
    dispatch(userSignOut());
  };

  return (
    <Container maxWidth="md">
      <Typography align="center" variant="h2" gutterBottom>
        Unauthorized Access
      </Typography>
      <br />
      <Typography align="center" variant="subtitle1">
        You do not have permission to access this page.
      </Typography>
      <Grid container justifyContent="center">
        <Grid item>
          <Button variant="outlined" onClick={handleClick}>
            Go back to login page
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
