import React from 'react';

import { Typography, styled } from '@mui/material';

interface Props {
  formerrors?: any;
  apierrors?: any;
}

const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  padding: '10px 0',
  color: 'var(--nz-warning)',
}));

export const FormErrors: React.FC<Props> = ({ formerrors, apierrors }) => {
  if (formerrors && (formerrors.blankfield || formerrors.passwordmatch)) {
    return (
      <div>
        <StyledTypography>
          {formerrors.passwordmatch ? 'Password value does not match confirm password value' : ''}
        </StyledTypography>
        <StyledTypography>{formerrors.blankfield ? 'Please enter your username and password' : ''}</StyledTypography>
      </div>
    );
  } else if (apierrors) {
    return <StyledTypography>{apierrors}</StyledTypography>;
  } else if (formerrors && formerrors.cognito && formerrors.cognito.message) {
    return <StyledTypography>{formerrors.cognito.message}</StyledTypography>;
  } else {
    return null;
  }
};
