import React from 'react';
import { Grid, styled, useMediaQuery } from '@mui/material';
import { AboutLaundryLab } from '@novozymes-digital/laundry-lab/components/AboutLaundryLab';
import { AuthTabs } from './AuthTabs';
import Footer from '@novozymes-digital/laundry-lab/components/UI/footer/Footer';

const StyledAuthPage = styled(Grid)(() => ({
  minHeight: 'calc(100vh - 45px)',
  width: '100%',
  margin: 0,
  padding: 0,
  border: 0,
}));

const StyledAuthTab = styled(StyledAuthPage)(() => ({
  height: '100%',
}));

const StyledContainer = styled('div')(() => ({
  '& p': {
    fontSize: '0.75rem !important',
    fontFamily: 'RaleGroteskBase !important',
  },
}));

export const AuthPage: React.FC = () => {
  const matches = useMediaQuery('(min-width:960px)');
  const ComponentToRender = matches ? StyledAuthPage : Grid;

  return (
    <ComponentToRender container spacing={0} alignContent="center" justifyContent="center">
      <StyledAuthPage item md={6} xs={12}>
        <AboutLaundryLab />
      </StyledAuthPage>
      <StyledAuthTab item md={6} xs={12}>
        <StyledAuthPage container alignContent="center" justifyContent="center">
          <Grid item>
            <AuthTabs />
          </Grid>
        </StyledAuthPage>
      </StyledAuthTab>
      <Grid item xs={12}>
        <StyledContainer>
          <Footer />
        </StyledContainer>
      </Grid>
    </ComponentToRender>
  );
};
