import React, { ReactNode, useState } from 'react';

import { StyledDialog, DialogTitle, DialogActions, DialogContent } from './UI/Dialog';

import {
  Accordion,
  IconButton,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableCellProps,
  AccordionSummary,
  styled,
} from '@mui/material';

import { getLocalStorageItem, setLocalStorageItem } from '../utility/LocalStorage';

import { ExpandMore, InfoOutlined } from '@mui/icons-material';

export interface Status {
  read: string;
  close: string;
}

export function getStatus(): Status {
  try {
    return (getLocalStorageItem('NEW_BEHIND_STATUS') as unknown as Status) || '';
  } catch (e) {
    return { read: '', close: '' };
  }
}

function setStatus(status: Status) {
  try {
    setLocalStorageItem('NEW_BEHIND_STATUS', status);
  } catch (e) {
    // empty
  }
}

const StyledIconButton = styled(IconButton)(() => ({
  color: 'var(--nz-white-95)',
  marginRight: '-16px',
}));

const StyledBox = styled(Box)(() => ({
  padding: '32px',

  '& > p': {
    paddingBottom: '22px',
  },
}));

const CenteredTypography = styled(Typography)({
  textAlign: 'center',
  fontSize: '0.75rem',
  lineHeight: '1.43',
  verticalAlign: 'inherit',
});

type AboutTableRow = ReactNode[];

const getCellProps = (defaultCellCount: number, currentCellCount: number, columnIndex: number): TableCellProps => {
  if (currentCellCount < defaultCellCount && columnIndex > 0) {
    const collumnSpan = defaultCellCount - 1;
    return { align: 'center', colSpan: collumnSpan };
  }

  return { align: 'left', style: { width: `calc(100% / ${defaultCellCount})` } };
};

const AboutTable = ({ rows, title, notes }: { rows: AboutTableRow[]; title?: ReactNode; notes?: ReactNode }) => (
  <Box mb={3}>
    {!!title && title}
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {rows.map((row, tabIndex) => (
            <TableRow key={tabIndex}>
              {row.map((rowData, columnIndex) => (
                <TableCell
                  key={tabIndex + '-' + columnIndex}
                  {...getCellProps(rows[0].length, row.length, columnIndex)}
                  align={columnIndex === rows[0].length - 1 ? 'center' : 'inherit'}
                >
                  {rowData}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {!!notes && notes}
  </Box>
);

interface Props {
  open?: boolean;
  onClose?: () => void;
  hiddenIcon?: boolean;
}

const BehindLaundryLabDialog: React.FC<Props> = ({ open = false, onClose, hiddenIcon = false }: Props) => {
  const lastDate = '2023-07-17';
  const status = getStatus();
  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    if (!open) toggleDialog();
    setStatus({ ...status, close: lastDate });
    if (onClose) onClose();
  };

  const handleRead = () => {
    handleClose();
    setStatus({ read: lastDate, close: lastDate });
  };

  const toggleDialog = () => {
    if (open) {
      if (onClose) {
        onClose();
      }
    } else {
      setOpen(!isOpen);
    }
  };

  return (
    <>
      {!hiddenIcon && (
        <StyledIconButton onClick={toggleDialog}>
          <InfoOutlined color="inherit" />
        </StyledIconButton>
      )}
      <StyledDialog
        open={isOpen || open}
        onClose={toggleDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        // maxWidth={'md'}
        PaperProps={{
          style: {
            maxWidth: 960,
          },
        }}
      >
        <DialogTitle onClose={handleRead}>Behind LaundryLab®</DialogTitle>
        <DialogContent>
          <StyledBox>
            <Typography variant="h2">Data foundation</Typography>

            <Typography variant="body1" component="p">
              The simulation model is based on wash data obtained in trials from detergent formulations prepared by
              Novonesis. These formulations contain the ingredients that we identified as the ones having a larger
              impact on the performance and are specific for the geographical region, and detergent format. The washing
              conditions & wash equipment used in the trials represent what Novonesis considers also the most relevant
              for the geographical region of interest.
            </Typography>
            <Typography variant="body1" component="p">
              Design of Experiments (DoE) is the statistical approach used to designing the wash experiments to generate
              the data needed to build the simulation model. DoE is a procedure for resource efficient planning of
              experiments so that the data obtained can be analyzed to yield valid and objective conclusions on complex
              interactions.
            </Typography>
            <Typography variant="body1" component="p">
              For LaundryLab, the DoE used to build the model consisted of an experimental program where some of the
              detergent ingredients were varied in different concentrations in a broad range. The goal is to identify
              all the individual effects of each ingredient and the combined effect of mixtures in the overall detergent
              performance. The DoE is built on statistics, and therefore considers possible interactions between
              components up to a third degree.
            </Typography>
            <Typography variant="body1" component="p">
              The power of DoE is that there is no need to include repeats in the experimental program (consecutive
              experiments using the same conditions), but it relies on internal replicates. Internal replicates refer to
              experiments that while overall have different conditions, they do replicate some of the factors. As an
              example, for a trial of 150 washes, each wash represented overall a different set of conditions (different
              concentrations of all the components); i.e., no repeated experiments. However, experiment 1, experiment 2
              and experiment “n” share as a common condition that the concentration of one component was the same in
              both experiments (internal replicates).
            </Typography>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <strong>Table 1.</strong> Washing conditions basis for the simulation <sup>a</sup>
                </Typography>
              </AccordionSummary>
              <AboutTable
                rows={[
                  [
                    'Washing conditions',
                    'Europe',
                    'China',
                    'North America',
                    'Latin America',
                    'Emerging Markets (IN / AFR / ME / SEA)',
                  ],
                  ['Washer type', 'Front Loader', 'Front Loader', 'Top Loader', 'Top Loader', 'TOM'],
                  [
                    'Wash cycle',
                    'Standard (no prewash)',
                    'Standard (no prewash)',
                    'Standard (no prewash)',
                    'Presoaked',
                    'Presoaked',
                  ],
                  ['Rinse', 'Yes', 'Yes', 'Yes', 'Yes', ' Yes'],
                  ['Water volume', '15.6 L', '37 L', '65 L', '56L', '1 L'],
                  ['Wash temperature', '30, 40 °C', '25 °C', '32 °C', '25 ºC', '30 °C'],
                  [
                    'Wash time',
                    '51 min main wash, 3 x 5 min rinse, 109 min total duration',
                    '15 min main wash, 3 min rinse, 44 min total duration',
                    '15 min main wash, 5 min rinse, 45 min total duration',
                    '30 min soaking, 19 min washing',
                    '5 min soak, 20 min wash',
                  ],
                  [
                    'Water hardness',
                    '14 dH Water hardness',
                    '14 dH Water hardness',
                    '6.72 dH Water hardness',
                    '150 ppm',
                    '150 / 350 / 450 ppm',
                  ],
                  ['Laundry load', '4 kg', '1.5 kg', '2.7 kg', '2.7 kg', '40 g'],
                  [
                    'Ballast',
                    'Standard EU ballast',
                    'Standard AP ballast',
                    'Standard NA ballast',
                    'Standard LATAM ballast',
                    'No Ballast',
                  ],
                  [
                    'Stains',
                    <>
                      EU relevant stains <br />
                      AISE stains v.
                    </>,
                    <>
                      China-relevant stains, <br />
                      CHIPS, JB group, JBS group
                    </>,
                    <>
                      NA relevant stains <br />
                      ASTM stains
                    </>,
                    <>
                      LATAM relevant stains <br />
                    </>,
                    <>
                      EM relevant standard stains <br />
                    </>,
                  ],
                  [
                    'Stain removal, Unit of measure',
                    <>Remisson (460 nm)</>,
                    <>Remisson (460 nm)</>,
                    <>SRI</>,
                    <>Delta E</>,
                    <>Remisson (460 nm)</>,
                  ],
                  ['Detergent Format', <>Liquid</>, <>Liquid</>, <>Liquid</>, <>Powder</>, <>Powder</>],
                ]}
              />
              <em>
                a. All models except for Emerging Market is trained based on FSW trial data; Emerging Market model is
                trained based on TOM trial data.
              </em>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  <strong>Table 2.</strong> Composition of detergent formulations
                </Typography>
              </AccordionSummary>
              <AboutTable
                notes={
                  <Typography style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>
                    <sup>a</sup> These ingredients cannot be adjusted in LaundryLab to explore new formulations.{' '}
                    <sup>b</sup> Fixed ingredients were used as fixed background.
                  </Typography>
                }
                rows={[
                  ['', 'Europe', 'China', 'North America', 'Latin America', 'Emerging Markets (IN / AFR / ME / SEA)'],
                  [
                    'Variable ingredients (ingredients part of LaundryLab’s scope to create formulations)',
                    <>
                      0-0.7692 g/L LAS
                      <br />
                      0-0.8846 g/L AES/SLES,
                      <br />
                      0-0.8846 g/L AEO,
                      <br />
                      <br />
                      0-0.0619 g/L Progress Uno 100 L,
                      <br />
                      0-0.0038 g/L Amplify Prime 100 L,
                      <br />
                      0-0.0269 g/L Lipex Evity 200 L,
                      <br />
                      0-0.0038 g/L Mannaway 200 L
                      <br />
                      <br />
                      0-0.1923 g/L citrate
                    </>,
                    <>
                      0-0.4 g/L LAS,
                      <br />
                      0-0.533 g/L AES/SLES,
                      <br />
                      0-0.4 g/L AEO,
                      <br />
                      <br />
                      0-0.0618 g/L Progress Uno 100 L,
                      <br />
                      0-0.0019 g/L Amplify Prime 100 L,
                      <br />
                      <br />
                      <br />
                      <br />
                      0-0.1 g/L citrate
                    </>,
                    <>
                      0-7.2 g/wash LAS
                      <br />
                      0-11.04 g/wash AES/SLES,
                      <br />
                      0-7.68 g/wash AEO,
                      <br />
                      <br />
                      0-1.2 g/wash Liquanase3.5L,
                      <br />
                      0-0.33 g/wash Amplify Prime 100 L,
                      <br />
                      <br />
                      0-0.288 g/wash Mannaway 200 L
                      <br />
                      <br />
                      0-0.14 g/wash Xpect 1000L
                      <br />
                      <br />0 or 0.96 g/wash citrate
                    </>,
                    <>
                      5.7-34.72 g/wash NaLAS
                      <br />
                      11.4-44.8 g/wash Sodium Carbonate
                      <br />
                      0-2.24 g/wash Savinase 12 T
                      <br />
                      0-1.12 g/wash Stainzyme Plus 12 T
                      <br />
                      0-1.12 g/wash Lipex 100 T
                      <br />
                      0-1.12 g/wash Mannaway 4 T
                      <br />
                      0-1.12 g/wash Celluclean 4500 T
                    </>,
                    <>
                      0-1.25 g/L LAS <br />
                      0-1.25 g/L Sodium Carbonate
                      <br />
                      0-0.6 g/L Silicate <br />
                      0-0.05 g/L Savinase 16 T
                      <br />
                      0-0.0142 g/L Stainzyme Plus 12 T <br />
                      0-0.01 g/L Lipex 100 T
                      <br />
                      0-0.0075 g/L Mannaway 4 T
                      <br />
                      0-0.0115 g/L Celluclean 4500 T
                      <br />
                      0-0.007 g/L Xpect 1000 T
                    </>,
                  ],
                  [
                    <>
                      Fixed ingredients<sup>a,b</sup>
                    </>,
                    <>
                      0.0385 g/L TEA,
                      <br />
                      0.096 g/L ethanol,
                      <br />
                      0.0385 g/L MPG
                      <br />
                      0.0769 g/L soap
                    </>,
                    <>
                      0.0385 g/L TEA,
                      <br />
                      0.096 g/L ethanol,
                      <br />
                      0.0385 g/L MPG
                      <br />
                      0.08 g/L soap
                    </>,
                    <>
                      <br />
                      <br />
                      <br />
                      0.949 g/wash soap
                    </>,
                    <>
                      0.02 g/wash Luthensol (non-ionic)
                      <br />
                      0.03 g/wash Sokalan CP5
                      <br />
                      0.09 g/wash Sodium Silicate
                      <br />
                      0.03 g/wash Zeolite
                    </>,
                    <>None</>,
                  ],
                  [
                    'Ingredients not included in the formulations ',
                    // eslint-disable-next-line react/jsx-key
                    <CenteredTypography>Polymers, FWA, perfume, strong chelators</CenteredTypography>,
                  ],
                ]}
              />
            </Accordion>
            <br />

            <Typography variant="h2">LaundryLab Models</Typography>

            <Typography variant="body1" component="p">
              LaundryLab models are very different from Large Language Models (like ChatGPT) in many ways:
            </Typography>
            <ul>
              <li>
                <Typography variant="body1" component="p">
                  ChatGPT-like models are generative models (sometimes referred to as Generative AI), meaning that they
                  try to use available information to potentially create something completely new compared to the
                  existing reality (e.g. create new text/images never seen before). That’s also why the “AI” term fits
                  better in there;
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="p">
                  LaundryLab models are only similar in the way that they are deep learning models, but they are only
                  applied in the context of supervised learning, and more specifically in the context of regression (and
                  that’s why the “machine learning” term fits best here), meaning that we “optimize” them based on the
                  true response (e.g. stain removal performance) but we do not aim to go beyond boundaries in the input
                  values (i.e. ingredients) that have been used to “teach” the model how to behave, and that’s why users
                  have this limitation when playing around with the tool. Of course users can still see strange behavior
                  here and there even within the ingredient boundaries, but these are very likely to reflect either the
                  lack of data, or odd behavior in the data itself. In other terms, in this context we just try to mimic
                  the current reality, differently from generative AI.{' '}
                </Typography>
              </li>
            </ul>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">More details</Typography>
              </AccordionSummary>

              <Typography>
                Standard machine/deep learning techniques use the input features as given features, i.e. it does not
                really matter how they have been generated - what matters is what the combination of those features
                returns as an outcome, as we would like to mimic that as good as possible.
              </Typography>
              <Typography>
                When using generative deep learning models, you are also interested in what&rsquo;s behind the
                generation of the input data, so you want to model their probability density distribution in combination
                with the response.
              </Typography>
              <Typography>
                If you can learn that density distribution, after the model training you can use that to actually
                generate outcomes that have never existed in the past.
              </Typography>
              <Typography>
                However, In order to apply the generative deep learning models you would definitely need:
              </Typography>
              <ul>
                <li>
                  <Typography variant="body1" component="p">
                    A higher amount data (you suddenly want to learn a very high-dimensional density distribution,
                    rather than learning only the distribution of your response)
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1" component="p">
                    A more complex neural network architecture (to be able to capture all the additional complexity)
                  </Typography>
                </li>
              </ul>
            </Accordion>
          </StyledBox>
        </DialogContent>
        <DialogActions style={{ margin: '0', padding: '12px 24px', background: 'white' }}>
          <Button onClick={handleRead} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default BehindLaundryLabDialog;
