import { ReactNode } from 'react';
import Navbar from '@novozymes-digital/laundry-lab/components/Navbar';
import { styled } from '@mui/material';
import Footer from '../components/UI/footer/Footer';

const StyledContainer = styled('div')(() => ({
  '& p': {
    fontSize: '0.75rem !important',
    fontFamily: 'RaleGroteskBase !important',
  },
}));

const PageWithHeader = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <>
      <Navbar />
      <div style={{ minHeight: 'calc(100vh - (68px + 45px))' }}>{children}</div>

      <StyledContainer>
        <Footer />
      </StyledContainer>
    </>
  );
};

export default PageWithHeader;
