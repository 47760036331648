import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableCellProps, styled } from '@mui/material';

import {
  IngredientsGroupLayout,
  LAS_INGREDIENT_ID,
  AES_INGREDIENT_ID,
  // AEO_INGREDIENT_ID,
  BASELINE_LABEL,
} from '@novozymes-digital/laundry-lab/static/Constants';

import Chart, { GetColorFunc } from './NewChart';
import Layout from './Layout';
import Tabs from './Tabs';
import Table, { TableRow, TableCell } from './Table';
import { CostBreakdownColors, FALLBACK_COLOR } from '@novozymes-digital/laundry-lab/static/chartColors';
import {
  getCurrentCollectionCurrency,
  getCurrentCollectionPrices,
  getCurrentCollectionRegion,
  getIngredientList,
  getIngredientsByRegionAndGroup,
  getSelectedBaselineId,
  getUserAllGrants,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { Formulation, Prices } from '@novozymes-digital/laundry-lab/store/types';

interface Props {
  formulations: Formulation[];
}

interface StyledTableCellProps extends TableCellProps {
  startSection?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTableCell = styled(({ startSection, ...otherProps }: StyledTableCellProps) => (
  <TableCell {...otherProps} />
))(({ startSection }: StyledTableCellProps) => ({
  borderTop: startSection ? `1px solid var(--nz-blue-l)` : 'none',
}));

function calculateCost(weight: number, cost: number): number {
  return !cost ? 0 : Math.round(((weight / 100) * 1000 * cost + Number.EPSILON) * 10) / 10;
}

// eslint-disable-next-line react/prop-types
const PriceCell: React.FunctionComponent<TableCellProps> = ({ children, ...props }) => {
  return <TableCell {...props}>{children}</TableCell>;
};

// eslint-disable-next-line react/prop-types
const TotalPriceCell: React.FunctionComponent<TableCellProps> = ({ children, ...props }) => {
  const currency = useSelector(getCurrentCollectionCurrency);
  return (
    <PriceCell {...props}>
      {children} {currency}/Tonne
    </PriceCell>
  );
};

interface StyledPriceCellProps extends TableCellProps {
  startSection?: boolean;
  children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledPriceCell = styled(({ startSection, ...otherProps }: StyledPriceCellProps) => (
  <PriceCell {...otherProps} />
))(({ startSection }: StyledPriceCellProps) => ({
  borderTop: startSection ? `1px solid var(--nz-blue-l)` : 'none',
}));

interface GroupProps {
  formulations: Formulation[];
  ingredientCost: Prices;
  ingredientsLayout: IngredientsGroupLayout[];
  tab?: number;
}

const IngredientGroups: React.FunctionComponent<GroupProps> = ({
  formulations,
  ingredientCost,
  ingredientsLayout,
}: GroupProps) => {
  const totals: number[] = formulations.map(() => 0);

  return (
    <>
      {ingredientsLayout.map(({ group_label, ingredients }, index) => (
        <TableRow key={index}>
          <StyledTableCell startSection={index === 0}>{group_label}</StyledTableCell>
          {formulations.map((formulation, fIndex) => {
            const value = ingredients.reduce((acc, label) => {
              const key = Object.keys(label)[0] as keyof Prices;
              return acc + calculateCost(formulation[key], ingredientCost[key]);
            }, 0);
            totals[fIndex] += value;
            return (
              <StyledPriceCell startSection={index === 0} key={fIndex} align="left">
                {Math.round((value + Number.EPSILON) * 10) / 10}
              </StyledPriceCell>
            );
          })}
        </TableRow>
      ))}
      <TableRow
        style={{ backgroundColor: CostBreakdownColors['LAST_COL'] ? CostBreakdownColors['LAST_COL'] : 'inherit' }}
      >
        <StyledTableCell>Total cost</StyledTableCell>
        {totals.map((value, index) => (
          <TotalPriceCell key={index} align="left">
            {Math.round(value)}
          </TotalPriceCell>
        ))}
      </TableRow>
    </>
  );
};

const Details: React.FunctionComponent<GroupProps> = ({
  formulations,
  ingredientCost,
  ingredientsLayout,
  tab,
}: GroupProps) => {
  const totals: number[] = formulations.map(() => 0);
  const userAllGrants = useSelector(getUserAllGrants);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

  return (
    <>
      {ingredientsLayout[tab || 0].ingredients.map((label, index) => {
        const key = Object.keys(label)[0] as keyof Prices;

        return (
          <React.Fragment key={key}>
            {tab === 0 && key === LAS_INGREDIENT_ID && (
              <TableRow>
                <StyledTableCell>Surfactants Anionic</StyledTableCell>
                {formulations.map((formulation, i) => (
                  <StyledPriceCell key={i} align="left">
                    {calculateCost(formulation[LAS_INGREDIENT_ID], ingredientCost[LAS_INGREDIENT_ID]) +
                      calculateCost(formulation[AES_INGREDIENT_ID], ingredientCost[AES_INGREDIENT_ID])}
                  </StyledPriceCell>
                ))}
              </TableRow>
            )}
            {/* {tab === 0 && key === AEO_INGREDIENT_ID && (
              <TableRow>
                <StyledTableCell>Surfactants Nonionic</StyledTableCell>
                {formulations.map((formulation, i) => (
                  <StyledTableCell key={i} align="left">
                    {calculateCost(formulation[AEO_INGREDIENT_ID], ingredientCost[AEO_INGREDIENT_ID])}
                  </StyledTableCell>
                ))}
              </TableRow>
            )} */}
            <TableRow>
              <StyledTableCell startSection={index === 0}>{Object.values(label)[0]}</StyledTableCell>

              {formulations.map((formulation, fIndex) => {
                const cost = calculateCost(formulation[key], ingredientCost[key]);

                // Check if the key is 'medleyBrilliant400l' and grantGlobalCosmed is false
                if (!(key === 'medleyBrilliant400l' && !grantGlobalCosmed)) {
                  totals[fIndex] += cost;
                  return (
                    <StyledPriceCell key={fIndex} startSection={index === 0} align="left">
                      {cost}
                    </StyledPriceCell>
                  );
                }

                // If the condition is not met, don't render anything
                return null;
              })}
            </TableRow>
          </React.Fragment>
        );
      })}
      <TableRow
        style={{ backgroundColor: CostBreakdownColors['LAST_COL'] ? CostBreakdownColors['LAST_COL'] : 'inherit' }}
      >
        <TableCell>Total cost</TableCell>
        {totals.map((value, index) => (
          <TotalPriceCell key={index} align="left">
            {Math.round(value)}
          </TotalPriceCell>
        ))}
      </TableRow>
    </>
  );
};

function generateSummaryData(
  formulations: Formulation[],
  ingredientsLayout: IngredientsGroupLayout[],
  ingredientCost: Prices,
  ingredientList: Record<string, string>,
  baseline?: string
) {
  const labels: string[] = [];
  const datasets: any[] = [];

  const offsets: number[] = formulations.map(() => 0);
  const baselineFormulation = formulations.find((formulation) => baseline && formulation.id === baseline);

  ingredientsLayout.forEach(({ group_label: label, ingredients }, index) => {
    const values: number[] = [];
    const rawValues: number[] = [];
    formulations.forEach((formulation, fIndex) => {
      if (formulation.id === baseline) return;
      if (index === 0) labels.push(formulation.name);

      const value = ingredients.reduce((acc, label) => {
        const key = Object.keys(label)[0] as keyof Prices;
        return acc + calculateCost(formulation[key], ingredientCost[key]);
      }, 0);

      rawValues.push(value);
      values.push(value + offsets[fIndex]);
      offsets[fIndex] += value;
    });

    datasets.push({
      label,
      values: rawValues,
      data: values,
    });
  });

  if (baseline) {
    const value = Object.keys(ingredientList).reduce((acc, key) => {
      if (!baselineFormulation) {
        return acc;
      }
      return acc + calculateCost(baselineFormulation[key as keyof Prices], ingredientCost[key as keyof Prices]);
    }, 0);

    datasets.unshift({
      label: baselineFormulation?.name + ' (' + BASELINE_LABEL + ')',
      baseline: true,
      data: formulations.slice(1).map(() => value),
    });
  }

  return {
    labels,
    datasets,
  };
}

function generateGroupData(
  formulations: Formulation[],
  ingredientsLayout: IngredientsGroupLayout[],
  ingredientCost: Prices,
  tab: number,
  baseline?: string
) {
  const labels: string[] = [];
  const datasets: any[] = [];
  const baselineFormulation = formulations.find((formulation) => baseline && formulation.id === baseline);

  const offsets: number[] = formulations.map(() => 0);

  ingredientsLayout[tab].ingredients.forEach((ingredient, index) => {
    const values: number[] = [];
    const rawValues: number[] = [];
    const [key, label] = Object.entries(ingredient)[0] as [keyof Prices, string];

    formulations.forEach((formulation, fIndex) => {
      if (baseline === formulation.id) return;
      if (index === 0) labels.push(formulation.name);
      const value = calculateCost(formulation[key], ingredientCost[key]);

      rawValues.push(value);
      values.push(value + offsets[fIndex]);
      offsets[fIndex] += value;
    });

    datasets.push({
      label,
      values: rawValues,
      data: values,
    });
  });

  if (baseline) {
    const value = ingredientsLayout[tab].ingredients.reduce((acc, ingredient) => {
      const key = Object.keys(ingredient)[0] as keyof Prices;

      if (!baselineFormulation) {
        return acc;
      }
      return acc + calculateCost(baselineFormulation[key], ingredientCost[key]);
    }, 0);
    datasets.unshift({
      label: baselineFormulation?.name + ' (' + BASELINE_LABEL + ')',
      baseline: true,
      data: formulations.slice(1).map(() => value),
    });
  }

  return {
    labels,
    datasets,
  };
}

const getCostColors: GetColorFunc = ({ label }) => {
  return label && CostBreakdownColors[label] ? CostBreakdownColors[label] : FALLBACK_COLOR;
};

const sortFormulations = (formulations: Formulation[], baseLineId: string) => {
  if (!baseLineId) return formulations;

  return [...formulations].sort((a, b) => {
    return a.id === baseLineId ? 1 : b.id === baseLineId ? -1 : 0;
  });
};

const CostBreakdown: React.FunctionComponent<Props> = ({ formulations }: Props) => {
  const region = useSelector(getCurrentCollectionRegion);
  const ingredientsLayout = useSelector(getIngredientsByRegionAndGroup)[region] || [];
  const ingredientList = useSelector(getIngredientList);
  const ingredientPrices = useSelector(getCurrentCollectionPrices);
  const currency = useSelector(getCurrentCollectionCurrency);

  const [tab, setTab] = useState(0);

  const tabLabels = ['Ingredient Groups', ...ingredientsLayout.map(({ group_label }) => group_label)];

  const baselineId = useSelector(getSelectedBaselineId) || '';
  const sortedFormulations = useMemo(() => sortFormulations(formulations, baselineId), [formulations, baselineId]);

  const tableLabels = sortedFormulations.map((formulation) => ({
    label: formulation.name + (!!baselineId && formulation.id === baselineId ? ' (baseline)' : ''),
    isBaseline: !!baselineId && formulation.id === baselineId,
  }));

  if (!ingredientPrices) {
    return null;
  }

  let data;
  if (tab === 0) {
    data = generateSummaryData(sortedFormulations, ingredientsLayout, ingredientPrices, ingredientList, baselineId);
  } else {
    data = generateGroupData(sortedFormulations, ingredientsLayout, ingredientPrices, tab - 1, baselineId);
  }

  return (
    <Layout data-cy="costbreakdown-data-panel" title="Cost breakdown" id="costbreakdown">
      <Tabs selected={tab} labels={tabLabels} onChange={(_, v) => setTab(v)}></Tabs>
      <Chart data={data} getColor={getCostColors} subtitle={currency + '/Tonne'} />
      <Table labels={tableLabels} labelsOffsetText={[currency + '/Tonne']} noLabelColors>
        {tab === 0 && (
          <IngredientGroups
            formulations={sortedFormulations}
            ingredientCost={ingredientPrices}
            ingredientsLayout={ingredientsLayout}
          />
        )}
        {tab !== 0 && (
          <Details
            formulations={sortedFormulations}
            ingredientCost={ingredientPrices}
            ingredientsLayout={ingredientsLayout}
            tab={tab - 1}
          />
        )}
      </Table>
    </Layout>
  );
};

export default CostBreakdown;
