import React from 'react';

import Layout from './Layout';
import Chart, { GetColorFunc } from './NewChart';
import { FALLBACK_COLOR } from '@novozymes-digital/laundry-lab/static/chartColors';
import { useSelector } from 'react-redux';
import {
  getSelectedBaselineData,
  getSustainabilityResultsById,
  getFormulationsColor,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { Formulation, FormulationColor, SustainabilityResult } from '@novozymes-digital/laundry-lab/store/types';
import { formatSustainabilityDynamic } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';

interface Props {
  formulations: Formulation[];
}
function generateDataset(
  title: string,
  subtitle: string,
  key: keyof SustainabilityResult,
  selectedFormulations: Formulation[],
  sustainabilityGetter: (id: string) => SustainabilityResult | undefined,
  selectedBaseline: Formulation | undefined,
  formulationsColor: FormulationColor[],
  minValues: any
): [string, string, any] {
  const datasets = selectedFormulations
    .filter((formulation) => !(!!selectedBaseline && formulation.id === selectedBaseline?.id))
    .reduce<any>((acc, formulation) => {
      const formulationSustainability = sustainabilityGetter(formulation.id);

      if (!formulationSustainability) {
        return acc;
      }

      if (selectedBaseline) {
        const baselineSustainability = sustainabilityGetter(selectedBaseline.id);
        if (baselineSustainability) {
          acc.push({
            label: `${selectedBaseline.name} (Baseline)`,
            data: [formatSustainabilityDynamic(baselineSustainability[key], minValues[key], key).value],
            stack: formulation.name,
            baseline: true,
            color: formulationsColor.find((f) => f.currentFormId === selectedBaseline.id)?.color,
          });
        }
      }

      acc.push({
        label: formulation.name,
        data: [formatSustainabilityDynamic(formulationSustainability[key], minValues[key], key).value],
        stack: formulation.name,
        color: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color,
      });

      return acc;
    }, []);

  return [
    title,
    subtitle,
    {
      labels: [title],
      datasets,
    },
  ];
}

const Sustainability: React.FC<Props> = ({ formulations }: Props) => {
  const sustainabilityGetter = useSelector(getSustainabilityResultsById);
  const selectedBaseline = useSelector(getSelectedBaselineData);
  const formulationsColor = useSelector(getFormulationsColor);

  const getSustColors: GetColorFunc = ({ label }) => {
    if (formulations) {
      const formulation = formulations.find((item: any) => item.name === label);
      if (formulation) {
        const colorObj = formulationsColor.find((obj: any) => obj.currentFormId === formulation.id);
        return colorObj ? colorObj.color : FALLBACK_COLOR;
      }
    }
    return FALLBACK_COLOR;
  };

  const minValues = {
    co2: Math.min(
      ...formulations
        .filter((formulation) => !(!!selectedBaseline && formulation.id === selectedBaseline?.id))
        .map((formulation) => sustainabilityGetter(formulation.id)?.co2 || 0)
    ),
    chemical: Math.min(
      ...formulations
        .filter((formulation) => !(!!selectedBaseline && formulation.id === selectedBaseline?.id))
        .map((formulation) => sustainabilityGetter(formulation.id)?.chemical || 0)
    ),
    cdv: Math.min(
      ...formulations
        .filter((formulation) => !(!!selectedBaseline && formulation.id === selectedBaseline?.id))
        .map((formulation) => sustainabilityGetter(formulation.id)?.cdv || 0)
    ),
  };

  const charts: Array<[string, string, any]> = [
    generateDataset(
      'CO2',
      `${formatSustainabilityDynamic(minValues.co2, minValues.co2, 'co2').suffix}/year`,
      'co2',
      formulations,
      sustainabilityGetter,
      selectedBaseline,
      formulationsColor,
      minValues
    ),
    generateDataset(
      'Chemicals',
      `${formatSustainabilityDynamic(minValues.chemical, minValues.chemical, 'chemical').suffix}/year`,
      'chemical',
      formulations,
      sustainabilityGetter,
      selectedBaseline,
      formulationsColor,
      minValues
    ),
    generateDataset(
      'CDV',
      `${formatSustainabilityDynamic(minValues.cdv, minValues.cdv, 'cdv').suffix}/year`,
      'cdv',
      formulations,
      sustainabilityGetter,
      selectedBaseline,
      formulationsColor,
      minValues
    ),
  ];

  return (
    <Layout data-cy="sustainabilityimpact-data-panel" title="Sustainability" id="sustainabilityimpact">
      {charts.map(([title, subtitle, data], index) => (
        <Chart noLegend={index > 0} key={title} getColor={getSustColors} data={data} subtitle={subtitle}></Chart>
      ))}
    </Layout>
  );
};

export default Sustainability;
