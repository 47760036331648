export const validateForm = (
  _event: unknown,
  state: { username?: string; password?: string }
): { blankfield: boolean } | undefined => {
  if (state.hasOwnProperty.call(state, 'username') && state.username === '') {
    return { blankfield: true };
  }
  if (state.hasOwnProperty.call(state, 'password') && state.password === '') {
    return { blankfield: true };
  }
  return;
};
