import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Formulation } from '@novozymes-digital/laundry-lab/store/types';
import EditIcon from '@novozymes-digital/laundry-lab/icons/EditIcon';
import DeleteIcon from '@novozymes-digital/laundry-lab/icons/DeleteIcon';
import CloneIcon from '@novozymes-digital/laundry-lab/icons/CloneIcon';
import { styled } from '@mui/material';

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    '& ul': {
      margin: 0,
    },
  },
}));

interface SimpleMenuProps {
  formulation: Formulation;
  currentBaselineId?: string;
  onClickAction: (action: string) => void;
  options?: {
    edit?: boolean;
    clone?: boolean;
    delete?: boolean;
  };
}
export default function SimpleMenu({
  formulation,
  currentBaselineId,
  onClickAction,
  options = { edit: true, clone: true, delete: true }, // all true by default
}: SimpleMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    onClickAction('edit');
  };

  const handleDelete = () => {
    handleClose();
    onClickAction('delete');
  };

  const handleClone = () => {
    handleClose();
    onClickAction('clone');
  };

  return (
    <div>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <StyledMenu id="fade-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {options.edit && (
          <MenuItem
            data-cy={'formulation-edit-' + formulation.name}
            onClick={handleEdit}
            disabled={formulation.id === currentBaselineId}
          >
            <EditIcon /> edit formulation
          </MenuItem>
        )}
        {options.clone && (
          <MenuItem
            data-cy={'formulation-clone-' + formulation.name}
            onClick={handleClone}
            disabled={formulation.id === currentBaselineId}
          >
            <CloneIcon /> clone formulation
          </MenuItem>
        )}
        {options.delete && (
          <MenuItem data-cy={'formulation-delete-' + formulation.name} onClick={handleDelete}>
            {' '}
            <DeleteIcon /> delete
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
