import React from 'react';
import { Typography, Grid, styled } from '@mui/material';
import { ReactComponent as Logo } from '@novozymes-digital/laundry-lab/images/LaundryLab_logo_white_256.svg';

const GridAboutpage = styled(Grid)({
  color: 'var(--nz-white-95)',
  height: '100%',
  width: '100%',
  backgroundColor: '#173629',
  margin: 0,
  padding: 0,
  border: 0,
});

const TypographyLaundryLabDescription = styled(Typography)({
  paddingTop: '32px',
  height: '100%',
  color: 'var(--nz-white-95)',
  fontStyle: 'italic',
  fontSize: '18px',
  lineHeight: '24px',
  textAlign: 'left',
});

export const AboutLaundryLab: React.FunctionComponent = () => {
  return (
    <GridAboutpage container alignContent="center" alignItems="center">
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Logo />
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <TypographyLaundryLabDescription>
            LaundryLab is an advanced simulation software to predict performance of consumer laundry formulations. You
            can simulate cleaning performance of formulations and calculate their associated costs and sustainability
            profiles.
            <br />
            <br />
            LaundryLab can be used as a screening tool to select &amp; validate in the lab only the most optimal
            formulations to cover your needs, allowing for significant savings in washing resources and faster time to
            market.
          </TypographyLaundryLabDescription>
        </Grid>
        <GridAboutpage item xs={2} />
      </Grid>
    </GridAboutpage>
  );
};
