import { Auth } from 'aws-amplify';
import { replace } from 'redux-first-history';
import forge, { Middleware, Request, Response } from 'mappersmith';
import EncodeJson from 'mappersmith/middleware/encode-json';
import GlobalErrorHandler, { setErrorHandler } from 'mappersmith/middleware/global-error-handler';
import { notificationShow } from './actions/actions';
import { store } from './index';

setErrorHandler((response: Response) => {
  store.dispatch(
    notificationShow({
      message: 'Something went wrong with your request. Please try again later.',
      status: 'error',
    })
  );
  return response.status() === 500;
});

const TokenAdderMiddleware: Middleware = () => ({
  request: async (request: Request): Promise<Request> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user.getSession((err: Error, session: any) => {
        const accessToken = session.getAccessToken();
        const jwtToken = accessToken.getJwtToken();

        return request.enhance({
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
      });
    } catch (error) {
      console.log('Error in TokenAdderMiddleware!');

      if (error === 'not authenticated') {
        const path = window.location.pathname;
        if (path.includes('signin')) {
          store.dispatch(replace('/signin'));
        } else {
          store.dispatch(replace('/sso'));
        }
      }

      return request;
    }
  },
});

const api = forge({
  host: process.env.REACT_APP_API_URL || '',
  middleware: [TokenAdderMiddleware, EncodeJson, GlobalErrorHandler],
  resources: {
    User: {
      securityGroup: { method: 'GET', path: '/get_security_group' },
      logMe: { method: 'POST', path: '/logme' },
      colFormStains: { method: 'GET', path: '/col_form_stains', binary: true },
      opportunities: {
        method: 'GET',
        path: 'get_user_opportunities',
      },
      feedback: { method: 'POST', path: 'feedback' },
    },
    Collections: {
      get: { path: '/user_collection' },
      create: { method: 'POST', path: '/create_collection' },
      update: { method: 'PATCH', path: '/update_collection' },
      pinn: { method: 'POST', path: '/set_collection_star' },
      delete: { method: 'POST', path: '/delete_user_collection' },
      clone: { method: 'POST', path: '/clone_user_collection' },
      send: { method: 'POST', path: '/clone_switch_collection_owner' },
    },
    Formulations: {
      get: { path: '/collection/{collectionId}/formulations' },
      create: { method: 'POST', path: '/save_formulation' },
      delete: { method: 'POST', path: '/delete_formulation' },
      update: { method: 'PATCH', path: '/update_formulation' },
      clone: { method: 'POST', path: '/clone_formulation' },
    },
    CustomStains: {
      get: { method: 'GET', path: '/collection/{collectionId}/custom_stain_groups ' },
      create: { method: 'POST', path: '/collection/save_custom_stain_group' },
      delete: { method: 'POST', path: '/collection/delete_custom_stain_group ' },
      update: { method: 'POST', path: '/collection/set_custom_stain_group ' },
    },
    Calculate: {
      stainRemoval: {
        method: 'GET',
        path: '/model_prediction',
      },
      sustainability: {
        method: 'POST',
        path: '/sustainability_impact',
      },
      ecolabel: {
        method: 'POST',
        path: '/ecolabel',
      },
      collection: {
        method: 'POST',
        path: '/collection/{collectionId}/calculate',
      },
      responseCurve: {
        method: 'POST',
        path: '/collection/{collectionId}/response_curve',
      },
    },
    Constants: {
      blends: {
        method: 'GET',
        path: 'get_blends',
      },
      stainGroups: {
        method: 'GET',
        path: 'get_stain_groups',
      },
      ingredients: {
        method: 'GET',
        path: 'get_ingredients',
      },
      revision: {
        method: 'GET',
        path: '/get_revision',
      },
      regions: {
        method: 'GET',
        path: 'get_regions',
      },
      stainsRegions: {
        method: 'GET',
        path: 'get_stain_regions',
      },
    },
  },
});

export default api;
