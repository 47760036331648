/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Grid, Box, Checkbox, FormControlLabel, Link, styled } from '@mui/material';
import { formatUnixDateTime } from '@novozymes-digital/laundry-lab/utility/Date';
import { getLocalStorageItem, setLocalStorageItem } from '@novozymes-digital/laundry-lab/utility/LocalStorage';

import config from '@novozymes-digital/laundry-lab/config/cognito.json';
import { Errors } from './Error';
import { useSelector } from 'react-redux';
import { getMessage } from '@novozymes-digital/laundry-lab/store/selectors';
import CheckboxUnchecked from '@novozymes-digital/laundry-lab/icons/CheckboxUnchecked';
import CheckboxChecked from '@novozymes-digital/laundry-lab/icons/CheckboxChecked';

interface Status {
  acceptedTerms?: boolean;
  acceptedDate?: string;
}

function getStatus(): Status {
  try {
    const status: Status | undefined = getLocalStorageItem<Status>('ACCEPTED_T&C_STATUS');

    if (status === undefined) {
      return { acceptedTerms: false, acceptedDate: '' };
    }
    return status;
  } catch (e) {
    console.error(e);
    return { acceptedTerms: false, acceptedDate: '' };
  }
}

function setStatus(status: Status) {
  try {
    setLocalStorageItem('ACCEPTED_T&C_STATUS', status);
  } catch (e) {
    console.error(e);
  }
}

const StyledLinkTC = styled(Link)(() => ({
  fontSize: '16px',
  textDecoration: 'underline',
}));

const StyledSSOButton = styled(Button)(() => ({
  borderColor: '#bfe6c4',
  backgroundColor: 'var(--nz-bg-2)',
  justifyContent: 'flex-start',
  padding: '12px 18px ',
  width: '400px',
  '&:hover': {
    backgroundColor: '#d5d5d5',
  },
}));

export const SSOSignIn: React.FC = () => {
  const status = getStatus();
  const [acceptTerms, setAcceptTerms] = useState(status.acceptedTerms);
  const message = useSelector(getMessage);

  const handleAcceptTerms = (event: any) => {
    const accepted: boolean = event.target.checked;
    setAcceptTerms(accepted);
    setStatus({ acceptedTerms: accepted, acceptedDate: accepted ? formatUnixDateTime(new Date()) : '' });
  };

  const handleSsoAD = () => {
    Auth.federatedSignIn({ customProvider: config.cognito.COGNITO_IDENTITY_PROVIDER_AD });
  };

  const handleSsoSF = () => {
    Auth.federatedSignIn({ customProvider: process.env.REACT_APP_IDP_SF || '' });
  };

  const renderSignIn = () => {
    return (
      <Box>
        <Grid container direction="column" justifyContent="center" spacing={3}>
          <Grid item>
            <Grid container direction="column">
              <FormControlLabel
                label={
                  <>
                    I accept Novonesis' &nbsp;
                    <StyledLinkTC
                      data-cy="open-tc"
                      id="open-tc"
                      color="inherit"
                      variant="body2"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
                        e.preventDefault();
                        window.open('https://www.novonesis.com/en/legal-notice-and-terms-use', '_blank');
                      }}
                    >
                      legal notice
                    </StyledLinkTC>
                    &nbsp; and&nbsp;
                    <StyledLinkTC
                      data-cy="open-pp"
                      id="open-pp"
                      color="inherit"
                      variant="body2"
                      onClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
                        e.preventDefault();
                        window.open('https://www.novonesis.com/en/privacy-policy', '_blank');
                      }}
                    >
                      privacy policy
                    </StyledLinkTC>
                  </>
                }
                control={
                  <Checkbox
                    data-cy="agree-to-tc"
                    id="agree-to-tc"
                    name="agreeToTC"
                    checked={acceptTerms}
                    onClick={handleAcceptTerms}
                    color="primary"
                    checkedIcon={<CheckboxChecked />}
                    icon={<CheckboxUnchecked />}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <StyledSSOButton
                data-cy="sso-azuread-button"
                variant="contained"
                disabled={!acceptTerms}
                onClick={handleSsoAD}
              >
                Sign in with your Novonesis organization account
              </StyledSSOButton>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <StyledSSOButton
                data-cy="sso-salesforce-button"
                variant="contained"
                disabled={!acceptTerms}
                onClick={handleSsoSF}
              >
                Sign in with your TS Portal account
              </StyledSSOButton>
            </Grid>
          </Grid>
        </Grid>
        <Errors message={message} />
      </Box>
    );
  };

  return (
    <Box pt={1} pb={3}>
      {renderSignIn()}
    </Box>
  );
};
