import { NzTypography } from '@novozymes-digital/dezymer-core';
import { ComponentPropsWithoutRef } from 'react';

import styled from 'styled-components';

interface NzFooterLinkProps extends ComponentPropsWithoutRef<'a'> {
  bold?: boolean;
  children: React.ReactNode;
}

const StyledLink = styled.a<{ $bold?: boolean }>`
  margin-right: 16px;

  font-weight: ${(props) => (props.$bold ? '800' : undefined)};
  text-decoration: none;
  color: var(--nz-black-67);
`;

export function NzFooterLink({ children, bold, ...rest }: NzFooterLinkProps) {
  if (typeof children !== 'string') {
    return <StyledLink {...rest}>{children}</StyledLink>;
  }

  return (
    <StyledLink $bold={bold} {...rest}>
      <NzTypography variant="p5">{children}</NzTypography>
    </StyledLink>
  );
}

export default NzFooterLink;
