import React from 'react';

import { Box, Grid, Typography, styled } from '@mui/material';

import { tooltipSettings } from '@novozymes-digital/laundry-lab/static/Constants';

import { Chart as ChartBar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Tooltip, SubTitle } from 'chart.js';
import { BarWithErrorBarsController, BarWithErrorBar } from 'chartjs-chart-error-bars';
import { isEqual, cloneDeep } from 'lodash';

ChartJS.register(BarWithErrorBarsController, BarWithErrorBar, CategoryScale, LinearScale, Tooltip, SubTitle);

const StyledBox = styled(Box)(() => ({
  maxHeight: '28px',
}));

const StyledTypography = styled(Typography)(() => ({
  paddingTop: '12px',
}));

export const baselineChartSettings = {
  // label: 'Baseline',
  backgroundColor: 'transparent',
  borderWidth: {
    top: 0,
    right: 1,
    bottom: 0,
    left: 0,
  },
  maxBarThickness: 50,
  borderColor: 'rgba(0, 0, 0, 0.80)',
  barPercentage: 1.0,
  categoryPercentage: 0.8,
};

export const commonBarChartSettings = {
  borderWidth: 1,
  barPercentage: 0.8,
  categoryPercentage: 0.7,
  maxBarThickness: 35,
  borderColor: '#E6E6E6',
};

interface Props {
  data: any;
  subtitle?: string;
  noLegend?: boolean;
}

export const colors = [
  ['#bfe6c4', '#bfe6c4'],
  ['#EFBE88', '#EFBE88'],
  ['#16416A', '#16416A'],
  ['#EBEDCE', '#EBEDCE'],
  ['#92A2A6', '#92A2A6'],
  ['#9BA08C', '#9BA08C'],
  ['#E6E6E6', '#E6E6E6'],
  ['#7C8BD9', '#7C8BD9'],
];

const canvas = document.createElement('canvas');

const Chart: React.FunctionComponent<Props> = ({ data, subtitle, noLegend }: Props) => {
  let hasBaseline = false;
  let baselineLabel = 'Baseline';

  const copyData = cloneDeep(data); //prevent Chart from changing props and re-render

  const filteredDatasets = copyData.datasets
    .map((values: any) => Object.assign(values, values.baseline ? baselineChartSettings : commonBarChartSettings))
    .filter((values: any) => {
      if (values.baseline) {
        hasBaseline = true;
        baselineLabel = values.label || baselineLabel;
      }
      return !values.baseline;
    });

  filteredDatasets.forEach((values: any, index: number) => {
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const gradient = ctx.createLinearGradient(0, 0, 400, 0);
      gradient.addColorStop(0, values.color || colors[index % 7][0]);
      gradient.addColorStop(1, values.color || colors[index % 7][1]);
      values.backgroundColor = gradient;
    }
  });

  if (!copyData.labels.length) return null;

  const hasSubSubLabels: boolean = copyData.subSubLabels
    ? !copyData.subSubLabels.every((label: string) => label == null)
    : false;
  const lineHeight = filteredDatasets.length > 2 ? 100 : hasSubSubLabels ? 80 : 60;
  const height = copyData.labels.length * lineHeight + 28 + 40;
  const LABEL_LEFT_WIDTH = 150;

  let xAxisMax = 0;
  copyData.datasets.forEach((set: any) => {
    const dataAux = set.data;
    dataAux.forEach((element: any) => {
      const value = typeof element === 'object' ? element.xMax : element;
      xAxisMax = value > xAxisMax ? value : xAxisMax;
    });
  });

  return (
    <Box data-cy="stain-removal-chart">
      {!noLegend && (
        <Box mb={2} ml={1} style={{ paddingLeft: LABEL_LEFT_WIDTH, marginTop: '32px' }}>
          <Grid container spacing={3}>
            {filteredDatasets.map((dataset: any, index: number) => (
              <Grid item xs={2} key={dataset.label + index}>
                <Grid container wrap="nowrap">
                  <StyledBox
                    bgcolor={dataset.color || colors[index % 7][0]}
                    width={6}
                    mr={1}
                    flexShrink={0}
                    borderRadius={8}
                  />
                  <Typography variant="caption">{dataset.label}</Typography>
                </Grid>
              </Grid>
            ))}
            {hasBaseline && (
              <Grid item xs={3} container wrap="nowrap">
                <StyledBox bgcolor="black" width={2} mr={1} borderRadius={8} />
                <Typography variant="caption">{baselineLabel}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item style={{ width: 150 }}>
          <Grid container direction="column">
            {copyData.labels.map((label: string, index: number) => (
              <Grid key={label + index} item container direction="column" style={{ height: lineHeight }}>
                <StyledTypography variant={copyData.subLabels ? 'caption' : 'body2'} align="left">
                  <b>{label}</b>
                </StyledTypography>
                {copyData.subLabels && copyData.subLabels[index] && (
                  <Typography variant="caption" align="left">
                    {copyData.subLabels[index]}
                  </Typography>
                )}
                {copyData.subSubLabels && copyData.subSubLabels[index] && (
                  <Typography variant="caption" align="left">
                    {copyData.subSubLabels[index]}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item style={{ width: 'calc(100% - 150px)' }}>
          <div style={{ height }}>
            <ChartBar
              type={'barWithErrorBars'}
              redraw={false}
              data={copyData}
              //updateMode={'resize'}
              options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                //resizeDelay: 5000,
                animation: {
                  //duration: 0,
                },
                layout: {
                  padding: {
                    bottom: 40,
                  },
                },
                interaction: {
                  mode: 'index',
                  axis: 'y',
                },
                scales: {
                  y: {
                    ticks: {
                      display: false,
                    },
                    stacked: true,
                    beginAtZero: true,
                    grid: {
                      display: false,
                    },
                  },
                  x: {
                    ticks: {
                      callback: (value: any) => {
                        return value;
                      },
                    },
                    suggestedMax: xAxisMax,
                    //min: 0,
                    stacked: false,
                    beginAtZero: true,
                  },
                },
                plugins: {
                  subtitle: {
                    display: true,
                    text: subtitle,
                    position: 'bottom',
                  },
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    //mode: 'index',
                    ...tooltipSettings,
                    filter: function (data: any, dataIndex: any) {
                      const datasetAux: any = data?.dataset;
                      return !hasBaseline || (datasetAux && !datasetAux['baseline']) || dataIndex === 0;
                    },
                    callbacks: {
                      label: function (context: any) {
                        const { dataIndex, dataset } = context;
                        const { label } = dataset;
                        const value =
                          typeof dataset.data[dataIndex] === 'object'
                            ? dataset.data[dataIndex]['x']
                            : dataset.data[dataIndex];

                        return `${label}: ${value}`;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Chart, isEqual);
