import TagManager from 'react-gtm-module';

enum LLGtmEvents {
  SignOut = 'user-signed-out',
  Authenticate = 'user-authenticated',
  AuthenticateFailed = 'user-authenticate-failed',
  ExportExcelRequest = 'export-data-requested',
  ExportExcelSucceed = 'export-data-succeeded',
  ResponseCurveRequest = 'response-curve-requested',
  CreateFormulationRequest = 'create-formulation-requested',
  CreateFormulationSucceed = 'create-formulation-succeeded',
  CreateCollectionRequest = 'create-collection-requested',
  CreateCollectionSucceed = 'create-collection-succeeded',
  UpdateCollectionRequest = 'update-collection-requested',
  UpdateCollectionSucceed = 'update-collection-succeeded',
  UpdateFormulationRequest = 'update-formulation-requested',
  UpdateFormulationSucceed = 'update-formulation-succeeded',
  DeleteFormulation = 'delete-formulation',
  DeleteCollection = 'delete-collection',
  CalculateFormulation = 'calculate-formulation',
  CalculateNewFormulation = 'calculate-new-formulation',
  CreateCustomStainGroupRequest = 'create-custom-stain-group-requested',
  CreateCustomStainGroupSucceed = 'create-custom-stain-group-succeeded',
  SetCustomStainGroupRequest = 'set-custom-stain-group-requested',
  SetCustomStainGroupSucceed = 'set-custom-stain-group-succeeded',
  DeleteCustomStainGroup = 'delete-custom-stain-group',
  CloneFormulation = 'clone-formulation',
  CloneCollection = 'clone-collection',
  SendCollection = 'send-collection',
  SendFeedback = 'send-feedback',
}

export const GTMEvent = (id: string, props?: Record<string, unknown>): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: id,
      ...props,
    },
  });
};

export const GtmSignOutEvent = (): void => GTMEvent(LLGtmEvents.SignOut);
export const GtmAuthenticateEvent = (): void => GTMEvent(LLGtmEvents.Authenticate);
export const GtmAuthenticateFailedEvent = (): void => GTMEvent(LLGtmEvents.AuthenticateFailed);
export const GtmExportExcelRequestEvent = (props?: Record<string, unknown>): void =>
  GTMEvent(LLGtmEvents.ExportExcelRequest, props);
export const GtmExportExcelSucceedEvent = (props?: Record<string, unknown>): void =>
  GTMEvent(LLGtmEvents.ExportExcelSucceed, props);
export const GtmResponseCurveRequestEvent = (props?: Record<string, unknown>): void =>
  GTMEvent(LLGtmEvents.ResponseCurveRequest, props);
export const GtmCreateFormulationRequestEvent = (): void => GTMEvent(LLGtmEvents.CreateFormulationRequest);
export const GtmCreateFormulationSucceedEvent = (): void => GTMEvent(LLGtmEvents.CreateFormulationSucceed);
export const GtmCreateCollectionRequestEvent = (): void => GTMEvent(LLGtmEvents.CreateCollectionRequest);
export const GtmCreateCollectionSucceedEvent = (): void => GTMEvent(LLGtmEvents.CreateCollectionSucceed);
export const GtmUpdateCollectionRequestEvent = (): void => GTMEvent(LLGtmEvents.UpdateCollectionRequest);
export const GtmUpdateCollectionSucceedEvent = (): void => GTMEvent(LLGtmEvents.UpdateCollectionSucceed);
export const GtmUpdateFormulationRequestEvent = (): void => GTMEvent(LLGtmEvents.UpdateFormulationRequest);
export const GtmUpdateFormulationSucceedEvent = (): void => GTMEvent(LLGtmEvents.UpdateFormulationSucceed);
export const GtmDeleteFormulationEvent = (): void => GTMEvent(LLGtmEvents.DeleteFormulation);
export const GtmDeleteCollectionEvent = (): void => GTMEvent(LLGtmEvents.DeleteCollection);
export const GtmCalculateFormulationEvent = (): void => GTMEvent(LLGtmEvents.CalculateFormulation);
export const GtmCalculateNewFormulationEvent = (): void => GTMEvent(LLGtmEvents.CalculateNewFormulation);
export const GtmCreateCustomStainGroupRequestEvent = (): void => GTMEvent(LLGtmEvents.CreateCustomStainGroupRequest);
export const GtmCreateCustomStainGroupSucceedEvent = (): void => GTMEvent(LLGtmEvents.CreateCustomStainGroupSucceed);
export const GtmSetCustomStainGroupRequestEvent = (): void => GTMEvent(LLGtmEvents.SetCustomStainGroupRequest);
export const GtmSetCustomStainGroupSucceedEvent = (): void => GTMEvent(LLGtmEvents.SetCustomStainGroupSucceed);
export const GtmDeleteCustomStainGroupEvent = (): void => GTMEvent(LLGtmEvents.DeleteCustomStainGroup);
export const GtmCloneCollectionEvent = (): void => GTMEvent(LLGtmEvents.CloneCollection);
export const GtmCloneFormulationEvent = (): void => GTMEvent(LLGtmEvents.CloneFormulation);
export const GtmSendCollectionEvent = (): void => GTMEvent(LLGtmEvents.SendCollection);
export const GtmSendFeedbackEvent = (): void => GTMEvent(LLGtmEvents.SendFeedback);
