import React, { useRef, useEffect } from 'react';

interface Props {
  children: React.ReactNode;
  activeNavClass: string;
  reload?: boolean;
}

const ScrollNav: React.FunctionComponent<Props & { activeNavClass?: string }> = ({
  children,
  reload,
  activeNavClass = '',
}: Props & { activeNavClass?: string }) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = container.current;
    if (el) {
      const anchors = el.querySelectorAll('a');
      const thresholds = [0];

      anchors.forEach((anchorEl, index) => {
        if (index === 0) return;
        const target = document.querySelector(anchorEl.hash) as HTMLElement;
        thresholds[index] = target?.offsetTop + target?.clientHeight / 4 ?? 0;
      });

      const selectAnchor = (anchorEl: HTMLAnchorElement) => {
        if (container.current) {
          container.current.querySelectorAll('a').forEach((link) => {
            link.classList.remove(activeNavClass);
          });
        }

        anchorEl.classList.add(activeNavClass);
      };

      const onScroll = () => {
        const offset = window.pageYOffset;

        let targetIndex;

        if (offset === 0) {
          targetIndex = 0;
        } else if (offset + window.innerHeight === document.body.offsetHeight) {
          targetIndex = thresholds.length - 1;
        } else {
          const breakpoint = offset + window.innerHeight / 2;
          targetIndex = thresholds.findIndex(
            (v, i) => breakpoint >= v && breakpoint <= (thresholds[i + 1] || Infinity)
          );
          targetIndex = targetIndex === -1 ? 0 : targetIndex;
        }

        if (anchors.length > 0) {
          if (!anchors[targetIndex].classList.contains(activeNavClass)) {
            selectAnchor(anchors[targetIndex]);
          }
        }
      };

      onScroll();

      let timeoutId: NodeJS.Timeout;
      const onAnchorClick = (event: MouseEvent) => {
        const anchorEl = event.composedPath().find((el) => el instanceof HTMLAnchorElement) as HTMLAnchorElement;

        if (anchorEl) {
          event.preventDefault();
          const target = document.querySelector(anchorEl.hash) as HTMLElement;

          if (target) {
            const offset = 100; // sticky nav height
            window.scroll({ top: target.offsetTop - offset, left: 0, behavior: 'smooth' });

            window.removeEventListener('scroll', onScroll);
            selectAnchor(anchorEl);
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              if (container.current) {
                window.addEventListener('scroll', onScroll);
              }
            }, 1000);
          }
        }
      };

      window.addEventListener('scroll', onScroll);
      el.addEventListener('click', onAnchorClick);

      return () => {
        window.removeEventListener('scroll', onScroll);
        el.removeEventListener('click', onAnchorClick);
      };
    }
  }, [activeNavClass, container, reload]);

  return <div ref={container}>{children}</div>;
};

export default ScrollNav;
