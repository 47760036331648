import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '16px',
  height: '16px',
}));

export default function CollectionPinSelected(): JSX.Element {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.98438 5.53906L6.67969 2.75H7.6875C7.99219 2.75 8.25 2.51562 8.25 2.1875V1.0625C8.25 0.757812 7.99219 0.5 7.6875 0.5H1.3125C0.984375 0.5 0.75 0.757812 0.75 1.0625V2.1875C0.75 2.51562 0.984375 2.75 1.3125 2.75H2.29688L1.99219 5.53906C0.867188 6.05469 0 7.01562 0 8.1875C0 8.51562 0.234375 8.75 0.5625 8.75H3.75V11.2109C3.75 11.2344 3.75 11.2578 3.75 11.2812L4.3125 12.4062C4.38281 12.5469 4.59375 12.5469 4.66406 12.4062L5.22656 11.2812C5.22656 11.2578 5.25 11.2344 5.25 11.2109V8.75H8.4375C8.74219 8.75 9 8.51562 9 8.1875C9 6.99219 8.10938 6.05469 6.98438 5.53906Z"
          fill="#C5DA00"
        />
      </svg>
    </StyledGrid>
  );
}
