import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ComparePerformance from './ComparePerformance';
import { styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '100%',
  height: 'auto',
  paddingTop: '68px',
  margin: 'auto',
  overflowAnchor: 'none',
}));

export const MainPanel: React.FC = () => {
  return (
    <StyledGrid container alignContent="center" justifyContent="flex-start">
      <Grid item sm={12}>
        <Box height="auto">
          <ComparePerformance />
        </Box>
      </Grid>
    </StyledGrid>
  );
};
