import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '14px',
  height: '14px',
  marginRight: '12px',
}));

export default function DeleteIcon(): JSX.Element {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.42069 1.92151C5.53009 1.81211 5.67846 1.75065 5.83317 1.75065H8.1665C8.32121 1.75065 8.46959 1.81211 8.57898 1.92151C8.68838 2.0309 8.74984 2.17927 8.74984 2.33398V2.91732H5.24984V2.33398C5.24984 2.17927 5.3113 2.0309 5.42069 1.92151ZM4.08317 2.91732V2.33398C4.08317 1.86986 4.26755 1.42474 4.59573 1.09655C4.92392 0.768359 5.36904 0.583984 5.83317 0.583984H8.1665C8.63063 0.583984 9.07575 0.768359 9.40394 1.09655C9.73213 1.42474 9.9165 1.86986 9.9165 2.33398V2.91732H11.0832L12.2498 2.91732C12.572 2.91732 12.8332 3.17848 12.8332 3.50065C12.8332 3.82282 12.572 4.08398 12.2498 4.08398H11.6665V11.6673C11.6665 12.1314 11.4821 12.5766 11.1539 12.9048C10.8258 13.2329 10.3806 13.4173 9.9165 13.4173H4.08317C3.61904 13.4173 3.17392 13.2329 2.84573 12.9048C2.51755 12.5766 2.33317 12.1314 2.33317 11.6673V4.08398H1.74984C1.42767 4.08398 1.1665 3.82282 1.1665 3.50065C1.1665 3.17848 1.42767 2.91732 1.74984 2.91732L2.9165 2.91732H4.08317ZM3.49984 4.08398V11.6673C3.49984 11.822 3.5613 11.9704 3.67069 12.0798C3.78009 12.1892 3.92846 12.2507 4.08317 12.2507H9.9165C10.0712 12.2507 10.2196 12.1892 10.329 12.0798C10.4384 11.9704 10.4998 11.822 10.4998 11.6673V4.08398H3.49984ZM5.83317 5.83398C6.15534 5.83398 6.4165 6.09515 6.4165 6.41732V9.91732C6.4165 10.2395 6.15534 10.5007 5.83317 10.5007C5.511 10.5007 5.24984 10.2395 5.24984 9.91732V6.41732C5.24984 6.09515 5.511 5.83398 5.83317 5.83398ZM8.74984 6.41732C8.74984 6.09515 8.48867 5.83398 8.1665 5.83398C7.84434 5.83398 7.58317 6.09515 7.58317 6.41732V9.91732C7.58317 10.2395 7.84434 10.5007 8.1665 10.5007C8.48867 10.5007 8.74984 10.2395 8.74984 9.91732V6.41732Z"
          fill="black"
        />
      </svg>
    </StyledGrid>
  );
}
