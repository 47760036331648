import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { IngredientsGroupLayout, Region } from '@novozymes-digital/laundry-lab/static/Constants';

import { parseResult } from './CustomFunctions';
import {
  Collection,
  ExportedFormulation,
  Ingredient,
  Prices,
  RegionType,
} from '@novozymes-digital/laundry-lab/store/types';

function generateGeneralInfo(
  wb: ExcelJS.Workbook,
  activeCollection: Collection,
  weightUnit: string,
  ingredients: IngredientsGroupLayout[],
  regions: Region,
  grantGlobalCosmed: boolean
) {
  const ws = wb.addWorksheet('General', {
    properties: { defaultColWidth: 20 },
  });
  const priceLabel = `(${activeCollection.currency}/${weightUnit})`;

  const labels = ingredients.reduce((acc: any, item: IngredientsGroupLayout) => {
    return Object.assign(acc, ...item.ingredients);
  }, {});

  ws.addRows([
    ['This file was exported from LaundryLab®, an app developed by Novonesis.'],
    ['The tabs contain formulations, simulations, and results from the app.'],
    [],
    ['The app was used with the below settings:'],
    [''],
    ['Region', `${regions[activeCollection.region].name} (${regions[activeCollection.region].detergent_format})`],
  ]);

  if (activeCollection.detergent_volume) {
    ws.addRows([['Detergent volume (tonnes)', activeCollection.detergent_volume]]);
  }
  ws.addRows([
    [''],
    ['Ingredient prices:'],
    [''],
    ...Object.entries(labels)
      .map(([key, value]) => {
        if (key.toLowerCase() === 'medleybrilliant400l' && !grantGlobalCosmed) {
          return null;
        }
        return [`${value} ${priceLabel}`, activeCollection.prices[key as keyof Prices]];
      })
      .filter(Boolean),
  ]);

  [ws.getCell(4, 1), ws.getCell(8, 1)].forEach((cell) => {
    cell.font = { bold: true };
  });
}

const filterHeadings = (
  headings: Array<{ [key: string]: string | number }>,
  config: { [key: string]: boolean },
  grantGlobalCosmed: boolean
): string[] => {
  return headings
    .filter((col: any) => {
      const normalized = col.key.replaceAll(' ', '_').toLowerCase();
      return config[normalized] !== false && (normalized !== 'medley_brilliant_400l' || grantGlobalCosmed);
    })
    .map((col: any) => col.value);
};

const filterDataRows = (
  dataRows: Array<Array<{ [key: string]: string | number }>>,
  config: { [key: string]: boolean }
): string[] => {
  return dataRows.map((row: any) =>
    row
      .filter((col: any) => {
        const normalized = col.key.replaceAll(' ', '_').toLowerCase();
        return config[normalized] !== false;
      })
      .map((col: any) => col.value)
  );
};

function generateFormulationDetails(
  wb: ExcelJS.Workbook,
  activeCollection: Collection,
  formulations: ExportedFormulation[],
  ingredients: IngredientsGroupLayout[],
  region: RegionType,
  regions: Region,
  grantGlobalCosmed: boolean
) {
  const ws = wb.addWorksheet('Formulation details', {
    properties: { defaultColWidth: 20 },
  });
  const collectionTemp = activeCollection.temperature;
  const dose_scale = regions[region].dose_scale;

  const excel_config = regions[region].excel_config;
  const formulation_details = excel_config?.formulation_details ? excel_config.formulation_details : {};

  const labels = ingredients.reduce((acc: any, item: IngredientsGroupLayout) => {
    return Object.assign(acc, ...item.ingredients);
  }, {});

  const headings: Array<{ [key: string]: string | number }> = [
    { key: 'Formulation name', value: 'Formulation name' },
    { key: 'Temperature', value: 'Temperature' },
    { key: 'Water Hardness', value: 'Water Hardness' },
    { key: 'Dosage', value: 'Dosage' },
    ...Object.values(labels).map((label: any) => ({ key: label, value: label })),
  ];
  const headersFiltered = filterHeadings(headings, formulation_details, grantGlobalCosmed);

  const dataRows: Array<Array<{ [key: string]: string | number }>> = formulations.map((formulation) => {
    const { name, dose, temperature, water_hardness, ...ingredients } = formulation;

    const finalTemperature = temperature || collectionTemp; // the formulation temperature takes precedence over the collection temperature
    return [
      { key: 'Formulation name', value: name },
      { key: 'Temperature', value: finalTemperature },
      { key: 'Water Hardness', value: water_hardness },
      { key: 'Dosage', value: `${dose} ${dose_scale}` },
      ...Object.keys(labels).map((key) => ({ key: key, value: ingredients[key as Ingredient] / 100 })),
    ];
  });
  const dataRowsFiltered = filterDataRows(dataRows, formulation_details);

  ws.addRows([headersFiltered, ...dataRowsFiltered]);

  ws.getRow(1).font = { bold: true };
  ws.getRows(2, formulations.length)?.forEach((row: { numFmt: string }) => {
    row.numFmt = '0.00%';
  });

  const tempCol = ws.getColumn(2);
  tempCol.numFmt = '0 °C';
  tempCol.width = 15;

  const waterHardnessCol = ws.getColumn(3);
  waterHardnessCol.numFmt = '0 "ppm"';
  waterHardnessCol.width = 15;

  const doseCol = ws.getColumn(4);
  doseCol.width = 10;
}

function generatePerformanceLongFormat(
  wb: ExcelJS.Workbook,
  formulations: ExportedFormulation[],
  stainGroupNamesForRegion: Record<string, string>,
  region: RegionType,
  regions: Region,
  grantGlobalCosmed: boolean
) {
  const ws = wb.addWorksheet('Stain removal, long format', {
    properties: { defaultColWidth: 20 },
  });

  const excel_config = regions[region].excel_config;
  const stain_removal_long_format = excel_config?.stain_removal_long_format
    ? excel_config.stain_removal_long_format
    : {};

  const UoM = regions[region].uom_stain_removal[0];

  const headings: Array<{ [key: string]: string | number }> = [
    { key: 'Formulation name', value: 'Formulation name' },
    { key: 'Stain group', value: 'Stain group' },
    { key: 'Stain code', value: 'Stain code' },
    { key: 'Stain name', value: 'Stain name' },
    { key: 'Textile', value: 'Textile' },
    { key: 'UoM', value: UoM },
    { key: 'variation of UoM', value: `+/- variation of ${UoM}` },
  ];
  const headersFiltered = filterHeadings(headings, stain_removal_long_format, grantGlobalCosmed);

  const dataRows: Array<Array<{ [key: string]: string | number }>> = [];

  formulations.map((formulation: ExportedFormulation) => {
    return Object.keys(stainGroupNamesForRegion).map((stainGroupKey) => {
      const stains = formulation.stainRemoval[stainGroupKey] || {};
      return Object.keys(stains).map((stainKey) => {
        const stain = stains[stainKey];
        return dataRows.push([
          { key: 'Formulation name', value: formulation.name },
          { key: 'Stain group', value: stainGroupNamesForRegion[stainGroupKey] },
          { key: 'Stain code', value: stain.stainCode },
          { key: 'Stain name', value: stain.name },
          { key: 'Textile', value: stain.textile },
          { key: UoM, value: stain.predicted },
          { key: `+/- variation of ${UoM}`, value: stain.standardDeviation },
        ]);
      });
    });
  });

  const dataRowsFiltered = filterDataRows(dataRows, stain_removal_long_format);
  ws.addRows([headersFiltered, ...dataRowsFiltered]);

  ws.getRow(1).font = { bold: true };
}

function generatePerformanceOverview(
  wb: ExcelJS.Workbook,
  formulations: ExportedFormulation[],
  stainGroupNamesForRegion: Record<string, string>,
  region: RegionType,
  regions: Region,
  grantGlobalCosmed: boolean
) {
  const ws = wb.addWorksheet('Stain removal, overview', {
    properties: { defaultColWidth: 20 },
  });

  const excel_config = regions[region].excel_config;
  const stain_removal_overview = excel_config?.stain_removal_overview ? excel_config.stain_removal_overview : {};

  const stainRemovalUoM = regions[region].uom_stain_removal;

  const headings: Array<{ [key: string]: string | number }> = [
    { key: 'Stain group', value: 'Stain group' },
    { key: 'Stain code', value: 'Stain code' },
    { key: 'Stain name', value: 'Stain name' },
    { key: 'Textile', value: 'Textile' },
    ...formulations.map((formulation) => ({
      key: 'Formulation name',
      value: `${formulation.name} (${stainRemovalUoM})`,
    })),
  ];

  const headersFiltered = filterHeadings(headings, stain_removal_overview, grantGlobalCosmed);

  const aux: any = {};
  const formulationsStains: any = {};

  formulations.map((formulation: ExportedFormulation) => {
    return Object.keys(stainGroupNamesForRegion).map((stainGroupKey) => {
      const stains = formulation.stainRemoval[stainGroupKey] || {};
      // eslint-disable-next-line array-callback-return
      return Object.keys(stains).map((stainKey) => {
        const stain = stains[stainKey];

        const id: string = stainGroupKey + stain.stainCode + stain.name;
        const fs: string = stain.stainCode + formulation.name;

        if (!formulationsStains[fs]) {
          if (!aux[id]) {
            aux[id] = [];
          }

          aux[id].push({
            formulationName: formulation.name,
            stainGroupLabel: stainGroupNamesForRegion[stainGroupKey],
            stain_code: stain.stainCode,
            display_name_eng: stain.name,
            textile: stain.textile,
            REM: stain.predicted,
          });

          formulationsStains[fs] = fs;
        }
      });
    });
  });

  const dataRows: Array<Array<{ [key: string]: string | number }>> = [];

  // eslint-disable-next-line array-callback-return
  Object.keys(aux).map((key: any) => {
    const aux2 = aux[key][0];
    dataRows.push([
      { key: 'Stain group', value: aux2.stainGroupLabel },
      { key: 'Stain code', value: aux2.stain_code },
      { key: 'Stain name', value: aux2.display_name_eng },
      { key: 'Textile', value: aux2.textile },
      ...aux[key].map((data: any) => ({ key: data.formulationName, value: data.REM })),
    ]);
  });

  const dataRowsFiltered = filterDataRows(dataRows, stain_removal_overview);
  ws.addRows([headersFiltered, ...dataRowsFiltered]);

  ws.getRow(1).font = { bold: true };
}

function generateCost(
  wb: ExcelJS.Workbook,
  collection: Collection,
  formulations: ExportedFormulation[],
  ingredients: IngredientsGroupLayout[],
  region: RegionType,
  regions: Region,
  grantGlobalCosmed: boolean
) {
  const ws = wb.addWorksheet('Cost breakdown', {
    properties: { defaultColWidth: 20 },
  });

  const excel_config = regions[region].excel_config;
  const cost_breakdown = excel_config?.cost_breakdown ? excel_config.cost_breakdown : {};

  const priceLabel = `(${collection.currency}/tonne)`;

  const labels = ingredients.reduce((acc: Array<any>, item: IngredientsGroupLayout) => {
    acc.push(...item.ingredients.map((i: any) => Object.values(i)[0]));
    return acc;
  }, []);

  const headings: Array<{ [key: string]: string | number }> = [
    { key: 'Formulation name', value: 'Formulation name' },
    { key: 'Formulation cost', value: `Formulation cost ${priceLabel}` },
    { key: 'Surfactants', value: `Surfactants ${priceLabel}` },
    { key: 'Chemicals', value: `Chemicals ${priceLabel}` },
    { key: 'Builders', value: `Builders ${priceLabel}` },
    { key: 'Enzymes', value: `Enzymes ${priceLabel}` },
    { key: 'Fillers', value: `Fillers ${priceLabel}` },
    { key: 'Others', value: `Others ${priceLabel}` },
    ...labels.map((v) => ({
      key: v,
      value: `${v} ${priceLabel}`,
    })),
  ];

  const headersFiltered = filterHeadings(headings, cost_breakdown, grantGlobalCosmed);

  const dataRows = formulations.map((formulation: ExportedFormulation) => {
    const result: Array<{ [key: string]: number | string }> = [];

    const sums: { [key: string]: number } = {};

    ingredients.forEach((item) => {
      let tempSum = 0;

      item.ingredients.forEach((i) => {
        const [key] = Object.keys(i);
        const cost = Math.round(
          parseResult(((formulation as any)[key] / 100) * 1000 * collection.prices[key as keyof Prices])
        );
        result.push({ key: key, value: cost });
        tempSum += cost;
      });

      sums[item.group_label] = tempSum;
    });

    return [
      { key: 'Formulation name', value: formulation.name },
      { key: `Formulation cost`, value: Object.values(sums).reduce((acc, v) => acc + v, 0) },
      { key: `Surfactants`, value: sums['Surfactants'] },
      { key: `Chemicals`, value: sums['Chemicals'] },
      { key: `Builders`, value: sums['Builders'] },
      { key: `Enzymes`, value: sums['Enzymes'] },
      { key: 'Fillers', value: sums['Fillers'] },
      { key: 'Others', value: sums['Others'] },
      ...result,
    ];
  });

  const dataRowsFiltered = filterDataRows(dataRows, cost_breakdown);

  ws.addRows([headersFiltered, ...dataRowsFiltered]);

  ws.getRow(1).font = { bold: true };
}

function generateSustainability(wb: ExcelJS.Workbook, formulations: ExportedFormulation[]) {
  const ws = wb.addWorksheet('Sustainability', {
    properties: { defaultColWidth: 30 },
  });

  ws.addRows([
    ['', 'Sustainability figures', '', ''],
    ['Formulation name', `CO2 (million tonnes)`, `Chemicals (million tonnes)`, `CDV (billion m3 water)`],
    ...formulations.map((formulation) => [
      formulation.name,
      formulation.sustainability.co2,
      formulation.sustainability.chemical,
      formulation.sustainability.cdv,
    ]),
  ]);

  ws.getRow(2).font = { bold: true };
  ws.mergeCells('B1:D1');

  ws.getCell('B1').alignment = { horizontal: 'center' };
}

function generateEcolabel(wb: ExcelJS.Workbook, formulations: ExportedFormulation[]) {
  const ws = wb.addWorksheet('EU Ecolabel', {
    properties: { defaultColWidth: 30 },
  });

  ws.addRows([
    ['', 'Eco label', '', ''],
    ['', 'Lives up to Criterion 1?', 'Lives up to Criterion 2?', 'Lives up to Criterion 3?'],
    ['Formulation name', 'Dosage requirements', 'Toxicity to aquatic organisms', 'Biodegradability'],
    ...formulations.map((formulation) => [
      formulation.name,
      ...Object.values(formulation.ecolabel).map((v) => (v === 'approved' ? 'Yes' : 'No')),
    ]),
  ]);

  ws.getRow(2).font = { bold: true };
  ws.getRow(3).font = { bold: true };

  ws.mergeCells('B1:D1');

  ws.getCell('B1').alignment = { horizontal: 'center' };
}

export default async function exportXlsx({
  activeCollection,
  formulations,
  weightUnit,
  stainGroupNames,
  ingredients,
  regions,
  grantGlobalCosmed,
}: {
  activeCollection: Collection;
  formulations: ExportedFormulation[];
  weightUnit: string;
  stainGroupNames: Record<string, string>;
  ingredients: IngredientsGroupLayout[];
  regions: Region;
  grantGlobalCosmed: boolean;
}): Promise<void> {
  const wb = new ExcelJS.Workbook();
  wb.creator = 'Novonesis LaundryLab Team';
  wb.created = new Date();

  const region = activeCollection.region;
  const ingredientsExcel = JSON.parse(JSON.stringify(ingredients));

  for (const ingredientGroup of ingredientsExcel) {
    for (const ingredient of ingredientGroup.ingredients) {
      delete ingredient['data_points'];
      delete ingredient['max_value'];
      delete ingredient['min_value'];
      delete ingredient['response_curve'];
      delete ingredient['price'];
      delete ingredient['tooltip'];
      delete ingredient['name'];
      delete ingredient['label'];
      delete ingredient['step'];
      delete ingredient['disabled'];
      delete ingredient['is_blend'];
    }
  }

  generateGeneralInfo(wb, activeCollection, weightUnit, ingredientsExcel, regions, grantGlobalCosmed);
  generateFormulationDetails(
    wb,
    activeCollection,
    formulations,
    ingredientsExcel,
    activeCollection.region,
    regions,
    grantGlobalCosmed
  );
  generatePerformanceLongFormat(wb, formulations, stainGroupNames, activeCollection.region, regions, grantGlobalCosmed);
  generatePerformanceOverview(wb, formulations, stainGroupNames, activeCollection.region, regions, grantGlobalCosmed);
  generateCost(
    wb,
    activeCollection,
    formulations,
    ingredientsExcel,
    activeCollection.region,
    regions,
    grantGlobalCosmed
  );
  generateSustainability(wb, formulations);
  !['la', 'afr', 'ind', 'me', 'sea'].includes(region) && generateEcolabel(wb, formulations);

  const buffer = await wb.xlsx.writeBuffer();
  await saveAs(new Blob([buffer]), 'LaundryLab.xlsx');
}
