import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#bfe6c4',
    },
    secondary: {
      light: '#EEFFF0',
      main: 'rgba(0, 0, 0, 0.8)',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.8)',
    },
    background: {
      default: '#EEFFF0',
    },
  },
  typography: {
    fontFamily: 'RaleGroteskBase',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: 'rgba(0, 0, 0, 0.8)',
          margin: '0',
          fontSize: '0.75rem',
          fontFamily: 'RaleGroteskBase',
          fontWeight: '400',
          lineHeight: '1.43',
          backgroundColor: '#EEFFF0',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '30px',
          borderTopRightRadius: '30px',
          borderRadius: '30px',
        },
        input: {
          padding: '12px 10px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.8)',
          padding: '4px 16px',
          fontSize: '16px',
          minWidth: '64px',
          boxSizing: 'border-box',
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          fontFamily: 'RaleGroteskBase',
          fontWeight: '400',
          lineHeight: '1.75',
          borderRadius: '58px',
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          minWidth: '120px',
          border: '1px solid transparent',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          padding: '4px 16px',
          border: 'solid 1px #2a2a2a',
          fontSize: '16px',
        },
        sizeSmall: {
          fontWeight: 'normal',
          padding: '3px 15px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '40px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          fontWeight: 'bold',
          paddingBottom: '8px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'RaleGroteskBase',
          '&.MuiDialogContentText-root': {
            color: 'black',
          },
        },
        body1: {
          fontSize: '1rem',
          fontFamily: 'RaleGroteskBase',
          fontWeight: 400,
          lineHeight: 1.5,
        },
        body2: {
          fontSize: '0.75rem',
        },
        h1: {
          fontWeight: 'bold',
        },
        h2: {
          fontWeight: 'bold',
          fontSize: '2rem',
          marginBottom: '0.5rem',
        },
        h3: {
          fontWeight: 'bold',
          fontSize: '1.5rem',
          marginBottom: '0.5rem',
        },
        h4: {
          fontSize: '1.1rem',
          fontStyle: 'italic',
          marginBottom: '0.5rem',
        },
        h5: {
          fontWeight: 'bold',
        },
        subtitle1: {
          fontWeight: 'bold',
        },
        caption: {
          lineHeight: '16px',
        },
        gutterBottom: {
          marginBottom: '0.6em',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          display: 'table-cell',
          padding: '16px',
          fontSize: '0.75rem',
          textAlign: 'left',
          fontFamily: 'RaleGroteskBase',
          fontWeight: '400',
          lineHeight: '1.43',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          verticalAlign: 'inherit',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px',
          fontWeight: 'normal',
          backgroundColor: 'rgba(0,0,0,0.8)',
          padding: '8px 10px',
          borderRadius: '4px',
        },
        arrow: {
          color: 'rgba(0,0,0,0.8)',
          '::before': {
            backgroundColor: 'rgba(0,0,0,0.8)',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineHover: {
          textDecorationColor: '#bfe6c4',
          textDecorationThickness: '2px',
        },
        underlineAlways: {
          textDecoration: 'underline',
          textDecorationColor: '#bfe6c4',
          textDecorationThickness: '2px',
        },
        button: {
          fontSize: '16px',
          margin: '0px 20px',
          textDecoration: 'underline',
          textDecorationColor: '#bfe6c4',
          textDecorationThickness: '3px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          '&.MuiDialog-paper': {
            minWidth: '300px',
            maxWidth: '960px',
          },
        },
        elevation1: {
          boxShadow: '0',
        },
        elevation8: {
          '& ul': {
            maxHeight: '300px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '&.MuiDialog-paper': {
            maxWidth: '600px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            borderRadius: '0.5rem',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiMenu-list': {
            // margin: '16px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          flex: '0 0 auto',
          display: 'flex',
          padding: '8px',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            opacity: '0',
          },
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
        },
        content: {
          flexGrow: 0,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          // flexWrap: 'nowrap',
        },
      },
    },
  },
});
