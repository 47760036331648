import { IconNameType, NzIcon } from '@novozymes-digital/dezymer-icons';
import styled, { css } from 'styled-components';

export type NewChipActionsPropsTypes = Array<{
  position: string;
  iconName: IconNameType;
  label?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}>;

export interface NewChipProps {
  checked: boolean;
  actions: NewChipActionsPropsTypes;
  label?: string;
  disabled: boolean;
  variant: 'primary' | 'secondary';
  isEditing: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onChange?: (input: unknown) => void;
  onKeyDown?: (input: unknown) => void;
  fullWidth: boolean;
}

const handleBgColor = (checked: boolean, disabled: boolean, variant: string, isEditing: boolean) => {
  if (isEditing) {
    return 'var(--nz-bg-3)';
  }
  if (disabled) {
    return variant === 'primary' ? 'var(--nz-dataGray)' : 'var(--nz-dataGray)';
  }
  switch (checked) {
    case true:
      return variant === 'primary' ? '#13291F' : 'var(--nz-green-minor)';
    case false:
      return variant === 'primary' ? 'var(--nz-blue-l)' : 'var(--nz-green-l)';
  }
};

const handleBorder = (checked: boolean, disabled: boolean, variant: string, isEditing: boolean) => {
  if (isEditing) {
    return '1px solid #B3E2B8';
  }
  if (disabled) {
    return variant === 'primary' ? '1px solid var(--nz-dataGray)' : '1px solid var(--nz-dataGray)';
  }
  switch (checked) {
    case true:
      return variant === 'primary' ? '1px solid #13291F' : '1px solid var(--nz-green-minor)';
    case false:
      return variant === 'primary' ? '1px solid var(--nz-blue-l)' : '1px solid var(--nz-green-l)';
  }
};

const handleFontColor = (checked: boolean, disabled: boolean, variant: string, isEditing: boolean) => {
  if (isEditing) {
    return 'var(--nz-black-80)';
  }
  if (disabled) {
    return variant === 'primary' ? 'var(--nz-black-47)' : 'var(--nz-black-47)';
  }
  switch (checked) {
    case true:
      return variant === 'primary' ? 'var(--nz-white-95)' : 'var(--nz-white-95)';
    case false:
      return variant === 'primary' ? 'var(--nz-black-80)' : 'var(--nz-black-80)';
    default:
      return '#fff';
  }
};

const ChipContainer = styled.div<{
  checked: boolean;
  disabled: boolean;
  variant: string;
  isEditing: boolean;
  fullWidth: boolean;
}>`
  background-color: ${({ checked, disabled, variant, isEditing }) =>
    handleBgColor(checked, disabled, variant, isEditing)};
  border: ${({ checked, disabled, variant, isEditing }) => handleBorder(checked, disabled, variant, isEditing)};
  color: ${({ checked, disabled, variant, isEditing }) => handleFontColor(checked, disabled, variant, isEditing)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 16px;
  align-items: center;
  cursor: pointer;
  height: 32px;
  min-width: 112px;
  fill: ${({ checked, disabled, variant, isEditing }) => handleFontColor(checked, disabled, variant, isEditing)};
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props.isEditing &&
    css`
      cursor: auto;
    `}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  font-size: 14px;
`;

const ChipLabel = styled.div`
  margin: 0 16px;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ChipIcon = styled.div<{
  position: string;
}>`
  margin-left: ${(props) => (props.position === 'left' ? '12px' : 'auto')};
  margin-right: ${(props) => (props.position === 'right' ? '12px' : 'auto')};
  display: inline-flex;
`;

const ChipIconSpan = styled.div<{
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}>`
  width: 16px;
  margin: 4px;
  display: inline-flex;
`;

const ChipEdit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Input = styled.input`
  padding: 4px;
  margin: 4px;
  background: var(--nz-bg-3);
  border: none;
  outline: none;
`;

export function NewChip(props: NewChipProps) {
  return (
    <ChipContainer
      checked={props.checked}
      disabled={props.disabled}
      variant={props.variant}
      isEditing={props.isEditing}
      onClick={!props.disabled ? props.onClick : () => undefined}
      role="button"
      fullWidth={props.fullWidth}
    >
      {!props.isEditing && props.actions.filter((action) => action.position === 'left').length > 0 && (
        <ChipIcon position="left">
          {props.actions
            .filter((action) => action.position === 'left')
            .map((action, key) => (
              <ChipIconSpan
                onClick={!props.disabled ? action.onClick : () => undefined}
                key={`left-icon-${key}`}
                aria-label={action.label}
                title={action.label}
              >
                <NzIcon name={action.iconName} size={16} />
              </ChipIconSpan>
            ))}
        </ChipIcon>
      )}
      {!props.isEditing && <ChipLabel title={props.label}>{props.label}</ChipLabel>}
      {!props.isEditing && props.actions.filter((action) => action.position === 'right').length > 0 && (
        <ChipIcon position="right">
          {props.actions
            .filter((action) => action.position === 'right')
            .map((action, key) => (
              <ChipIconSpan
                onClick={!props.disabled ? action.onClick : () => undefined}
                key={`right-icon-${key}`}
                aria-label={action.label}
                title={action.label}
              >
                <NzIcon name={action.iconName} size={16} />
              </ChipIconSpan>
            ))}
        </ChipIcon>
      )}
      {props.isEditing && (
        <ChipEdit>
          <Input
            type="text"
            defaultValue={props.label}
            onChange={(e) => props.onChange?.(e.target.value)}
            onKeyDown={props.onKeyDown}
          />
          <ChipIcon position="right">
            <ChipIconSpan>
              <NzIcon name={'edit-icon'} size={16} />
            </ChipIconSpan>
          </ChipIcon>
        </ChipEdit>
      )}
    </ChipContainer>
  );
}

NewChip.defaultProps = {
  actions: [],
  disabled: false,
  checked: false,
  isEditing: false,
  variant: 'primary',
  onChange: () => undefined,
  onClick: () => undefined,
  fullWidth: false,
};

export default NewChip;
