import { Region, TARGET_PH } from '@novozymes-digital/laundry-lab/static/Constants';
import {
  BackendCollection,
  BackendFormulation,
  Collection,
  CreateBackendCollection,
  CreateBackendFormulation,
  Formulation,
  RegionType,
  StainRemovalResultBackendData,
  StainRemovalResult,
  UpdateBackendCollection,
  PredictionRequestMapperData,
  SustainabilityRequestMapperData,
  EcoLabelRequestMapperData,
  StainGroupCustom,
  CustomStainGroupBackend,
} from './types';

export const collectionFrontendDataMapper = (backendCollection: BackendCollection): Collection => ({
  id: backendCollection.id,
  pinned: backendCollection.is_starred,
  name: backendCollection.collection_name,
  username: backendCollection.username,
  nrOfFormulations: backendCollection.formulation_ids.length,
  lastUpdated: backendCollection.modification_date,
  created: backendCollection.creation_date,
  region: backendCollection.region,
  currency: backendCollection.currency,
  temperature: backendCollection.temperature,
  dose: backendCollection.dose,
  detergent_volume: backendCollection.detergent_volume,
  opportunity_id: backendCollection.opportunity_id,
  prices: {
    aeo: backendCollection.aeo,
    aes: backendCollection.aes,
    amplifyprime100l: backendCollection.amplifyprime100l,
    citrate: backendCollection.citrate,
    las: backendCollection.las,
    lipexevity200l: backendCollection.lipexevity200l,
    mannaway200l: backendCollection.mannaway200l,
    progressuno100l: backendCollection.progressuno100l,
    liquanase35l: backendCollection.liquanase35l,
    soap: backendCollection.soap,
    lipex100t: backendCollection.lipex100t,
    mannaway4t: backendCollection.mannaway4t,
    savinase12t: backendCollection.savinase12t,
    stainzymeplus12t: backendCollection.stainzymeplus12t,
    celluclean4500t: backendCollection.celluclean4500t,
    sodiumcarbonate: backendCollection.sodiumcarbonate,
    sodiumsulfate: backendCollection.sodiumsulfate,
    xpect1000l: backendCollection.xpect1000l,
    silicate: backendCollection.silicate,
    savinase16t: backendCollection.savinase16t,
    xpect1000t: backendCollection.xpect1000t,
    sodiumchloride: backendCollection.sodiumchloride,
    dolomite: backendCollection.dolomite,
    medleyCore200l: backendCollection.medleyCore200l,
    medleyBoost300l: backendCollection.medleyBoost300l,
    medleyFlex300l: backendCollection.medleyFlex300l,
    medleyBright200l: backendCollection.medleyBright200l,
    medleyAdvance300l: backendCollection.medleyAdvance300l,
    medleyEssential200l: backendCollection.medleyEssential200l,
    medleySelect300l: backendCollection.medleySelect300l,
    medleyPure300l: backendCollection.medleyPure300l,
    medleyBrilliant300l: backendCollection.medleyBrilliant300l,
    medleyOptiwash1750s: backendCollection.medleyOptiwash1750s,
    medleyOptiwash3500s: backendCollection.medleyOptiwash3500s,
    medleyOptiwash400splus: backendCollection.medleyOptiwash400splus,
    medleyAdvance200t: backendCollection.medleyAdvance200t,
    medleyBrilliant400l: backendCollection.medleyBrilliant400l,
  },
});

export const collectionCreateDataMapper = (frontendCollection: Collection): CreateBackendCollection => ({
  collection_name: frontendCollection.name,
  region: frontendCollection.region,
  currency: frontendCollection.currency,
  temperature: frontendCollection.temperature,
  is_starred: frontendCollection.pinned,
  dose: frontendCollection.dose,
  detergent_volume: frontendCollection.detergent_volume,
  opportunity_id: frontendCollection.opportunity_id,
  las: frontendCollection.prices.las ?? 0,
  aes: frontendCollection.prices.aes ?? 0,
  aeo: frontendCollection.prices.aeo ?? 0,
  soap: frontendCollection.prices.soap ?? 0,
  citrate: frontendCollection.prices.citrate ?? 0,
  amplifyprime100l: frontendCollection.prices.amplifyprime100l ?? 0,
  progressuno100l: frontendCollection.prices.progressuno100l ?? 0,
  liquanase35l: frontendCollection.prices.liquanase35l ?? 0,
  lipexevity200l: frontendCollection.prices.lipexevity200l ?? 0,
  mannaway200l: frontendCollection.prices.mannaway200l ?? 0,
  lipex100t: frontendCollection.prices.lipex100t ?? 0,
  mannaway4t: frontendCollection.prices.mannaway4t ?? 0,
  savinase12t: frontendCollection.prices.savinase12t ?? 0,
  stainzymeplus12t: frontendCollection.prices.stainzymeplus12t ?? 0,
  celluclean4500t: frontendCollection.prices.celluclean4500t ?? 0,
  sodiumcarbonate: frontendCollection.prices.sodiumcarbonate ?? 0,
  sodiumsulfate: frontendCollection.prices.sodiumsulfate ?? 0,
  xpect1000l: frontendCollection.prices.xpect1000l ?? 0,
  silicate: frontendCollection.prices.silicate ?? 0,
  savinase16t: frontendCollection.prices.savinase16t ?? 0,
  xpect1000t: frontendCollection.prices.xpect1000t ?? 0,
  sodiumchloride: frontendCollection.prices.sodiumchloride ?? 0,
  dolomite: frontendCollection.prices.dolomite ?? 0,
  medleyCore200l: frontendCollection.prices.medleyCore200l ?? 0,
  medleyBoost300l: frontendCollection.prices.medleyBoost300l ?? 0,
  medleyFlex300l: frontendCollection.prices.medleyFlex300l ?? 0,
  medleyBright200l: frontendCollection.prices.medleyBright200l ?? 0,
  medleyAdvance300l: frontendCollection.prices.medleyAdvance300l ?? 0,
  medleyEssential200l: frontendCollection.prices.medleyEssential200l ?? 0,
  medleySelect300l: frontendCollection.prices.medleySelect300l ?? 0,
  medleyPure300l: frontendCollection.prices.medleyPure300l ?? 0,
  medleyBrilliant300l: frontendCollection.prices.medleyBrilliant300l ?? 0,
  medleyOptiwash1750s: frontendCollection.prices.medleyOptiwash1750s ?? 0,
  medleyOptiwash3500s: frontendCollection.prices.medleyOptiwash3500s ?? 0,
  medleyOptiwash400splus: frontendCollection.prices.medleyOptiwash400splus ?? 0,
  medleyAdvance200t: frontendCollection.prices.medleyAdvance200t ?? 0,
  medleyBrilliant400l: frontendCollection.prices.medleyBrilliant400l ?? 0,
});

export const collectionUpdateDataMapper = (frontendCollection: Collection): UpdateBackendCollection => ({
  id: frontendCollection.id!,
  collection_name: frontendCollection.name,
  region: frontendCollection.region,
  currency: frontendCollection.currency,
  temperature: frontendCollection.temperature,
  is_starred: frontendCollection.pinned,
  dose: frontendCollection.dose,
  detergent_volume: frontendCollection.detergent_volume,
  opportunity_id: frontendCollection.opportunity_id,
  las: frontendCollection.prices.las,
  aes: frontendCollection.prices.aes,
  aeo: frontendCollection.prices.aeo,
  soap: frontendCollection.prices.soap,
  citrate: frontendCollection.prices.citrate,
  amplifyprime100l: frontendCollection.prices.amplifyprime100l,
  progressuno100l: frontendCollection.prices.progressuno100l,
  liquanase35l: frontendCollection.prices.liquanase35l,
  lipexevity200l: frontendCollection.prices.lipexevity200l,
  mannaway200l: frontendCollection.prices.mannaway200l,
  lipex100t: frontendCollection.prices.lipex100t,
  mannaway4t: frontendCollection.prices.mannaway4t,
  savinase12t: frontendCollection.prices.savinase12t,
  stainzymeplus12t: frontendCollection.prices.stainzymeplus12t,
  celluclean4500t: frontendCollection.prices.celluclean4500t,
  sodiumcarbonate: frontendCollection.prices.sodiumcarbonate,
  sodiumsulfate: frontendCollection.prices.sodiumsulfate,
  xpect1000l: frontendCollection.prices.xpect1000l,
  silicate: frontendCollection.prices.silicate,
  savinase16t: frontendCollection.prices.savinase16t,
  xpect1000t: frontendCollection.prices.xpect1000t,
  sodiumchloride: frontendCollection.prices.sodiumchloride,
  dolomite: frontendCollection.prices.dolomite,
  medleyCore200l: frontendCollection.prices.medleyCore200l,
  medleyBoost300l: frontendCollection.prices.medleyBoost300l,
  medleyFlex300l: frontendCollection.prices.medleyFlex300l,
  medleyBright200l: frontendCollection.prices.medleyBright200l,
  medleyAdvance300l: frontendCollection.prices.medleyAdvance300l,
  medleyEssential200l: frontendCollection.prices.medleyEssential200l,
  medleySelect300l: frontendCollection.prices.medleySelect300l,
  medleyPure300l: frontendCollection.prices.medleyPure300l,
  medleyBrilliant300l: frontendCollection.prices.medleyBrilliant300l,
  medleyOptiwash1750s: frontendCollection.prices.medleyOptiwash1750s,
  medleyOptiwash3500s: frontendCollection.prices.medleyOptiwash3500s,
  medleyOptiwash400splus: frontendCollection.prices.medleyOptiwash400splus,
  medleyAdvance200t: frontendCollection.prices.medleyAdvance200t,
  medleyBrilliant400l: frontendCollection.prices.medleyBrilliant400l,
});

export const formulationDataMapper = (backendFormulation: BackendFormulation): Formulation => {
  const { id, ...overlappingData } = backendFormulation;
  return {
    ...overlappingData,
    id: id.toString(),
    type: backendFormulation.model ? 'modelFormulation' : 'userFormulation',
  };
};

export const formulationCreateDataMapper = (
  frontendFormulation: Formulation,
  currentCollection: Collection
): CreateBackendFormulation => ({
  region: currentCollection.region,
  collectionId: currentCollection.id!,
  temperature: frontendFormulation.temperature,
  water_hardness: frontendFormulation.water_hardness ?? 0,
  dose: frontendFormulation.dose,
  detergent_volume: frontendFormulation.detergent_volume,
  opportunity_id: frontendFormulation.opportunity_id,
  name: frontendFormulation.name,
  progressuno100l: frontendFormulation.progressuno100l,
  liquanase35l: frontendFormulation.liquanase35l,
  amplifyprime100l: frontendFormulation.amplifyprime100l,
  lipexevity200l: frontendFormulation.lipexevity200l,
  mannaway200l: frontendFormulation.mannaway200l,
  las: frontendFormulation.las,
  aes: frontendFormulation.aes,
  aeo: frontendFormulation.aeo,
  soap: frontendFormulation.soap,
  citrate: frontendFormulation.citrate,
  lipex100t: frontendFormulation.lipex100t,
  mannaway4t: frontendFormulation.mannaway4t,
  savinase12t: frontendFormulation.savinase12t,
  stainzymeplus12t: frontendFormulation.stainzymeplus12t,
  celluclean4500t: frontendFormulation.celluclean4500t,
  sodiumcarbonate: frontendFormulation.sodiumcarbonate,
  sodiumsulfate: frontendFormulation.sodiumsulfate,
  xpect1000l: frontendFormulation.xpect1000l,
  silicate: frontendFormulation.silicate,
  savinase16t: frontendFormulation.savinase16t,
  xpect1000t: frontendFormulation.xpect1000t,
  sodiumchloride: frontendFormulation.sodiumchloride,
  dolomite: frontendFormulation.dolomite,
  medleyCore200l: frontendFormulation.medleyCore200l,
  medleyBoost300l: frontendFormulation.medleyBoost300l,
  medleyFlex300l: frontendFormulation.medleyFlex300l,
  medleyBright200l: frontendFormulation.medleyBright200l,
  medleyAdvance300l: frontendFormulation.medleyAdvance300l,
  medleyEssential200l: frontendFormulation.medleyEssential200l,
  medleySelect300l: frontendFormulation.medleySelect300l,
  medleyPure300l: frontendFormulation.medleyPure300l,
  medleyBrilliant300l: frontendFormulation.medleyBrilliant300l,
  medleyOptiwash1750s: frontendFormulation.medleyOptiwash1750s,
  medleyOptiwash3500s: frontendFormulation.medleyOptiwash3500s,
  medleyOptiwash400splus: frontendFormulation.medleyOptiwash400splus,
  medleyAdvance200t: frontendFormulation.medleyAdvance200t,
  medleyBrilliant400l: frontendFormulation.medleyBrilliant400l,
});

// Convertion from % to g/L
const getValueConverter = (dose: number, region: RegionType, regions: Region) => (value: number) => {
  const waterVolume: number = regions[region].water_volume;
  if (['eu', 'cn', 'na'].includes(region)) {
    // g/Wash
    return ((value / 100) * dose) / waterVolume;
  } else if (['la', 'afr', 'ind', 'me', 'sea'].includes(region)) {
    // g/L
    return (value / 100) * dose;
  } else {
    return value;
  }
};

export const modelPredictionRequestMapper = (
  formulation: Formulation,
  region: RegionType,
  revision: number,
  regions: Region,
  ingredientsMaxValue: Record<string, Record<string, number>>,
  collection_id?: number,
  hash?: number
): PredictionRequestMapperData => {
  const convertValue = getValueConverter(formulation.dose, region, regions);

  const REGION_CITRATE = {
    eu: convertValue(formulation.citrate),
    cn: convertValue(formulation.citrate),
    na: formulation.citrate == 0 ? 0 : ingredientsMaxValue['na']['citrate'],
    la: 0,
    // EM to change!!!!!!!!
    afr: 0,
    ind: 0,
    me: 0,
    sea: 0,
  };

  return {
    region: region,
    temperature: formulation.temperature,
    water_hardness: formulation.water_hardness ?? 0,
    target_ph: TARGET_PH, // Needed if it never changes?
    las: convertValue(formulation.las),
    aes: convertValue(formulation.aes),
    aeo: convertValue(formulation.aeo),
    progressuno100l: convertValue(formulation.progressuno100l),
    liquanase35l: convertValue(formulation.liquanase35l),
    amplifyprime100l: convertValue(formulation.amplifyprime100l),
    lipexevity200l: convertValue(formulation.lipexevity200l),
    mannaway200l: convertValue(formulation.mannaway200l),
    lipex100t: convertValue(formulation.lipex100t),
    mannaway4t: convertValue(formulation.mannaway4t),
    savinase12t: convertValue(formulation.savinase12t),
    stainzymeplus12t: convertValue(formulation.stainzymeplus12t),
    celluclean4500t: convertValue(formulation.celluclean4500t),
    sodiumcarbonate: convertValue(formulation.sodiumcarbonate),
    sodiumsulfate: convertValue(formulation.sodiumsulfate),
    xpect1000l: convertValue(formulation.xpect1000l),
    silicate: convertValue(formulation.silicate),
    savinase16t: convertValue(formulation.savinase16t),
    xpect1000t: convertValue(formulation.xpect1000t),
    sodiumchloride: convertValue(formulation.sodiumchloride),
    dolomite: convertValue(formulation.dolomite),
    medleyCore200l: convertValue(formulation.medleyCore200l),
    medleyBoost300l: convertValue(formulation.medleyBoost300l),
    medleyFlex300l: convertValue(formulation.medleyFlex300l),
    medleyBright200l: convertValue(formulation.medleyBright200l),
    medleyAdvance300l: convertValue(formulation.medleyAdvance300l),
    medleyEssential200l: convertValue(formulation.medleyEssential200l),
    medleySelect300l: convertValue(formulation.medleySelect300l),
    medleyPure300l: convertValue(formulation.medleyPure300l),
    medleyBrilliant300l: convertValue(formulation.medleyBrilliant300l),
    medleyOptiwash1750s: convertValue(formulation.medleyOptiwash1750s),
    medleyOptiwash3500s: convertValue(formulation.medleyOptiwash3500s),
    medleyOptiwash400splus: convertValue(formulation.medleyOptiwash400splus),
    medleyAdvance200t: convertValue(formulation.medleyAdvance200t),
    medleyBrilliant400l: convertValue(formulation.medleyBrilliant400l),
    // Citrate g/L amount has different rules per region
    citrate: REGION_CITRATE[region],

    // SOAP g/L amount is constant per region
    soap: ingredientsMaxValue[region]['soap'] ?? 0,

    revision: revision,
    collection_id: collection_id,
    hash,
  };
};

export const modelPredictionResultMapper = (backendResponse: StainRemovalResultBackendData): StainRemovalResult => {
  return Object.keys(backendResponse).reduce((groupAcc, stainGroupKey) => {
    return {
      ...groupAcc,
      [stainGroupKey]: Object.keys(backendResponse[stainGroupKey]).reduce((stainsAcc, stainKey) => {
        return {
          ...stainsAcc,
          [stainKey]: {
            stainCode: backendResponse[stainGroupKey][stainKey].stain_code,
            name: backendResponse[stainGroupKey][stainKey].display_name_eng,
            label: backendResponse[stainGroupKey][stainKey].display_code_eng,
            groups: backendResponse[stainGroupKey][stainKey].stain_group,
            predicted: backendResponse[stainGroupKey][stainKey].Predicted,
            standardDeviation: backendResponse[stainGroupKey][stainKey]['Standard Deviation'],
            textile: backendResponse[stainGroupKey][stainKey].stain_textile,
          },
        };
      }, {}),
    };
  }, {});
};

export const modelSustainabilityRequestMapper = (
  formulation: Formulation,
  region: RegionType,
  regions: Region,
  detergent_volume: any
): SustainabilityRequestMapperData => {
  return {
    aeo: formulation.aeo,
    aes: formulation.aes,
    amplifyprime100l: formulation.amplifyprime100l,
    citrate: formulation.citrate,
    /* 
    For LA and EMs regions the dosage is g/L, we need it to be in g/Wash for sustainability.
    Therefore the g/L dosage is multiplied by the water volume to return g/Wash
    */
    dose: ['la', 'afr', 'ind', 'me', 'sea'].includes(region)
      ? formulation.dose * regions[region].water_volume
      : formulation.dose,
    detergent_volume: detergent_volume,
    las: formulation.las,
    lipexevity200l: formulation.lipexevity200l,
    mannaway200l: formulation.mannaway200l,
    progressuno100l: formulation.progressuno100l,
    liquanase35l: formulation.liquanase35l,
    region: region,
    soap: formulation.soap,
    lipex100t: formulation.lipex100t,
    mannaway4t: formulation.mannaway4t,
    savinase12t: formulation.savinase12t,
    stainzymeplus12t: formulation.stainzymeplus12t,
    celluclean4500t: formulation.celluclean4500t,
    sodiumcarbonate: formulation.sodiumcarbonate,
    sodiumsulfate: formulation.sodiumsulfate,
    xpect1000l: formulation.xpect1000l,
    silicate: formulation.silicate,
    savinase16t: formulation.savinase16t,
    xpect1000t: formulation.xpect1000t,
    sodiumchloride: formulation.sodiumchloride,
    dolomite: formulation.dolomite,
    medleyCore200l: formulation.medleyCore200l,
    medleyBoost300l: formulation.medleyBoost300l,
    medleyFlex300l: formulation.medleyFlex300l,
    medleyBright200l: formulation.medleyBright200l,
    medleyAdvance300l: formulation.medleyAdvance300l,
    medleyEssential200l: formulation.medleyEssential200l,
    medleySelect300l: formulation.medleySelect300l,
    medleyPure300l: formulation.medleyPure300l,
    medleyBrilliant300l: formulation.medleyBrilliant300l,
    medleyOptiwash1750s: formulation.medleyOptiwash1750s,
    medleyOptiwash3500s: formulation.medleyOptiwash3500s,
    medleyOptiwash400splus: formulation.medleyOptiwash400splus,
    medleyAdvance200t: formulation.medleyAdvance200t,
    medleyBrilliant400l: formulation.medleyBrilliant400l,
  };
};

export const modelEcoLabelRequestMapper = (formulation: Formulation, region: RegionType): EcoLabelRequestMapperData => {
  return {
    dose: formulation.dose,
    las: formulation.las,
    aes: formulation.aes,
    aeo: formulation.aeo,
    soap: formulation.soap,
    citrate: formulation.citrate,
    lipexevity200l: formulation.lipexevity200l,
    amplifyprime100l: formulation.amplifyprime100l,
    progressuno100l: formulation.progressuno100l,
    liquanase35l: formulation.liquanase35l,
    mannaway200l: formulation.mannaway200l,
    lipex100t: formulation.lipex100t,
    mannaway4t: formulation.mannaway4t,
    savinase12t: formulation.savinase12t,
    stainzymeplus12t: formulation.stainzymeplus12t,
    celluclean4500t: formulation.celluclean4500t,
    sodiumcarbonate: formulation.sodiumcarbonate,
    sodiumsulfate: formulation.sodiumsulfate,
    xpect1000l: formulation.xpect1000l,
    silicate: formulation.silicate,
    savinase16t: formulation.savinase16t,
    xpect1000t: formulation.xpect1000t,
    sodiumchloride: formulation.sodiumchloride,
    dolomite: formulation.dolomite,
    medleyCore200l: formulation.medleyCore200l,
    medleyBoost300l: formulation.medleyBoost300l,
    medleyFlex300l: formulation.medleyFlex300l,
    medleyBright200l: formulation.medleyBright200l,
    medleyAdvance300l: formulation.medleyAdvance300l,
    medleyEssential200l: formulation.medleyEssential200l,
    medleySelect300l: formulation.medleySelect300l,
    medleyPure300l: formulation.medleyPure300l,
    medleyBrilliant300l: formulation.medleyBrilliant300l,
    medleyOptiwash1750s: formulation.medleyOptiwash1750s,
    medleyOptiwash3500s: formulation.medleyOptiwash3500s,
    medleyOptiwash400splus: formulation.medleyOptiwash400splus,
    medleyAdvance200t: formulation.medleyAdvance200t,
    medleyBrilliant400l: formulation.medleyBrilliant400l,
    region,
  };
};

export const customStainGroupDataMapper = (
  customStainGroup: StainGroupCustom,
  currentCollection: Collection | undefined
): CustomStainGroupBackend => ({
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  collection_id: currentCollection?.id!,
  stain_group: customStainGroup.stain_group,
  stain_group_label: customStainGroup.stain_group_label,
  sort_order: customStainGroup.sort_order,
  stains: customStainGroup.stains,
});
