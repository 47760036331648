import { Enzyme, RegionType } from '../store/types';

export const TARGET_PH = 7.0;

export type IngredientsLabel = {
  [ingredientId: string]: any;
};

export type IngredientsGroupLayout = {
  group_label: string;
  ingredients: IngredientsLabel[];
};

export type IngredientsInputLayoutsType = {
  [regionId in RegionType]: IngredientsGroupLayout[];
};

export type BlendsLabel = {
  [blendId: string]: any;
};

export type BlendsGroupLayout = {
  blend: string;
  enzymes: BlendsLabel[];
  limits: { [key: number]: number };
  disclaimer: string;
};

export type BlendsInputLayoutsType = {
  [regionId in RegionType]: BlendsGroupLayout[];
};

export type Region = {
  [region: string]: RegionLayout;
};

export type RegionLayout = {
  dose_default: number;
  active: boolean;
  id: string;
  sustainability_washes_pr_year: number;
  uom_temperature: string[];
  uom_currency: string[];
  water_volume: number;
  dose_step: number;
  dose_min: number;
  detergent_format: string[];
  name: string;
  sustainability_n_households: number;
  uom_temperature_options: number[];
  uom_stain_removal: string[];
  uom_stain_removal_description: string;
  dose_disabled: boolean;
  dose_max: number;
  prices: {
    las: number;
    aes: number;
    aeo: number;
    soap: number;
    citrate: number;
    amplifyprime100l: number;
    progressuno100l: number;
    liquanase35l: number;
    lipexevity200l: number;
    mannaway200l: number;
    lipex100t: number;
    mannaway4t: number;
    savinase12t: number;
    stainzymeplus12t: number;
    celluclean4500t: number;
    sodiumcarbonate: number;
    sodiumsulfate: number;
    xpect1000l: number;
    silicate: number;
    savinase16t: number;
    xpect1000t: number;
    sodiumchloride: number;
    dolomite: number;
    medleyCore200l: number;
    medleyBoost300l: number;
    medleyFlex300l: number;
    medleyBright200l: number;
    medleyAdvance300l: number;
    medleyEssential200l: number;
    medleySelect300l: number;
    medleyPure300l: number;
    medleyBrilliant300l: number;
    medleyOptiwash1750s: number;
    medleyOptiwash3500s: number;
    medleyOptiwash400splus: number;
    medleyAdvance200t: number;
    medleyBrilliant400l: number;
  };
  dose_interval: number;
  dose_scale: string;
  excel_config?: ExcelConfig;
  scaling: number;
};

export type ExcelConfig = {
  cost_breakdown: { [key: string]: boolean };
  formulation_details: { [key: string]: boolean };
  stain_removal_long_format: { [key: string]: boolean };
  stain_removal_overview: { [key: string]: boolean };
};

export type EnzymeSettings = {
  [regionId in RegionType]: Enzyme[];
};

export const LAS_INGREDIENT_ID = 'las';
export const AES_INGREDIENT_ID = 'aes';
export const AEO_INGREDIENT_ID = 'aeo';

export const barWidth = 50,
  baselineBarWidth = 50,
  commonBarThickness = 24;

export const BASELINE_LABEL = 'Baseline';

export const baselineChartSettings = {
  label: BASELINE_LABEL,
  backgroundColor: 'transparent',
  borderWidth: {
    top: 0,
    right: 1,
    bottom: 0,
    left: 0,
  },
  maxBarThickness: baselineBarWidth,
  borderColor: 'black',
  barPercentage: 1.0,
  categoryPercentage: 0.8,
};

export const commonBarChartSettings = {
  borderWidth: 1,
  barPercentage: 0.8,
  categoryPercentage: 0.7,
  barThickness: commonBarThickness,
};

export const tooltipSettings = {
  intersect: false,
  backgroundColor: 'var(--nz-bg-2)',
  titleColor: 'var(--nz-black-80)',
  bodyColor: 'var(--nz-black-80)',
  cornerRadius: 16,
  padding: 16,
  bodySpacing: 8,
  shared: true,
};
