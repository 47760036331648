import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { default as NumberFormat } from 'react-number-format';
import { Switch, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  getBlendDisclaimer,
  getBlendEnzymes,
  getCurrentCollectionCurrency,
  getCurrentCollectionPrices,
  getIngredientList,
  getRegions,
  getUserLocale,
  getUserUnits,
  getUserAllGrants,
  getBlendLimits,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { Ingredient, RegionType } from '@novozymes-digital/laundry-lab/store/types';
import { isEqual } from 'lodash';
import { trunc } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as InfoTooltip } from '@novozymes-digital/laundry-lab/images/info_tooltip.svg';
import Slider from '@mui/material/Slider';

interface Props {
  ingredient: Ingredient;
  value: number;
  onValueChange: (key: Ingredient, value: number) => void;
  dose: number;
  extraProperties: Record<string, unknown>;
  setExtraProperties: (properties: Record<string, unknown>) => void;
  limits: any;
  region: RegionType;
  decimals: number;
  currentValues?: any;
  // newValues?: any;
  onChange?: (input: unknown) => void;
}

const StyledMainContainer = styled(Grid)(() => ({
  paddingLeft: '20px',
  paddingRight: '20px',
}));

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

const StyledLabel = styled('label')(() => ({
  marginTop: '10px',
  paddingRight: '10px ',
  fontSize: '14px',
  fontWeight: 'bold',
}));

const StyledInfoTooltip = styled(InfoTooltip)(() => ({
  marginLeft: '-16px',
}));

const StyledSmallComment = styled('span')(() => ({
  fontSize: '12px',
  color: '#b5b5b5',
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '14px',
}));

const StyledGrid = styled(Grid)(() => ({
  '&.MuiGrid-container': {
    alignItems: 'baseline !important',
    width: '100% !important',
    display: 'flex !important',
    flexWrap: 'wrap !important',
  },
}));

const BlendStyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '23px',
    backgroundColor: 'transparent',
    padding: '10px',
    height: '32px',
    fontSize: '14px',
    width: '84px',
  },
  '& .MuiOutlinedInput-input    ': {
    padding: '0',
    textAlign: 'center',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid #B2ACC7',
  },
  '& input': {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'right',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#432F7A',
  },
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '23px',
    backgroundColor: 'transparent',
    height: '32px',
    fontSize: '14px',
    width: '84px',
  },
  '& .MuiOutlinedInput-input    ': {
    padding: '0',
    textAlign: 'center',
  },
  '& .MuiInput-underline:before': {
    left: 0,
    right: 0,
    bottom: 0,
    content: '"\xa0"',
    position: 'absolute',
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    pointerEvents: 'none',
  },
  '& input': {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'right',
  },
  '.MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: '2px solid rgba(0, 0, 0, 0.8)',
  },
}));

const BlendSlider = styled(Slider)(() => ({
  color: '#432F7A',
  '& .MuiSlider-thumb:hover ': {
    boxShadow: '0px 0px 0px 8px #432f7a29',
  },
  '& .MuiSlider-valueLabel span span': {
    color: 'white',
  },
  '& .MuiSlider-active': {
    boxShadow: '0px 0px 0px 14px #432f7a29',
  },
}));

const StyledSlider = styled(Slider)(() => ({
  color: '#C5DA00',
  '& .MuiSlider-thumb:hover': {
    boxShadow: '0px 0px 0px 8px #C5DA0028',
  },

  '& .MuiSlider-valueLabel span span': {
    color: 'rgba(0, 0, 0, 0.87);',
  },

  '& .MuiSlider-active': {
    boxShadow: '0px 0px 0px 8px #C5DA0028',
  },
}));

const StyledToggleSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-colorSecondary.Mui-checked': {
    color: '#173629',
  },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.8)',
    boxShadow: theme.shadows[1],
    marginLeft: '-16px',
  },
}));

const getIsDisabled = ({
  ingredient,
  region,
  extraProperties,
}: {
  ingredient: string;
  region: string;
  extraProperties: any;
}) => {
  return (ingredient === 'citrate' && region !== 'eu') || ingredient === 'soap' || extraProperties[ingredient];
};
const getStepLength = ({ ingredient }: { ingredient: string }) => {
  const pointOneIngredients = ['las', 'aes', 'aeo', 'citrate'];

  if (pointOneIngredients.indexOf(ingredient) !== -1) {
    return 0.1;
  }

  return 0.01;
};

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  groupSeparator: string;
  decimalSeparator: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, groupSeparator, decimalSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={groupSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString
    />
  );
}

const IngredientsInput: React.FC<Props> = ({
  ingredient,
  value,
  onValueChange,
  dose,
  extraProperties,
  setExtraProperties,
  limits,
  region,
  decimals,
}: Props) => {
  const userLocale = useSelector(getUserLocale, isEqual);
  const ingredientCosts = useSelector(getCurrentCollectionPrices, isEqual);
  const currency = useSelector(getCurrentCollectionCurrency, isEqual);
  const userUnits = useSelector(getUserUnits, isEqual);
  const ingredientList = useSelector(getIngredientList, isEqual);
  const water_volume = useSelector(getRegions, isEqual)[region].water_volume;
  const blendsEnzymes = useSelector(getBlendEnzymes, isEqual)[region];
  const blendDisclaimer = useSelector(getBlendDisclaimer, isEqual)[region];
  const userAllGrants = useSelector(getUserAllGrants);

  const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));
  const blendLimits = useSelector(getBlendLimits);

  const getKeyValue = (key: string) => (obj: Record<string, (dosage: number, water_volume: number) => number>) =>
    obj[key];
  const maxValue: number = getKeyValue(ingredient)(limits[region])(dose, water_volume);
  const minValue = 0;

  const isDisabled = getIsDisabled({ ingredient, region, extraProperties });
  const ingredientLabel = ingredientList[ingredient];

  const [sliderValue, setSliderValue] = useState(value);

  // eslint-disable-next-line
  React.useEffect(() => handleValueUpdating(value), [dose, extraProperties]);

  const handleValueUpdating = (value: string | number) => {
    let newValue = Number(value);
    if (newValue > maxValue) {
      newValue = maxValue;
    }

    // don't allow negative values
    if (newValue < 0) {
      newValue = 0;
    }

    if (ingredient === 'citrate' && region === 'na') {
      // this is necessary for the switch toggle, the checked variable doesn't update
      // right away
      if (value === 'on' || (extraProperties.citrate && value !== 'off')) {
        newValue = maxValue;
      } else {
        newValue = 0;
      }
    }

    onValueChange(ingredient, newValue);
    setSliderValue(newValue);
  };

  const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (isDisabled) return;
    handleValueUpdating(event.target.value);
  };

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (isDisabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    setSliderValue(newValue);
  };

  const handleSliderCommitChange = (event: any, value: number | number[]) => {
    if (isDisabled) return;
    const newValue = Array.isArray(value) ? value[0] : value;
    handleValueUpdating(newValue);
  };

  const valuetext = (newValue: number | number[]) => {
    return `${newValue}°C`;
  };

  const stepCount = getStepLength({ ingredient });

  const { groupSeparator, decimalSeparator, locale } = userLocale;

  // Max limit without the scaleFactor in medleyBrilliant400l
  const maxWithoutScaleFactor = (dosage: number): number => {
    return grantGlobalCosmed
      ? trunc(Object.entries(blendLimits['eu']['medleyBrilliant400l']).find((x) => Number(x[0]) == dosage)?.[1] ?? 0, 2)
      : 0;
  };

  return (
    <StyledMainContainer container alignItems="flex-end" justifyContent="center">
      <Grid item xs={12}>
        <StyledFormControl>
          <Grid container item alignItems="center" justifyContent="space-between" sm={12}>
            <StyledGrid container item xs="auto">
              <StyledLabel htmlFor={ingredient}>
                {blendsEnzymes && Object.keys(blendsEnzymes).includes(ingredient) && !['la'].includes(region)
                  ? `Blend: ${ingredientLabel}`
                  : ingredientLabel}
                :{' '}
              </StyledLabel>
              {blendsEnzymes && Object.keys(blendsEnzymes).includes(ingredient) ? (
                <>
                  {blendDisclaimer[ingredient] && ingredient !== 'medleyBrilliant400l' && (
                    <LightTooltip
                      placement="top"
                      title={`The stain removal prediction does not cover ${blendDisclaimer[ingredient].toLowerCase()}`}
                    >
                      <StyledInfoTooltip fontSize="small" />
                    </LightTooltip>
                  )}
                  {ingredient === 'medleyBrilliant400l' && grantGlobalCosmed && (
                    <LightTooltip
                      placement="top"
                      title={[
                        `The stain removal prediction does not cover ${blendDisclaimer[ingredient].toLowerCase()}.`,
                        ` The displayed protease effect is based on both model prediction and subject matter expertise.`,
                        value > maxWithoutScaleFactor(dose)
                          ? ' The displayed amylase effect might not be reflecting full amylase activity due to model limitation.'
                          : '',
                      ].filter(Boolean)}
                    >
                      <StyledInfoTooltip fontSize="small" />
                    </LightTooltip>
                  )}
                  <BlendStyledInput
                    disabled={isDisabled}
                    id={ingredient}
                    name={ingredient}
                    value={trunc(sliderValue, decimals)}
                    onBlur={handleChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom as any,
                      endAdornment: (
                        <InputAdornment position="end">
                          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>%</span>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      groupSeparator,
                      decimalSeparator,
                    }}
                  />
                </>
              ) : (
                <StyledInput
                  disabled={isDisabled}
                  id={ingredient}
                  name={ingredient}
                  value={trunc(sliderValue, decimals)}
                  onBlur={handleChange}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    endAdornment: (
                      <InputAdornment position="end">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>%</span>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    groupSeparator,
                    decimalSeparator,
                  }}
                />
              )}
            </StyledGrid>
            <Typography align="right" style={{ marginLeft: 'auto', fontSize: '14px' }}>
              {`${!!ingredientCosts && Number(ingredientCosts[ingredient]).toLocaleString(locale)} `}
              <StyledSmallComment>{` ${currency}/${userUnits.weight}`}</StyledSmallComment>
            </Typography>
          </Grid>
          {ingredient == 'citrate' && region == 'na' ? (
            ''
          ) : (
            <Grid container item xs={12} alignItems="baseline" justifyContent="space-between">
              <StyledTypography>0%</StyledTypography>
              <StyledTypography>{maxValue}%</StyledTypography>
            </Grid>
          )}
        </StyledFormControl>
        {ingredient == 'citrate' && region == 'na' && (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <StyledToggleSwitch
              data-cy="citrate-toggle"
              onChange={() => {
                setExtraProperties({ ...extraProperties, citrate: !extraProperties.citrate });
                handleValueUpdating(!extraProperties.citrate === true ? 'on' : 'off');
              }}
              checked={Boolean(extraProperties['citrate'])}
            />
            <StyledSmallComment style={{ marginRight: 'auto' }}>*This amount can not be changed</StyledSmallComment>
          </div>
        )}
        {blendsEnzymes && Object.keys(blendsEnzymes).includes(ingredient) ? (
          <BlendSlider
            value={trunc(sliderValue, decimals)}
            valueLabelDisplay="auto"
            step={stepCount}
            min={isDisabled ? undefined : minValue}
            max={maxValue}
            getAriaValueText={valuetext}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderCommitChange}
            disabled={isDisabled}
          />
        ) : (
          <StyledSlider
            value={trunc(sliderValue, decimals)}
            valueLabelDisplay="auto"
            step={stepCount}
            min={isDisabled ? undefined : minValue}
            max={maxValue}
            getAriaValueText={valuetext}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderCommitChange}
            disabled={isDisabled}
          />
        )}
      </Grid>
    </StyledMainContainer>
  );
};

const compareProps = (prevProps: Props, nextProps: Props): boolean => {
  const prev = {
    value: prevProps.value,
    extraProperties: prevProps.extraProperties,
    dose: prevProps.dose,
  };
  const next = {
    value: nextProps.value,
    extraProperties: nextProps.extraProperties,
    dose: nextProps.dose,
  };
  const equal = isEqual(prev, next);
  return equal;
};

export default React.memo(IngredientsInput, compareProps);
