import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

const StyledRoot = styled(Grid)(() => ({
  paddingTop: '96px',
}));

const StyledGrid = styled(Grid)(() => ({
  height: '250px',
}));

const StyledHeader = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
}));

interface EmptyMessageProps {
  icon: React.ReactNode;
  header: string;
  msg: string;
}

const EmptyMessage: React.FunctionComponent<EmptyMessageProps> = ({ icon, header, msg }: EmptyMessageProps) => {
  return (
    <StyledRoot container direction="column" alignItems="center" spacing={4}>
      <StyledGrid>{icon}</StyledGrid>
      <Grid item>
        <StyledHeader align="center">{header}</StyledHeader>
        <Typography align="center">{msg}</Typography>
      </Grid>
    </StyledRoot>
  );
};

export default EmptyMessage;
