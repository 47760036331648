import React from 'react';
import { NzCard, NzCardContent, NzButton, NzTypography } from '@novozymes-digital/dezymer-core';
import ButtonLayout from '../UI/ButtonLayout';
import NewChip from '../UI/NewChip';
import { Button, styled } from '@mui/material';

interface Props {
  stainsLeft: Stain[];
  stainsRight: Stain[];
  groupName: string;
  updateGroupList: any;
  onConfirm: any;
  onDelete: any;
  onCancel: any;
  editMode: string;
}

interface Stain {
  label: string;
  key: string;
  order: number;
}

const innerCardContentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'left',
  height: '100%',
  padding: '8px 8px 8px 0',
  overflow: 'auto',
};

const innerCardContentStyleRight = {
  ...innerCardContentStyle,
  height: 'calc(100% - 40px)',
  marginBottom: '12px',
};

const outterCardContentStyle = {
  height: '100%',
  padding: '16px 0 32px 24px',
};

const cardFooterStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: '8px',
  paddingRight: '12px',
};

const CancelButton = styled(Button)({
  height: '32px',
  minWidth: '112px',
  backgroundColor: 'var(--nz-black-80)',
  color: 'white',
  '&: hover': {
    backgroundColor: 'var(--nz-black-47)',
  },
});

const ConfirmButton = styled(Button)({
  height: '32px',
  minWidth: '112px',
  backgroundColor: '#B3E2B8',
});

const EditStainsGroup: React.FunctionComponent<Props> = ({
  stainsLeft,
  stainsRight,
  groupName,
  updateGroupList,
  onConfirm,
  onDelete,
  onCancel,
  editMode,
}: Props) => {
  const addStain = (stain: any) => {
    //newData.find(x => x.investor === investor)
    const newStainsLeft = stainsLeft.filter((x) => {
      return x.label !== stain.label && x.key !== stain.key;
    });
    const newStainsRight = [
      ...stainsLeft.filter((x) => {
        return x.label === stain.label && x.key === stain.key;
      }),
      ...stainsRight,
    ];
    stain.order = stainsRight.length;
    updateGroupList(newStainsLeft, newStainsRight);
  };
  const removeStain = (stain: any) => {
    const newStainsRight = stainsRight.filter((x) => {
      return x.label !== stain.label && x.key !== stain.key;
    });
    const newStainsLeft = [
      ...stainsRight.filter((x) => {
        return x.label === stain.label && x.key === stain.key;
      }),
      ...stainsLeft,
    ];
    stain.order = stainsLeft.length;
    updateGroupList(newStainsLeft, newStainsRight);
  };

  return (
    <div style={{ fontSize: '14px', display: 'flex', marginTop: '12px' }}>
      <ButtonLayout spacing={8}>
        <NzCard width="calc(50% - 4px)" height="378px" appearance="flat" color="var(--nz-bg-2)">
          <NzCardContent style={outterCardContentStyle}>
            <NzTypography variant="hb7">Standard Stains</NzTypography>
            <div style={innerCardContentStyle} data-cy="custom-stains-list-left">
              <ButtonLayout spacing={4}>
                {stainsLeft
                  .sort((a, b) => a.order - b.order)
                  .map((stain) => (
                    <NewChip
                      label={stain.label}
                      key={stain.label + stain.key}
                      onClick={() => {
                        addStain(stain);
                      }}
                    />
                  ))}
              </ButtonLayout>
            </div>
          </NzCardContent>
        </NzCard>
        <NzCard
          width="calc(50% - 4px)"
          height="378px"
          appearance="flat"
          color="var(--nz-bg-2)"
          style={{ overflow: 'auto' }}
        >
          <NzCardContent style={outterCardContentStyle}>
            <NzTypography variant="hb7" inline>
              Stains in
            </NzTypography>
            <NzTypography variant="p4" inline>{` "${groupName}"`}</NzTypography>
            <div style={innerCardContentStyleRight} data-cy="custom-stains-list-right">
              <ButtonLayout spacing={4}>
                {stainsRight
                  .sort((a, b) => a.order - b.order)
                  .map((stain) => (
                    <NewChip
                      label={stain.label}
                      key={stain.label + stain.key + stain.order}
                      onClick={() => {
                        removeStain(stain);
                      }}
                    />
                  ))}
              </ButtonLayout>
            </div>
            <div style={cardFooterStyle} data-cy="custom-stains-list-footer">
              <NzButton
                variant="primary"
                size="medium"
                theme="ghost"
                onClick={() => {
                  onDelete();
                }}
                style={{ marginRight: 'auto' }}
                disabled={editMode !== 'edit'}
              >
                Delete
              </NzButton>
              <CancelButton
                variant="contained"
                size="medium"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </CancelButton>
              <ConfirmButton
                variant="contained"
                size="medium"
                disabled={stainsRight.length == 0}
                onClick={() => {
                  onConfirm();
                }}
              >
                Confirm
              </ConfirmButton>
            </div>
          </NzCardContent>
        </NzCard>
      </ButtonLayout>
    </div>
  );
};

export default EditStainsGroup;
