import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  initApp,
  userSignInFailed,
  initLogin,
  userAuthorized,
  signIn,
} from '@novozymes-digital/laundry-lab/store/actions/actions';

import { Route, Routes } from 'react-router';

import { ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';

import { AuthPage } from '@novozymes-digital/laundry-lab/pages/Auth';
import { SSOAuthPage } from '@novozymes-digital/laundry-lab/pages/SSOAuth';
import { UnauthorizedMessage } from '@novozymes-digital/laundry-lab/pages/Auth/UnauthorizedMessage';
import theme from './theme';

import CollectionList from './pages/Collections/CollectionList';
import CollectionForm from './pages/Collections/CollectionForm';
import PageWithHeader from './pages/PageWithHeader';
import ComparePage from './pages/Compare';
import { initializeCookieConsent, initializeGTM } from '@novozymes-digital/utils';

import { Hub } from '@aws-amplify/core';
import FeedbackComponent from './pages/Feedback';
import { NzThemeVariables, NzTheme } from '@novozymes-digital/dezymer-core';
import { SnackBarProvider } from './utility/Snackbar';

declare const window: Window &
  typeof globalThis & {
    _DATADOG_SYNTHETICS_BROWSER: unknown;
  };

export const isLocalhost = (): boolean => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

function Loading() {
  return <p>Loading...</p>;
}

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onHubCapsule = (capsule: any) => {
      const { channel } = capsule;
      const { payload } = capsule;
      if (channel === 'auth') {
        switch (payload.event) {
          case 'signIn_failure':
            // eslint-disable-next-line no-case-declarations
            const message =
              `Signin failed! Ensure you're in the laundrylab-app AD group in Novonesis Access Portal (http://nzaccess).` +
              ` In AWS Cognito check if the account is enabled and authorized to enter the ${window.location.hostname} environment .`;
            dispatch(userSignInFailed(message));
            dispatch(initApp());
            break;
          case 'signIn':
            dispatch(initApp());
            dispatch(signIn());
            break;
          default:
            break;
        }
      }
    };

    Hub.listen('auth', onHubCapsule);

    const urlParams = new URLSearchParams(window.location.search);

    // If the current window doesn't have the code
    // included in the redirect after authorization
    // then call initLogin, the user isn't validated.
    // It will be called later in cognitoHostedUI
    // auth event (see above).
    if (!urlParams.has('code') && !urlParams.has('error')) {
      dispatch(initLogin());
    }

    // check for the existence of error paramenter in the query string
    if (urlParams.has('error')) {
      dispatch(userAuthorized(false));
    }
  }, [dispatch]);

  useEffect(() => {
    // check that is not a dev env and the user is not a datadog bot and only then add analytics tracking
    if (!isLocalhost() && window._DATADOG_SYNTHETICS_BROWSER === undefined) {
      initializeCookieConsent();
      initializeGTM('GTM-KTWCZXC');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NzThemeVariables />
      <NzTheme />
      <>
        <SnackBarProvider>
          <Routes>
            <Route path="/" element={<Loading />} />
            <Route path="/sso" element={<SSOAuthPage />} />
            <Route path="/signin" element={<AuthPage />} />
            <Route path="/unauthorized" element={<UnauthorizedMessage />} />
            <Route
              path="/collections"
              element={
                <PageWithHeader>
                  <CollectionList />
                  <FeedbackComponent />
                </PageWithHeader>
              }
            />
            <Route
              path="/collections/:selection"
              element={
                <PageWithHeader>
                  <CollectionList />
                  <FeedbackComponent />
                </PageWithHeader>
              }
            />
            <Route
              path="/collection"
              element={
                <PageWithHeader>
                  <CollectionForm />
                  <FeedbackComponent />
                </PageWithHeader>
              }
            />
            <Route
              path="/collection/:id"
              element={
                <PageWithHeader>
                  <CollectionForm />
                  <FeedbackComponent />
                </PageWithHeader>
              }
            />
            <Route
              path="/compare/:id"
              element={
                <PageWithHeader>
                  <ComparePage />
                  <FeedbackComponent />
                </PageWithHeader>
              }
            />
          </Routes>
        </SnackBarProvider>
      </>
    </ThemeProvider>
  );
};

export default App;
