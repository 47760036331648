import { useRef, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/material';
import { sendFeedback } from '../store/actions/actions';
import { useDispatch } from 'react-redux';

const FeedbackComponent: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const feedbackRef = useRef<HTMLInputElement>();

  const handleSendFeedback = () => {
    if (feedbackRef.current?.value) {
      setShowDialog(false);
      dispatch(sendFeedback({ message: feedbackRef.current?.value }));
    }
  };

  const FeedbackButton = styled(Button)({
    width: '200px',
    height: '50px',
    right: '-75px',
    bottom: '174px',
    margin: '0',
    position: 'fixed',
    transform: 'rotate(-90deg)',
    background: '#173629',
    borderRadius: '10px 10px 0px 0px',
    border: 'none',
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: '#bfe6c4',
      color: 'black',
      transition: 'all .6s',
    },
  });

  const FeedbackDialog = styled(Dialog)({
    '& .MuiPaper-root': {
      width: '288px !important',
      borderRadius: '24px !important',
      transition: 'all 2.3s ease-in-out !important',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
    },
    '& .MuiDialog-container': {
      height: '139% !important',
      outline: '0 !important',
      right: '30px !important',
      position: 'absolute',
      transition: 'all 0.9s !important',
    },
    transition: 'all 1.3s ease-in-out !important',
  });

  const FeedbackDialogContent = styled(DialogContent)({
    backgroundColor: '#173629 !important',
    overflowY: 'initial',
  });

  const FeedbackDialogContentText = styled(DialogContentText)({
    marginBottom: '0px !important',
    color: '#fff !important',
  });

  const FeedbackTextField = styled(TextField)({
    height: '257px !important',
    '& input': {
      borderRadius: '16px !important',
      backgroundColor: 'white !important',
      color: 'black !important',
    },
    '& fieldset': {
      borderRadius: '16px !important',
    },
    '& fieldset::placeholder !important': {
      color: 'red !important',
    },
    '& textarea': {
      borderRadius: '16px !important',
      backgroundColor: 'white !important',
      color: 'black !important',
      padding: '8px !important',
      height: '265px !important',
    },
    '& .MuiInputBase-root': {
      padding: '0 !important',
    },
  });

  const FeedbackDialogActions = styled(DialogActions)({
    backgroundColor: '#173629 !important',
    padding: '0px 24px !important',
  });

  const CancelButton = styled(Button)({
    color: 'white !important',
    padding: '16px 6px !important',

    '&:hover': { textDecoration: 'underline' },
  });

  const SubmitButton = styled(Button)({
    height: '32px !important',
    width: '83px !important',
    background: '#bfe6c4 !important',
    borderRadius: '58px !important',
    border: 'none !important',
    color: 'rgba(0, 0, 0, 0.8) !important',
    padding: '16px 6px !important',

    '&:hover': {
      backgroundColor: 'rgb(133, 161, 137) !important',
    },
    '&.Mui-disabled': {
      padding: '6px 16px !important',
      fontSize: '1rem !important',
      borderRadius: '3.625rem !important',
      border: 'none !important',
      backgroundColor: 'rgb(230, 230, 230) !important',
      color: 'rgba(0, 0, 0, 0.47) !important',
      textDecoration: 'none rgb(230, 230, 230) !important',
      boxShadow: 'none !important',
    },
  });

  return (
    <>
      <FeedbackButton data-cy="feedback-button" variant="outlined" onClick={handleClickOpen}>
        Feedback
      </FeedbackButton>
      <FeedbackDialog open={showDialog}>
        <FeedbackDialogContent>
          <FeedbackDialogContentText>Type your feedback</FeedbackDialogContentText>
          <FeedbackTextField
            data-cy="feedback-text"
            margin="dense"
            id="name"
            placeholder="What's on your mind?"
            fullWidth
            variant="outlined"
            multiline={true}
            minRows={14}
            maxRows={14}
            required
            inputRef={feedbackRef}
          />
        </FeedbackDialogContent>
        <FeedbackDialogActions>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <SubmitButton data-cy="feedback-submit" onClick={handleSendFeedback}>
            Submit
          </SubmitButton>
        </FeedbackDialogActions>
      </FeedbackDialog>
    </>
  );
};

export default FeedbackComponent;
