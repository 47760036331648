import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FormControl, Box, Button, Grid, FilledInput, Typography, Link, styled } from '@mui/material';
import { FormErrors } from './FormErrors';

const StyledFormControl = styled(FormControl)(() => ({
  fontSize: '16px',
  lineHeight: '19px',
  paddingBottom: '10px',
  fontWeight: 'bold',
}));

const StyledFilledInput = styled(FilledInput)(() => ({
  fontSize: '16px',
  lineHeight: '19px',
  paddingBottom: '10px',
  backgroundColor: 'var(--nz-bg-3)',
}));

const StyledLink = styled(Link)(() => ({
  fontSize: '16px',
  margin: '0px 20px',
  textDecoration: 'underline',
  textDecorationColor: '#bfe6c4',
  textDecorationThickness: '3px',
  cursor: 'pointer',
}));

interface Props {
  onCancel: () => void;
}

export const ResetPassword: React.FC<Props> = ({ onCancel }: Props) => {
  const [state, setState] = useState({
    code: '',
    email: '',
    password: '',
    codeSent: false,
    confirmed: false,
    confirmPassword: '',
    isConfirming: false,
    isSendingCode: false,
    errors: {
      cognito: null,
      blankfield: false,
    },
  });

  const validateCodeForm = () => {
    return state.email.length > 3;
  };

  const validateResetForm = () => {
    return state.code.length > 0 && state.password.length > 0 && state.password === state.confirmPassword;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSendCodeClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setState({ ...state, isSendingCode: true });

    try {
      await Auth.forgotPassword(state.email);
      setState({ ...state, codeSent: true });
    } catch (error: any) {
      console.log(error.message);
      setState({ ...state, isSendingCode: false });
    }
  };

  const handleConfirmClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setState({ ...state, isConfirming: true });
    try {
      await Auth.forgotPasswordSubmit(state.email, state.code, state.password);
      setState({ ...state, confirmed: true });
    } catch (error: any) {
      console.log(error.message);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setState({
        ...state,
        isConfirming: false,
        errors: {
          cognito: err,
          blankfield: false,
        },
      });
    }
  };

  const renderRequestCodeForm = () => {
    return (
      <Grid
        component="form"
        onSubmit={handleSendCodeClick}
        container
        direction="column"
        justifyContent="center"
        spacing={3}
      >
        <Grid item>
          <StyledFormControl fullWidth>
            <label htmlFor="email">Email</label>
            <StyledFilledInput
              required
              id="email"
              name="email"
              autoComplete="email"
              disableUnderline
              fullWidth
              style={{ height: '50px', padding: 0 }}
              value={state.email}
              onChange={handleChange}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" alignItems="center" direction="row">
            <StyledLink color="inherit" variant="body2" onClick={onCancel}>
              Cancel
            </StyledLink>
            <Button
              id="send-verification-code"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!validateCodeForm()}
            >
              Send verification code
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <React.Fragment>
        <FormErrors formerrors={state.errors} />
        <Grid
          component="form"
          container
          direction="column"
          justifyContent="center"
          spacing={3}
          onSubmit={handleConfirmClick}
        >
          <Grid item>
            <Typography>The verification code has been sent to your email.</Typography>
          </Grid>
          <Grid item>
            <StyledFormControl fullWidth>
              <label htmlFor="code">Verification Code</label>
              <StyledFilledInput
                required
                id="code"
                name="code"
                disableUnderline
                fullWidth
                style={{ height: '50px', padding: 0 }}
                value={state.code}
                onChange={handleChange}
              />
            </StyledFormControl>
          </Grid>
          <Grid item>
            <StyledFormControl fullWidth>
              <label htmlFor="email">Enter new password</label>
              <StyledFilledInput
                required
                id="password"
                name="password"
                disableUnderline
                fullWidth
                type="password"
                style={{ height: '50px', padding: 0 }}
                value={state.password}
                onChange={handleChange}
              />
            </StyledFormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <label htmlFor="confirmpassword">Confirm new password</label>
              <StyledFilledInput
                required
                id="confirmPassword"
                name="confirmPassword"
                disableUnderline
                fullWidth
                type="password"
                style={{ height: '50px', padding: 0 }}
                value={state.confirmPassword}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" direction="column">
              <Button
                id="set-new-password"
                type="submit"
                variant="contained"
                color="primary"
                disabled={!validateResetForm()}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <Grid container justifyContent="space-evenly" alignContent="center">
        <Grid item>
          <Typography component="h4">Your password has been reset.</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box pt={1} pb={3}>
      {!state.codeSent ? renderRequestCodeForm() : !state.confirmed ? renderConfirmationForm() : renderSuccessMessage()}
    </Box>
  );
};
