import React, { createContext, useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';

export type SnackColor = 'success' | 'info' | 'warning' | 'error';

type SnackBarContextActions = {
  showSnackBar: (text: string, status: SnackColor) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({ children }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [status, setStatus] = React.useState<SnackColor>('info');

  const showSnackBar = (text: string, status: SnackColor = 'info') => {
    setMessage(text);
    setStatus(status);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
        }}
      >
        <Alert
          onClose={handleClose}
          severity={status}
          icon={false}
          sx={{
            width: '100%',
            ...(status === 'error' && {
              backgroundColor: 'var(--nz-warning-24)',
            }),
            ...(status === 'warning' && {
              backgroundColor: 'var(--nz-green-24)',
            }),
            ...(status === 'info' && {
              backgroundColor: 'var(--nz-bg-3)',
            }),
            ...(status === 'success' && {
              backgroundColor: 'var(--nz-green-24)',
            }),
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};

export { SnackBarProvider, useSnackBar };
