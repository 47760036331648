import React from 'react';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material';

interface Props {
  message?: any;
}

const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  padding: '10px 0',
  color: 'var(--nz-warning)',
}));

export const Errors: React.FC<Props> = ({ message }) => {
  if (message) {
    return <StyledTypography>{message}</StyledTypography>;
  } else {
    return null;
  }
};
