import React from 'react';
import { Card, Typography, styled } from '@mui/material';

const StyledCard = styled(Card)({
  padding: '24px',
  marginBottom: '10px',
});

interface Props {
  id?: string;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Layout: React.FunctionComponent<Props> = ({ title, children, ...props }: Props) => {
  return (
    <StyledCard {...props}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {children}
    </StyledCard>
  );
};

export default Layout;
