import actionTypes from '../actions/actionTypes';
import { Action, StateInterface } from '../types';

type AuthState = StateInterface['authState'];

const initialAuthState: AuthState = {
  status: 'loading',
  isAuthorized: false,
  username: '',
  userGrants: [],
  message: '',
  environment: '',
};

export default (state: AuthState = initialAuthState, action: Action): AuthState => {
  switch (action.type) {
    case actionTypes.USER_AUTHENTICATED:
      return {
        ...state,
        username: action.payload.user.attributes.email.toLowerCase(),
        userGrants: action.payload.user.signInUserSession.accessToken.payload['cognito:groups'].filter(
          (element: string) => {
            if (element.startsWith('grant_')) return element;
          }
        ),
        message: '',
      };

    case actionTypes.USER_AUTHORIZED:
      return {
        ...state,
        isAuthorized: action.payload.isAuthorized,
        message: '',
      };

    case actionTypes.USER_SIGN_IN_FAILED:
      return {
        ...state,
        message: action.payload.message,
      };
    case actionTypes.ENVIRONMENT:
      return {
        ...state,
        environment: action.payload.environment,
      };
    default:
      return state;
  }
};
