import { format } from 'date-fns';

const DISPLAY_FORMAT = 'dd MMM yy HH:mm';
const DISPLAY_DATETIME_FORMAT = 'dd MMM HH:mm:ss';
const DISPLAY_FULL_FORMAT = 'MMMM d, yyyy';

export function formatUnixTime(unixTime: number): string {
  return format(new Date(unixTime * 1000), DISPLAY_FORMAT);
}

export function formatUnixDateTime(date: Date): string {
  return format(date, DISPLAY_DATETIME_FORMAT);
}

export function formatDatetime(datetime: string): string {
  return format(new Date(datetime), DISPLAY_FORMAT);
}

export function formatFullDate(datetime: string): string {
  return format(new Date(datetime), DISPLAY_FULL_FORMAT);
}
