import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '14px',
  height: '14px',
  marginRight: '12px',
}));

export default function CloneIcon(): JSX.Element {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9949 3.82373C15.9037 2.24892 14.5977 1 13 1H4L3.82373 1.00509C2.24892 1.09634 1 2.40232 1 4V13L1.00509 13.1763C1.09634 14.7511 2.40232 16 4 16H5L5.11662 15.9933C5.61396 15.9355 6 15.5128 6 15C6 14.4477 5.55228 14 5 14H4L3.88338 13.9933C3.38604 13.9355 3 13.5128 3 13V4L3.00673 3.88338C3.06449 3.38604 3.48716 3 4 3H13L13.1166 3.00673C13.614 3.06449 14 3.48716 14 4V5L14.0067 5.11662C14.0645 5.61396 14.4872 6 15 6C15.5523 6 16 5.55228 16 5V4L15.9949 3.82373ZM20 8H11C9.34315 8 8 9.34315 8 11V20C8 21.6569 9.34315 23 11 23H20C21.6569 23 23 21.6569 23 20V11C23 9.34315 21.6569 8 20 8ZM11 10H20C20.5523 10 21 10.4477 21 11V20C21 20.5523 20.5523 21 20 21H11C10.4477 21 10 20.5523 10 20V11C10 10.4477 10.4477 10 11 10Z"
          fill="black"
        />
      </svg>
    </StyledGrid>
  );
}
