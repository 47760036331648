import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {
  StyledDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@novozymes-digital/laundry-lab/components/UI/Dialog';
import { exportExcel } from '@novozymes-digital/laundry-lab/store/actions/actions';
import {
  getCurrentCollectionId,
  getCurrentCollectionRegion,
  getFormulations,
  getIsGeneratingExcelExportData,
  getRegions,
  getSelectedFormulations,
} from '@novozymes-digital/laundry-lab/store/selectors';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const StyledBox = styled(Box)(() => ({
  display: 'inline-block',
  margin: '32px',
  position: 'relative',
}));

const StyledLink = styled(Link)(() => ({
  fontSize: '16px',
  margin: '0px 20px',
  textDecoration: 'underline',
  textDecorationColor: '#bfe6c4',
  textDecorationThickness: '3px',
  cursor: 'pointer',
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
  color: green[500],
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f5f5f5',
  },
}));

const StyledTableCellTableNameRow = styled(TableCell)(() => ({
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
}));

const StyledTableCellTableNameRowTableCell = styled(StyledTableCellTableNameRow)(() => ({
  whiteSpace: 'nowrap',
}));

const ExportDataDialog: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch();
  const formulations = useSelector(getFormulations);
  const [collectionId, setCollectionId] = useState(0);
  const [selectedFormulations, setSelectedFormulations] = useState<string[]>([]);
  const preSelectedFormulations = useSelector(getSelectedFormulations);
  const collectionIdAsNumber = Number(useSelector(getCurrentCollectionId));
  const isLoading = useSelector(getIsGeneratingExcelExportData);
  const region = useSelector(getCurrentCollectionRegion);
  const dose_scale = useSelector(getRegions)[region].dose_scale;

  const handleClose = () => {
    setSelectedFormulations([]);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedFormulations(preSelectedFormulations);
      setCollectionId(collectionIdAsNumber);
    }
  }, [isOpen, preSelectedFormulations, collectionIdAsNumber]);

  const selectAll = () => {
    setSelectedFormulations(formulations.map((f) => f.id));
  };

  const selectNone = () => {
    setSelectedFormulations([]); // Empty first
  };

  const handleExport = () => {
    if (collectionId) {
      dispatch(exportExcel({ collectionId, formulationIds: selectedFormulations }));
    }
  };

  const onToggleSelect = (id: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event ? !!event?.target?.checked : !selectedFormulations.includes(id);
    if (checked) {
      setSelectedFormulations([...selectedFormulations, id]);
    } else {
      setSelectedFormulations(selectedFormulations.filter((formId) => formId !== id));
    }
  };

  return (
    <div>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby="export-data-dialog"
        open={isOpen}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: 'white',
            borderRadius: 24,
            maxWidth: 960,
          },
        }}
      >
        <DialogTitle onClose={handleClose}>Export data</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" justifyContent="flex-end" style={{ marginBottom: 8 }}>
            <Grid item>
              <StyledLink color="inherit" variant="body2" onClick={selectAll}>
                Select all
              </StyledLink>
              <StyledLink color="inherit" variant="body2" onClick={selectNone}>
                Clear all
              </StyledLink>
            </Grid>
          </Grid>
          <Grid container>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="50" align="center">
                    <Grid container justifyContent="center"></Grid>
                  </TableCell>
                  <StyledTableHeadCell width="300">Formulation name</StyledTableHeadCell>
                  <TableCell style={{ fontWeight: 400 }} width="150" align="center">
                    Temperature °C
                  </TableCell>
                  <TableCell style={{ fontWeight: 400 }} width="150" align="center">
                    Dosage {dose_scale}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formulations.map((formulation) => (
                  <StyledTableRow key={formulation.id}>
                    <StyledTableCell align="center">
                      <Checkbox
                        checked={selectedFormulations.includes(formulation.id)}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          onToggleSelect(formulation.id, event);
                        }}
                        name={formulation.id}
                        color="primary"
                      />
                    </StyledTableCell>
                    <StyledTableCellTableNameRowTableCell
                      className={clsx()}
                      onClick={() => onToggleSelect(formulation.id)}
                    >
                      {formulation.name}
                    </StyledTableCellTableNameRowTableCell>
                    <StyledTableCell align="center">{formulation.temperature || 0}</StyledTableCell>
                    <StyledTableCell align="center">{formulation.dose || 0}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingTop: 0 }}>
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <StyledBox>
                <Button
                  data-cy="export-button"
                  autoFocus
                  onClick={handleExport}
                  color="primary"
                  variant="contained"
                  disabled={isLoading || !selectedFormulations?.length}
                >
                  Export
                </Button>
                {isLoading && <StyledCircularProgress size={30} />}
              </StyledBox>
            </Grid>
          </Grid>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default ExportDataDialog;
