import { combineEpics, Epic, select, selectArray } from 'redux-most';
import * as most from 'most';
import Auth from '@aws-amplify/auth';
import { replace, push } from 'redux-first-history';
import { IngredientsInputLayoutsType, Region, TARGET_PH } from '@novozymes-digital/laundry-lab/static/Constants';
import actionTypes from './actions/actionTypes';
import {
  Action,
  BackendCollection,
  BackendFormulation,
  EcolabelResult,
  StainRemovalResultBackendData,
  StateInterface,
  SustainabilityResult,
  ExportedFormulation,
  StainRemovalResult,
  RegionType,
  StainRegions,
  StainGroupCreateResult,
  StainGroupCustom,
  Formulation,
  Opportunities,
} from './types';
import api from './api';
import {
  calculateFormulation,
  calculateEcoLabel,
  calculateEcoLabelResult,
  calculateStainRemoval,
  calculateStainRemovalResult,
  calculateSustainability,
  calculateSustainabilityResult,
  closeNewFormulation,
  createCollectionResult,
  createFormulationResult,
  updateFormulationResult,
  fetchFormulations,
  fetchFormulationsResult,
  fetchUserCollections,
  fetchUserCollectionsResult,
  updateCollectionResult,
  userAuthenticated,
  userAuthorized,
  pinCollectionResult,
  deleteCollectionResult,
  deleteFormulationResult,
  exportExcelResult,
  getStainGroupsResult,
  getIngredientsResult,
  getRevisionResult,
  getRegionsResult,
  getStainsRegionResult,
  notificationShow,
  closeEditFormulation,
  getStainCustomGroupsResult,
  setStainGroup,
  deleteStainCustomGroupResult,
  getCustomStainGroup,
  cloneCollectionResult,
  cloneFormulationResult,
  sendCollectionResult,
  voidResult,
  environment,
  getBlendsResult,
  getOpportunitiesResult,
  sendFeedbackResult,
} from './actions/actions';
import {
  collectionCreateDataMapper,
  collectionFrontendDataMapper,
  collectionUpdateDataMapper,
  customStainGroupDataMapper,
  formulationCreateDataMapper,
  formulationDataMapper,
  modelEcoLabelRequestMapper,
  modelPredictionRequestMapper,
  modelPredictionResultMapper,
  modelSustainabilityRequestMapper,
} from './dataMappers';

import {
  getCurrentCollectionRegion,
  getFormulationById,
  getCurrentCollection,
  getUserUnits,
  getFormulationsByIds,
  getStainGroups,
  getIngredientsByRegionAndGroup,
  getRevision,
  getSelectedFormulations,
  getFormulations,
  getStainCustomGroups,
  getNewFormulationData,
  getRegions,
  getIngredientMaxValue,
  getUserAllGrants,
} from './selectors';
import exportXlsx from '@novozymes-digital/laundry-lab/utility/exportXlsx';
import { sortStainsResults } from '@novozymes-digital/laundry-lab/utility/CustomFunctions';
import {
  GtmAuthenticateEvent,
  GtmCalculateFormulationEvent,
  GtmCalculateNewFormulationEvent,
  GtmCloneCollectionEvent,
  GtmCloneFormulationEvent,
  GtmCreateCollectionRequestEvent,
  GtmCreateCollectionSucceedEvent,
  GtmCreateCustomStainGroupRequestEvent,
  GtmCreateCustomStainGroupSucceedEvent,
  GtmSetCustomStainGroupRequestEvent,
  GtmSetCustomStainGroupSucceedEvent,
  GtmCreateFormulationRequestEvent,
  GtmCreateFormulationSucceedEvent,
  GtmDeleteCollectionEvent,
  GtmDeleteCustomStainGroupEvent,
  GtmDeleteFormulationEvent,
  GtmExportExcelRequestEvent,
  GtmExportExcelSucceedEvent,
  GtmResponseCurveRequestEvent,
  GtmSendCollectionEvent,
  GtmSignOutEvent,
  GtmUpdateCollectionRequestEvent,
  GtmUpdateCollectionSucceedEvent,
  GtmUpdateFormulationRequestEvent,
  GtmUpdateFormulationSucceedEvent,
  GtmSendFeedbackEvent,
} from '@novozymes-digital/laundry-lab/utility/TagManager';

import FileSaver from 'file-saver';

const checkAuthEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) => {
  const path = window.location.pathname;

  return action$
    .thru(selectArray([actionTypes.LOGIN_INIT, actionTypes.APP_INIT]))
    .flatMap(() => {
      return most
        .fromPromise(Auth.currentSession())
        .flatMap(() => most.fromPromise(Auth.currentAuthenticatedUser()).map((user) => userAuthenticated(user)))
        .recoverWith((error) => {
          console.log('error', error);
          if (path.includes('signin')) {
            return most.of(replace('/signin'));
          } else {
            return most.of(replace('/sso'));
          }
        });
    })
    .recoverWith((error) => {
      console.log('error', error);
      return most.empty();
    });
};

const authorizeEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap((action) => {
    const { user } = action.payload;
    return most
      .fromPromise(api.User.securityGroup())
      .flatMap((result) => {
        const apiResultData: { security_group_name: string; environment: string } = result.data();
        const requiredAccessGroup = apiResultData['security_group_name'];
        const env = apiResultData['environment'];
        const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

        // laundryLabAccess is a Salesforce flag
        let laundryLabAccess = true;
        if (user.signInUserSession.idToken.payload?.['custom:custom_attributes'] !== undefined) {
          const custom_attributes = JSON.parse(user.signInUserSession.idToken.payload['custom:custom_attributes']);
          laundryLabAccess = custom_attributes.LaundryLabAccess === 'true';
        }

        const grantAccess =
          (isDevEnv && requiredAccessGroup === 'local') ||
          (user.signInUserSession.accessToken.payload['cognito:groups'].indexOf(requiredAccessGroup) !== -1 &&
            laundryLabAccess);

        if (grantAccess) {
          GtmAuthenticateEvent();
        }

        return most.from([userAuthorized(grantAccess), environment(env)]);
      })
      .recoverWith((error) => {
        console.error('Error authorizing user', error);
        return most.of(userAuthorized(false));
      });
  });

const afterAuthEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHORIZED)).flatMap((action) => {
    const { isAuthorized } = action.payload;
    if (isAuthorized) {
      const actionsToDispatch = [fetchUserCollections()];

      if (['/', '/sso', '/signin'].includes(window.location.pathname)) {
        actionsToDispatch.push(push('/collections/pinned'));
      }
      return most.from(actionsToDispatch);
    }
    return most.of(replace('/unauthorized'));
  });

const logMeEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_SIGN_IN)).flatMap(() => {
    return most
      .fromPromise(
        api.User.logMe({
          body: {
            tc_url: 'https://www.novozymes.com/en/legal-notice',
            privacy_url: 'https://www.novozymes.com/en/privacy-policy',
            client_host: window.location.host,
          },
        })
      )
      .map((response) => {
        return voidResult({ data: response });
      })
      .recoverWith((error: Error) => {
        return most.of(voidResult({ error }));
      });
  });

const userSignOutEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_SIGN_OUT)).flatMap(() => {
    if (window.location.pathname.includes('/signin')) {
      // sign out from username/passwrod login, send user back to /signin page
      return most
        .fromPromise(Auth.signOut())
        .tap(() => GtmSignOutEvent())
        .map(() => replace('/signin'));
    } else {
      // sign out from sso\federated sign in, the Open ID~
      // provider will redirect the user back to the main page
      return most.fromPromise(Auth.signOut()).tap(() => GtmSignOutEvent());
    }
  });

const fetchCollectionsEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.FETCH_USER_COLLECTIONS)).flatMap(() =>
    most
      .fromPromise(api.Collections.get())
      .map((response) => {
        const backendCollections: BackendCollection[] = response.data();
        const sortedCollections = backendCollections.sort((a, b) => b.modification_date - a.modification_date);
        const mappedCollections = sortedCollections.map(collectionFrontendDataMapper);

        // Map collections from API to local structure
        return fetchUserCollectionsResult({ collections: mappedCollections });
      })
      .recoverWith((error: Error) => {
        return most.of(fetchUserCollectionsResult({ error }));
      })
  );

const selectCollectionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.SELECT_COLLECTION)).map((action) => {
    return fetchFormulations(action.payload.collectionId);
  });

const createCollectionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.CREATE_COLLECTION)).flatMap((action) => {
    GtmCreateCollectionRequestEvent();
    return most
      .fromPromise(api.Collections.create({ body: collectionCreateDataMapper(action.payload.collection) }))
      .tap(() => {
        GtmCreateCollectionSucceedEvent();
      })
      .map((response) => {
        const responseData: BackendCollection = response.data();
        return createCollectionResult({ collection: collectionFrontendDataMapper(responseData) });
      })
      .recoverWith((error: Error) => {
        return most.of(createCollectionResult({ error }));
      });
  });

const updateCollectionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.UPDATE_COLLECTION)).flatMap((action) => {
    GtmUpdateCollectionRequestEvent();
    return most
      .fromPromise(api.Collections.update({ body: collectionUpdateDataMapper(action.payload.collection) }))
      .tap(() => {
        GtmUpdateCollectionSucceedEvent();
      })
      .map((response) => {
        const responseData: BackendCollection = response.data();
        return updateCollectionResult({ collection: collectionFrontendDataMapper(responseData) });
      })
      .recoverWith((error: Error) => {
        return most.of(updateCollectionResult({ error }));
      });
  });

const closeCollectionCreateEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.CREATE_COLLECTION_RESULT)).flatMap((action) => {
    if (!action.error) {
      return most.from([
        fetchUserCollections(),
        push(`/compare/${action.payload.collection.id}`),
        notificationShow({ message: 'Collection created', status: 'success' }),
      ]);
    }
    return most.of(notificationShow({ message: 'Error creating collection', status: 'error' }));
  });

const closeCollectionEditEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.UPDATE_COLLECTION_RESULT)).flatMap((action) => {
    if (!action.error) {
      return most.from([
        fetchUserCollections(),
        push(`/compare/${action.payload.collection.id}`),
        notificationShow({ message: 'Collection updated', status: 'success' }),
      ]);
    }
    return most.of(notificationShow({ message: 'Error editing collection', status: 'error' }));

    /*
    let errorMessage = 'Error from server';
    const responseData = 'responseData' in action.payload ? action.payload.responseData : {};
    if (responseData) {
      const responseDataObj = JSON.parse(responseData);
      errorMessage = 'detail' in responseDataObj ? responseDataObj.detail : 'Error from server';
    }
    return most.of(notificationShow({ message: errorMessage, status: 'error' }));
    */
  });

const collectionTogglePinnedEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.PIN_COLLECTION)).flatMap((action) => {
    const { collectionId, isCurrentlyPinned } = action.payload;
    return most
      .fromPromise(api.Collections.pinn({ body: { is_starred: !isCurrentlyPinned, id: collectionId } }))
      .flatMap(() => {
        return most.from([pinCollectionResult({ collectionId }), fetchUserCollections()]);
      })
      .recoverWith((error: Error) => {
        return most.of(pinCollectionResult({ error }));
      });
  });

const deleteCollectionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.DELETE_COLLECTION)).flatMap((action) => {
    const { collectionId } = action.payload;
    return most
      .fromPromise(api.Collections.delete({ body: { id: collectionId } }))
      .tap(() => {
        GtmDeleteCollectionEvent();
      })
      .flatMap(() => {
        return most.from([
          deleteCollectionResult({}),
          fetchUserCollections(),
          notificationShow({ message: 'Collection deleted', status: 'success' }),
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(deleteCollectionResult({ error }));
      });
  });

const cloneCollectionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.CLONE_COLLECTION)).flatMap((action) => {
    const { collectionId } = action.payload;
    return most
      .fromPromise(api.Collections.clone({ body: { id: collectionId } }))
      .tap(() => {
        GtmCloneCollectionEvent();
      })
      .flatMap(() => {
        return most.from([
          cloneCollectionResult({}),
          fetchUserCollections(),
          notificationShow({ message: 'Collection cloned', status: 'success' }),
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(cloneCollectionResult({ error }));
      });
  });

const sendCollectionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.SEND_COLLECTION)).flatMap((action) => {
    const { collectionId, newUser } = action.payload;
    return most
      .fromPromise(api.Collections.send({ body: { collection_id: collectionId, new_user: newUser } }))
      .tap(() => {
        GtmSendCollectionEvent();
      })
      .flatMap(() => {
        return most.from([
          sendCollectionResult({}),
          fetchUserCollections(),
          notificationShow({ message: 'Collection sent', status: 'success' }),
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(sendCollectionResult({ error }));
      });
  });

const deleteFormulationEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.DELETE_FORMULATION)).flatMap((action) => {
    const { formulationId, collectionId } = action.payload;
    return most
      .fromPromise(api.Formulations.delete({ body: { id: formulationId } }))
      .tap(() => {
        GtmDeleteFormulationEvent();
      })
      .flatMap(() => {
        return most.from([
          deleteFormulationResult({ deletedFormulationId: formulationId }),
          fetchFormulations(collectionId),
          notificationShow({ message: 'Formulation deleted', status: 'success' }),
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(deleteFormulationResult({ error }));
      });
  });

const fetchFormulationsEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.FETCH_FORMULATIONS)).flatMap((action) => {
    return most
      .fromPromise(api.Formulations.get({ collectionId: action.payload.collectionId }))
      .map((response) => {
        const collectionFormulations: BackendFormulation[] = response.data();

        const mappedFormulations = collectionFormulations.map(formulationDataMapper);

        return fetchFormulationsResult({ formulations: [...mappedFormulations] });
      })
      .recoverWith((error: Error) => {
        return most.of(fetchFormulationsResult({ error }));
      });
  });

const cloneFormulationEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.CLONE_FORMULATION)).flatMap((action) => {
    const { formulationId, collectionId } = action.payload;
    return most
      .fromPromise(api.Formulations.clone({ body: { formulation_id: formulationId, collection_id: collectionId } }))
      .tap(() => {
        GtmCloneFormulationEvent();
      })
      .flatMap(() => {
        return most.from([
          cloneFormulationResult({}),
          fetchFormulations(collectionId),
          notificationShow({ message: 'Formulation cloned', status: 'success' }),
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(cloneFormulationResult({ error }));
      });
  });

const goToIndexEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.GO_TO_INDEX)).map(() => {
    return push('/collections/pinned');
  });

const toggleSelectFormulationEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.TOGGLE_SELECT_FORMULATION)).flatMap((action) => {
    const { formulationId } = action.payload;
    return most.of(calculateFormulation({ formulationId }));
  });

const calculateFormulationEpic: Epic<StateInterface, Action | any> = (
  action$: most.Stream<Action>,
  { getState }: any
) =>
  action$.thru(select(actionTypes.CALCULATE_FORMULATION)).flatMap((action) => {
    const state = getState();
    const { formulationId } = action.payload;
    const formulationData = getFormulationById(state)(formulationId);
    const region = getCurrentCollectionRegion(state);
    const detergent_volume = getCurrentCollection(state)?.detergent_volume;

    if (!formulationData) {
      return most.empty();
      // return most.of(calculateFormulationResult({ formulationId, error: new Error('No formulation with id') }));
    }

    GtmCalculateFormulationEvent();

    const actions = ['la', 'afr', 'me', 'ind', 'sea'].includes(region)
      ? [calculateStainRemoval({ formulationData }), calculateSustainability({ formulationData, detergent_volume })]
      : [
          calculateStainRemoval({ formulationData }),
          calculateSustainability({ formulationData, detergent_volume }),
          calculateEcoLabel({ formulationData, region }),
        ];

    const actionsT = ['la', 'afr', 'me', 'ind', 'sea'].includes(region)
      ? [actionTypes.CALCULATE_STAIN_REMOVAL_RESULT, actionTypes.CALCULATE_SUSTAINABILITY_RESULT]
      : [
          actionTypes.CALCULATE_STAIN_REMOVAL_RESULT,
          actionTypes.CALCULATE_SUSTAINABILITY_RESULT,
          actionTypes.CALCULATE_ECO_LABEL_RESULT,
        ];

    return most.merge(
      most.from(actions),
      action$.thru(selectArray(actionsT)).flatMap(() => {
        return most.empty();
      })
    );
  });

const getHash = (stains: any, newCustomStain: any) => {
  const cyrb53 = function (str: string, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };
  const customIds = stains.map((x: any) => x.id);
  const customLabels = stains.map((x: any) => x.stain_group_label);
  const customStains = stains
    .filter((x: any) => !newCustomStain || x.id !== newCustomStain.id)
    .reduce((acc: any, curVal: any) => acc.concat(curVal.stains.map((x: any) => x.stain_id)), []);
  if (newCustomStain) {
    customIds.push(newCustomStain.id);
    customLabels.push(newCustomStain.stain_group_label);
    customStains.push(...newCustomStain.stains);
  }
  const onlyUnique = (arr: any) => {
    return arr.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  };

  const hash = cyrb53(
    onlyUnique(customIds).sort().toString() +
      onlyUnique(customLabels).sort().toString() +
      customStains.sort().toString()
  );

  return hash;
};

const calculateStainRemovalEpic: Epic<StateInterface, Action | any> = (
  action$: most.Stream<Action>,
  { getState }: any
) =>
  action$.thru(select(actionTypes.CALCULATE_STAIN_REMOVAL)).flatMap((action) => {
    const state = getState();
    const { formulationData, newCustomStain } = action.payload;
    const region = getCurrentCollectionRegion(state);
    const revision = getRevision(state);
    const currentCollection = getCurrentCollection(state);
    const collection_id = currentCollection?.id;
    const customStains = getStainCustomGroups(state);
    const hash = getHash(customStains, newCustomStain);
    const regions = getRegions(state);
    const ingredientsMaxValue = getIngredientMaxValue(state);

    return most
      .fromPromise(
        api.Calculate.stainRemoval(
          modelPredictionRequestMapper(
            formulationData,
            region,
            revision,
            regions,
            ingredientsMaxValue,
            collection_id,
            hash
          ) as any
        )
      )
      .map((response) => {
        const backendStainRemovalResult: StainRemovalResultBackendData = response.data();
        const result: StainRemovalResult = sortStainsResults(modelPredictionResultMapper(backendStainRemovalResult));
        return calculateStainRemovalResult({ formulationId: formulationData.id, stainRemovalResult: result });
        console.log('calculate stain epic: ', result);
      })
      .recoverWith((error: Error) => {
        return most.of(calculateStainRemovalResult({ formulationId: formulationData.id, error }));
      });
  });

const calculateSustainabilityEpic: Epic<StateInterface, Action | any> = (
  action$: most.Stream<Action>,
  { getState }: any
) =>
  action$.thru(select(actionTypes.CALCULATE_SUSTAINABILITY)).flatMap((action) => {
    const state = getState();
    const { formulationData } = action.payload;
    const region = getCurrentCollectionRegion(state);
    const regions = getRegions(state);
    const detergent_volume = getCurrentCollection(state)?.detergent_volume;

    return most
      .fromPromise(
        api.Calculate.sustainability({
          body: modelSustainabilityRequestMapper(formulationData, region, regions, detergent_volume),
        })
      )
      .map((response) => {
        const sustainabilityResult: SustainabilityResult = response.data();
        return calculateSustainabilityResult({ formulationId: formulationData.id, sustainabilityResult });
      })
      .recoverWith((error: Error) => {
        return most.of(calculateSustainabilityResult({ formulationId: formulationData.id, error }));
      });
  });

const calculateEcoLabelEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.CALCULATE_ECO_LABEL)).flatMap((action) => {
    const { formulationData, region } = action.payload;

    return most
      .fromPromise(
        api.Calculate.ecolabel({
          body: modelEcoLabelRequestMapper(formulationData, region),
        })
      )
      .map((response) => {
        const ecolabelResult: EcolabelResult = response.data();
        return calculateEcoLabelResult({ formulationId: formulationData.id, ecolabelResult });
      })
      .recoverWith((error: Error) => {
        return most.of(calculateEcoLabelResult({ formulationId: formulationData.id, error }));
      });
  });

const updateNewFormulationEpic: Epic<StateInterface, Action | any> = (
  action$: most.Stream<Action>,
  { getState }: any
) =>
  action$
    .thru(select(actionTypes.UPDATE_NEW_FORMULATION))
    .debounce(1000)
    .tap(() => {
      GtmCalculateNewFormulationEvent();
    })
    .flatMap((action) => {
      const state = getState();
      const { formulationData } = action.payload;
      const region = action.payload.region || formulationData.region;
      const detergent_volume = getCurrentCollection(state)?.detergent_volume;
      const actions = ['la', 'afr', 'me', 'ind', 'sea'].includes(region)
        ? [calculateStainRemoval({ formulationData }), calculateSustainability({ formulationData, detergent_volume })]
        : [
            calculateStainRemoval({ formulationData }),
            calculateSustainability({ formulationData, detergent_volume }),
            calculateEcoLabel({ formulationData, region }),
          ];

      return most.from(actions);
    });

const updateFormulationEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>, { getState }: any) =>
  action$.thru(select(actionTypes.UPDATE_FORMULATION)).flatMap((action) => {
    const state = getState();
    const currentCollection = getCurrentCollection(state);
    if (!currentCollection) {
      return most.of(
        updateFormulationResult({ error: new Error('Failed to update formulation, currentCollection not defined') })
      );
    }

    GtmUpdateFormulationRequestEvent();

    return most
      .fromPromise(api.Formulations.update({ body: action.payload.formulation }))
      .flatMap((response) => {
        const responseStatus = response.status();
        if (responseStatus === 200) {
          GtmUpdateFormulationSucceedEvent();

          const responseData: BackendFormulation = response.data();
          const formulation = formulationDataMapper(responseData);
          return most.from([
            updateFormulationResult({ formulation }),
            calculateFormulation({ formulationId: formulation.id }),
            closeEditFormulation(),
            notificationShow({ message: 'Formulation updated', status: 'success' }),
          ]);
        }
        return most.of(
          updateFormulationResult({
            error: new Error('Failed to update formulation, API responded with ' + responseStatus),
          })
        );
      })

      .recoverWith((error: Error) => {
        return most.of(updateFormulationResult({ error }));
      });
  });

const createFormulationEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>, { getState }: any) =>
  action$.thru(select(actionTypes.CREATE_FORMULATION)).flatMap((action) => {
    const state = getState();
    const currentCollection = getCurrentCollection(state);
    if (!currentCollection) {
      return most.of(
        createFormulationResult({ error: new Error('Failed to create formulation, currentCollection not defined') })
      );
    }

    GtmCreateFormulationRequestEvent();

    return most
      .fromPromise(
        api.Formulations.create({
          body: formulationCreateDataMapper(action.payload.formulationData, currentCollection),
        })
      )
      .flatMap((response) => {
        const responseStatus = response.status();
        // console.log(response);

        if (responseStatus === 201) {
          GtmCreateFormulationSucceedEvent();

          return most.from([
            createFormulationResult({}),
            fetchFormulations(currentCollection.id!),
            closeNewFormulation(),
            notificationShow({ message: 'Formulation created', status: 'success' }),
          ]);
        }
        return most.of(
          createFormulationResult({
            error: new Error('Failed to create formulation, API responded with ' + responseStatus),
          })
        );
      })
      .recoverWith((error: Error) => {
        return most.of(createFormulationResult({ error }));
      });
  });

const exportExcelEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>, { getState }: any) =>
  action$.thru(select(actionTypes.EXPORT_EXCEL)).flatMap((action) => {
    const state = getState();
    const collectionId: number = action.payload.collectionId;

    const formulationIds: string[] = action.payload.formulationIds;
    const selectedFormulations = getFormulationsByIds(getState())(formulationIds);

    if (!collectionId || !formulationIds?.length) {
      return most.empty();
    }

    const target_ph = TARGET_PH;
    const collectionRegion = getCurrentCollectionRegion(state);
    GtmExportExcelRequestEvent({ region: collectionRegion }); // Register Event in GTM
    const stainGroupNames = getStainGroups(state)[collectionRegion];
    const ingredients = getIngredientsByRegionAndGroup(state)[collectionRegion];
    const activeCollection = getCurrentCollection(state);
    const userAllGrants = getUserAllGrants(state);

    const grantGlobalCosmed = userAllGrants.some((grant) => grant.includes('grant_GlobalCosmed'));

    return most
      .fromPromise(
        api.Calculate.collection({
          collectionId: collectionId,
          body: {
            target_ph: target_ph,
            formulations: selectedFormulations,
            detergent_volume: activeCollection?.detergent_volume,
          },
        })
      )
      .flatMap((response) => {
        const formulations: ExportedFormulation[] = response.data();
        const activeCollection = getCurrentCollection(state);
        const weightUnit = getUserUnits(state).weight;
        const regions = getRegions(state);

        if (!activeCollection || !formulations?.length) {
          return most.empty();
        }

        // Sort stains by name
        for (const formulation of formulations) {
          formulation.stainRemoval = sortStainsResults(modelPredictionResultMapper(formulation.model_prediction));
        }

        return most
          .fromPromise(
            exportXlsx({
              activeCollection,
              formulations,
              weightUnit,
              stainGroupNames,
              ingredients,
              regions,
              grantGlobalCosmed,
            })
          )
          .tap(() => {
            GtmExportExcelSucceedEvent({ region: collectionRegion }); // Register Event in GTM
          })
          .map(() => {
            return exportExcelResult({});
          });
      })
      .recoverWith((error: Error) => {
        console.log('error', error);
        return most.of(exportExcelResult({ error }));
      });
  });

const responseCurveEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>, { getState }: any) =>
  action$.thru(select(actionTypes.RESPONSE_CURVE)).flatMap((action) => {
    const state = getState();
    const collectionId: number = action.payload.collectionId;

    const formulationIds: string[] = action.payload.formulationIds;
    const selectedFormulations = getFormulationsByIds(getState())(formulationIds);

    if (!collectionId || !formulationIds?.length) {
      return most.empty();
    }

    const target_ph = TARGET_PH;
    const collectionRegion = getCurrentCollectionRegion(state);
    GtmResponseCurveRequestEvent({ region: collectionRegion });

    return most
      .fromPromise(
        api.Calculate.responseCurve({
          collectionId: collectionId,
          body: { target_ph: target_ph, formulations: selectedFormulations },
        })
      )
      .flatMap((response) => {
        const ack: { message: string } = response.data();
        return most.of(notificationShow({ message: ack.message, status: 'success' }));
      })
      .recoverWith(() => {
        return most.of(notificationShow({ message: 'Error requesting response curve calculation', status: 'error' }));
      });
  });

const getStainGroupsEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.Constants.stainGroups())
      .map((response) => {
        const stainGroups: Record<RegionType, Record<string, string>> = response.data();
        return getStainGroupsResult({ stainGroups });
      })
      .recoverWith((error: Error) => {
        return most.of(getStainGroupsResult({ stainGroups: null, error }));
      });
  });

const getIngredientsEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.Constants.ingredients())
      .map((response) => {
        const ingredients: IngredientsInputLayoutsType = response.data();
        return getIngredientsResult({ ingredients });
      })
      .recoverWith((error: Error) => {
        return most.of(getIngredientsResult({ ingredients: null, error }));
      });
  });

const getBlendsEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.Constants.blends())
      .map((response) => {
        const blends: any = response.data();
        return getBlendsResult({ blends });
      })
      .recoverWith((error: Error) => {
        return most.of(getBlendsResult({ blends: null, error }));
      });
  });

const getRevisionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.Constants.revision())
      .map((response) => {
        const revision: number = response.data();
        return getRevisionResult({ revision });
      })
      .recoverWith((error: Error) => {
        return most.of(getRevisionResult({ revision: null, error }));
      });
  });

const getRegionsEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.Constants.regions())
      .map((response) => {
        const regions: Region = response.data();
        return getRegionsResult({ regions });
      })
      .recoverWith((error: Error) => {
        return most.of(getRegionsResult({ regions: null, error }));
      });
  });

const getOpportunitiesEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.User.opportunities())
      .map((response) => {
        const opportunities: Opportunities = response.data();
        return getOpportunitiesResult({ opportunities });
      })
      .recoverWith((error: Error) => {
        return most.of(getOpportunitiesResult({ opportunities: null, error }));
      });
  });

const getStainsRegionEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.USER_AUTHENTICATED)).flatMap(() => {
    return most
      .fromPromise(api.Constants.stainsRegions())
      .map((response) => {
        const stainsRegions: StainRegions = response.data();
        return getStainsRegionResult({ stainsRegions });
      })
      .recoverWith((error: Error) => {
        return most.of(getStainsRegionResult({ stainsRegions: null, error }));
      });
  });

const createCustomStainGroupEpic: Epic<StateInterface, Action | any> = (
  action$: most.Stream<Action>,
  { getState }: any
) =>
  action$.thru(select(actionTypes.CREATE_CUSTOM_STAIN_GROUP)).flatMap((action) => {
    const state = getState();
    const currentCollection = getCurrentCollection(state);

    const stains = action.payload.stainGroupData.stains;

    GtmCreateCustomStainGroupRequestEvent();

    return most
      .fromPromise(
        api.CustomStains.create({
          body: customStainGroupDataMapper(action.payload.stainGroupData, currentCollection),
        })
      )
      .flatMap((response) => {
        const result: StainGroupCreateResult = response.data();
        const responseStatus = response.status();
        if (responseStatus === 200) {
          GtmCreateCustomStainGroupSucceedEvent();
        }
        const stainGroupData = {
          stain_group: result.stain_group,
          stain_group_label: result.stain_group_label,
          sort_order: result.sort_order,
          stains: stains,
          id: result.id,
          collection_id: result.collection_id,
        };
        const actionsToDispatch = [setStainGroup({ stainGroupData })];
        return most.from(actionsToDispatch);
      })
      .recoverWith((error: Error) => {
        return most.of(getStainsRegionResult({ stainsRegions: null, error }));
      });
  });

const setStainsGroupEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>, { getState }: any) =>
  action$.thru(select(actionTypes.SET_CUSTOM_STAIN_GROUP)).flatMap((action) => {
    const state = getState();
    const currentCollection = getCurrentCollection(state);

    const formulationsState = getFormulations(state);
    const newFormulation = getNewFormulationData(state);

    const selectedFormulations = getSelectedFormulations(state);
    let formulationToCall = formulationsState.filter(
      (item) => selectedFormulations.indexOf(item.id) !== -1
    ) as Formulation[];

    if (newFormulation) formulationToCall = [...formulationToCall, newFormulation];

    const calculateStainRemovals = formulationToCall.map((formulationData) =>
      calculateStainRemoval({ formulationData, newCustomStain: action.payload.stainGroupData })
    );

    const createStainGroup = {
      collection_id: currentCollection?.id,
      stain_group: action.payload.stainGroupData.stain_group,
      stain_group_label: action.payload.stainGroupData.stain_group_label,
      sort_order: action.payload.stainGroupData.sort_order,
      stain_group_id: action.payload.stainGroupData.id,
      stains: action.payload.stainGroupData.stains,
    };

    GtmSetCustomStainGroupRequestEvent();

    return most
      .fromPromise(api.CustomStains.update({ body: createStainGroup }))
      .flatMap((response) => {
        const responseStatus = response.status();
        if (responseStatus === 200) {
          GtmSetCustomStainGroupSucceedEvent();
        }
        return most.from([
          getCustomStainGroup(),
          ...calculateStainRemovals,
          notificationShow({ message: 'Custom group saved', status: 'success' }),
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(getStainsRegionResult({ stainsRegions: null, error }));
      });
  });

const getStainsCustomGroupsEpic: Epic<StateInterface, Action | any> = (
  action$: most.Stream<Action>,
  { getState }: any
) =>
  action$.thru(selectArray([actionTypes.SELECT_COLLECTION, actionTypes.GET_STAIN_CUSTOM_GROUPS])).flatMap(() => {
    const state = getState();
    const currentCollection = getCurrentCollection(state);
    return most
      .fromPromise(api.CustomStains.get({ collectionId: currentCollection?.id }))
      .map((response) => {
        const stainCustomGroups: StainGroupCustom = response.data();
        return getStainCustomGroupsResult({ stainCustomGroups });
      })
      .recoverWith((error: Error) => {
        return most.of(getStainCustomGroupsResult({ stainCustomGroups: null, error }));
      });
  });

const deleteCustomStainGroupEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.DELETE_CUSTOM_STAIN_GROUP)).flatMap((action) => {
    const { id, collection_id } = action.payload;
    /*const state = getState();
    const formulations = getFormulations(state);
    const selectedFormulations = getSelectedFormulations(state);
    const formulationToCall = formulations.filter(
      (item) => selectedFormulations.indexOf(item.id) !== -1
    ) as Formulation[];
    const calculateStainRemovals = formulationToCall.map((formulationData) =>
      calculateStainRemoval({ formulationData })
    );*/

    return most
      .fromPromise(api.CustomStains.delete({ body: { id: id, collection_id } }))
      .tap(() => {
        GtmDeleteCustomStainGroupEvent();
      })
      .flatMap(() => {
        return most.from([
          deleteStainCustomGroupResult({}),
          getCustomStainGroup(),
          notificationShow({ message: 'Custom group deleted', status: 'success' }),
          //...calculateStainRemovals,
        ]);
      })
      .recoverWith((error: Error) => {
        return most.of(deleteStainCustomGroupResult({ error }));
      });
  });

const exportDbData: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.BACKEND_DATA)).flatMap(() =>
    most
      .fromPromise(api.User.colFormStains())
      .map((response) => {
        FileSaver.saveAs(response.data(), 'col_form_stains.xlsx');
        return voidResult({ data: null });
      })
      .recoverWith((error: Error) => {
        return most.of(voidResult({ error }));
      })
  );

const sendFeedbackEpic: Epic<StateInterface, Action | any> = (action$: most.Stream<Action>) =>
  action$.thru(select(actionTypes.SEND_FEEDBACK)).flatMap((action) => {
    const { message } = action.payload;
    return most
      .fromPromise(api.User.feedback({ body: { message: message } }))
      .tap(() => {
        GtmSendFeedbackEvent();
      })
      .flatMap(() => {
        return most.from([notificationShow({ message: 'Thank you for your feedback', status: 'success' })]);
      })
      .recoverWith((error: Error) => {
        return most.of(sendFeedbackResult({ error }));
      });
  });

const rootEpic = combineEpics([
  checkAuthEpic,
  authorizeEpic,
  afterAuthEpic,
  logMeEpic,
  userSignOutEpic,
  fetchCollectionsEpic,
  selectCollectionEpic,
  createCollectionEpic,
  updateCollectionEpic,
  closeCollectionEditEpic,
  closeCollectionCreateEpic,
  collectionTogglePinnedEpic,
  deleteCollectionEpic,
  deleteFormulationEpic,
  cloneCollectionEpic,
  sendCollectionEpic,
  fetchFormulationsEpic,
  goToIndexEpic,
  toggleSelectFormulationEpic,
  calculateFormulationEpic,
  calculateStainRemovalEpic,
  calculateSustainabilityEpic,
  calculateEcoLabelEpic,
  updateNewFormulationEpic,
  updateFormulationEpic,
  createFormulationEpic,
  cloneFormulationEpic,
  exportExcelEpic,
  responseCurveEpic,
  getStainGroupsEpic,
  getIngredientsEpic,
  getBlendsEpic,
  getRevisionEpic,
  getRegionsEpic,
  getOpportunitiesEpic,
  getStainsRegionEpic,
  createCustomStainGroupEpic,
  getStainsCustomGroupsEpic,
  setStainsGroupEpic,
  deleteCustomStainGroupEpic,
  exportDbData,
  sendFeedbackEpic,
]);

export default rootEpic;
