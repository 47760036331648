import { ReactNode } from 'react';
import styled from 'styled-components';

export type layoutType = 'vertical' | 'mansonary' | 'horizontal';

export interface ButtonLayoutProps {
  layout: layoutType;
  children?: Array<ReactNode>;
  spacing: number;
  wrap?: boolean;
  alignItems?: string;
}

const ChildrenContainer = styled.div<{
  layout: layoutType;
  spacing: number;
  $wrap: boolean;
  alignItems?: string;
}>`
  display: flex;
  flex-direction: ${(props: any) => (props.layout === 'vertical' ? 'column' : 'row')};
  flex-wrap: ${(props: any) => (props.$wrap ? 'wrap' : 'nowrap')};
  align-items: ${(props: any) => {
    switch (props.layout) {
      case 'horizontal':
        return props.alignItems ? props.alignItems : 'stretch';
      default:
        return props.alignItems ? props.alignItems : 'flex-start';
    }
  }};
  width: 100%;
  gap: ${(props: any) => `${props.spacing}px`};
`;
export function ButtonLayout(props: ButtonLayoutProps) {
  return (
    <ChildrenContainer
      layout={props.layout}
      spacing={props.spacing}
      $wrap={props.wrap ? true : false}
      alignItems={props.alignItems ? props.alignItems : undefined}
    >
      {props.children && props.children}
    </ChildrenContainer>
  );
}

ButtonLayout.defaultProps = {
  layout: 'mansonary',
  variant: 'card',
  spacing: '8',
  wrap: true,
};

export default ButtonLayout;
