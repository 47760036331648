import React, { MouseEvent, useMemo, useState } from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentCollectionId,
  getFormulations,
  getFormulationsColor,
  getSelectedBaselineId,
  getSelectedFormulations,
} from '@novozymes-digital/laundry-lab/store/selectors';
import { Formulation } from '@novozymes-digital/laundry-lab/store/types';
import {
  setBaseline,
  toggleSelectFormulation,
  deleteFormulation,
  openEditFormulation,
  cloneFormulation,
} from '@novozymes-digital/laundry-lab/store/actions/actions';
import { Link } from '@mui/material';
import SimpleMenu from './SimpleMenu';
import Divider from '@mui/material/Divider';
import NewDialog from '@novozymes-digital/laundry-lab/components/UI/NewDialog';

const StyledFormControl = styled(FormControl)(() => ({
  margin: 0,
  flex: 1,
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  flex: 1,
  width: '100%!important',
  '& .MuiFormControlLabel-label': {
    width: '100%!important',
  },
}));

const StyledFormLabel = styled('span')(() => ({
  marginRight: 'auto',
  fontSize: '14px',
}));

const StyledFormLabelDate = styled('div')(() => ({
  fontSize: '12px',
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: 'underline',
}));

const StyledGrid = styled(Grid)(() => ({
  paddingLeft: '20px',
  paddingRight: '20px',
  paddingTop: '20px',
  display: 'grid',
}));
interface FormulationListParams {
  maxFormulations: number;
  formulations: Formulation[];
}

const FormulationList: React.FC<FormulationListParams> = ({ maxFormulations, formulations }: FormulationListParams) => {
  const dispatch = useDispatch();
  const [isOpenDeleteConfimationDialog, setIsOpenDeleteConfimationDialog] = useState(false);
  const [selectedFormulation, setSelectedFormulation] = useState<Formulation | undefined>(undefined);

  const selectedFormulations = useSelector(getSelectedFormulations);
  const formulationsColor = useSelector(getFormulationsColor);
  const currentBaseline = useSelector(getSelectedBaselineId);
  const currentCollectionId = useSelector(getCurrentCollectionId);
  const modelFormulations: Formulation[] = formulations?.filter((f) => f.type === 'modelFormulation');

  const handleMenuAction = (action: string, formulation?: Formulation) => {
    switch (action) {
      case 'delete':
        setIsOpenDeleteConfimationDialog(true);
        break;
      case 'edit':
        if (!formulation) break;
        // eslint-disable-next-line no-case-declarations
        const editFormulationIsSelected = selectedFormulations.includes(formulation.id);
        dispatch(toggleSelectFormulation({ formulationId: formulation.id, isSelected: true }));
        dispatch(openEditFormulation({ formulationId: formulation.id, isSelected: editFormulationIsSelected }));
        break;
      case 'clone':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        dispatch(cloneFormulation({ formulationId: formulation?.id, collectionId: currentCollectionId }));
        break;
      default:
        break;
    }
  };

  const handleCloneAction = (action: string, formulation?: Formulation) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    dispatch(cloneFormulation({ formulationId: formulation?.id, collectionId: currentCollectionId }));
  };

  const handleDeleteFormulation = () => {
    if (!selectedFormulation || !currentCollectionId) return;
    dispatch(deleteFormulation({ formulationId: selectedFormulation.id, collectionId: currentCollectionId }));
    setSelectedFormulation(undefined);
    setIsOpenDeleteConfimationDialog(false);
  };

  const sortedFormulations = useMemo(
    () =>
      formulations.sort((a, b) => {
        // Sort by creation-update date
        if (a.sort_order && b.sort_order && a.sort_order < b.sort_order) {
          return -1;
        } else {
          return 1;
        }
      }),
    [formulations]
  );

  const handleChange = (id: string, isSelected: boolean) => {
    if ((isSelected && currentBaseline === id) || selectedFormulations?.length === 1) {
      handleSetBaseline('');
    } // Remove formula as baseline when uncheck
    dispatch(toggleSelectFormulation({ formulationId: id, isSelected }));
  };

  const handleSetBaseline = (id: string) => {
    dispatch(setBaseline({ formulationId: id }));
  };

  return (
    <>
      <NewDialog
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        onClose={() => setIsOpenDeleteConfimationDialog(false)}
        onConfirm={() => handleDeleteFormulation()}
        isOpen={isOpenDeleteConfimationDialog}
      >
        <Typography>{`You are about to delete ${selectedFormulation?.name}`}</Typography>
      </NewDialog>

      <StyledFormControl required>
        <FormGroup>
          <Grid container direction="column">
            {sortedFormulations.map((formulation, index) => {
              const isSelected = selectedFormulations.includes(formulation.id);
              const isBaseline = formulation.id === currentBaseline;
              return (
                <Grid item key={index}>
                  <Grid container alignItems="center" spacing={0}>
                    <Grid item xs={11}>
                      <StyledFormControlLabel
                        data-cy={formulation.name}
                        id={`formulation-${formulation.id}`}
                        key={formulation.id}
                        control={
                          <Checkbox
                            key={`select-formulation-${formulation.id}`}
                            checked={isSelected}
                            style={{
                              color: formulationsColor.find((f) => f.currentFormId === formulation.id)?.color || '',
                            }}
                            color="primary"
                            onChange={() => handleChange(formulation.id, isSelected)}
                            name={formulation.id}
                          />
                        }
                        label={
                          <StyledFormLabel>
                            {formulation.name}
                            {isSelected && selectedFormulations?.length > 1 && (
                              <StyledFormLabelDate>
                                {isBaseline ? (
                                  <strong
                                    onClick={(e: MouseEvent) => {
                                      e?.preventDefault();
                                      handleSetBaseline('');
                                    }}
                                  >
                                    Baseline
                                  </strong>
                                ) : (
                                  <StyledLink
                                    id={`select-baseline-${formulation.id}`}
                                    color="secondary"
                                    onClick={(e: MouseEvent) => {
                                      e?.preventDefault();
                                      handleSetBaseline(formulation.id);
                                    }}
                                  >
                                    Use as baseline
                                  </StyledLink>
                                )}
                              </StyledFormLabelDate>
                            )}
                          </StyledFormLabel>
                        }
                        disabled={selectedFormulations.length >= maxFormulations && !isSelected}
                      />
                    </Grid>
                    <Grid data-cy={'formulation-menu-' + formulation.name} item xs={1} style={{ padding: '' }}>
                      {formulation.type === 'userFormulation' && (
                        <SimpleMenu
                          formulation={formulation}
                          currentBaselineId={currentBaseline}
                          onClickAction={(action: any) => {
                            setSelectedFormulation(formulation);
                            handleMenuAction(action, formulation);
                          }}
                        />
                      )}
                      {formulation.type === 'modelFormulation' && (
                        <SimpleMenu
                          formulation={formulation}
                          currentBaselineId={currentBaseline}
                          onClickAction={(action: any) => {
                            setSelectedFormulation(formulation);
                            handleCloneAction(action, formulation);
                          }}
                          options={{
                            edit: false,
                            delete: false,
                            clone: true,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {modelFormulations.length === index + 1 && <Divider variant="middle" />}
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </StyledFormControl>
    </>
  );
};

const FormulationSelection: React.FC = () => {
  const formulations = useSelector(getFormulations);
  const MAX_NFORMULATIONS = 5;

  const userFormulationIsEmpty = Object.entries(formulations).length === 0;

  const renderFormulationIsEmpty = () => {
    return <Typography>You don&apos;t have saved formulations.</Typography>;
  };

  return (
    <Grid container alignContent="center">
      <StyledGrid item sm={12}>
        <Typography variant="subtitle1">Select formulations to compare - max {MAX_NFORMULATIONS}</Typography>
        {userFormulationIsEmpty ? (
          renderFormulationIsEmpty()
        ) : (
          <FormulationList formulations={formulations} maxFormulations={MAX_NFORMULATIONS} />
        )}
      </StyledGrid>
    </Grid>
  );
};

export default FormulationSelection;
