import Dialog from '@mui/material/Dialog';
import { DialogActions, Button, DialogContent, DialogContentText, DialogTitle, styled } from '@mui/material';
import { ReactNode } from 'react';

export interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  cancelButtonText: string;
  confirmButtonText: string;
  onConfirm: () => void;
  confirmButtonEnabled?: boolean;
  children: ReactNode;
}

export const StyledDialog = styled(Dialog)({
  //   '& .MuiDialog-paper': {
  minWidth: '600px',
  //   },
});

export function NewDialog({
  isOpen,
  onClose,
  children,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
  confirmButtonEnabled = true,
}: DialogProps) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle id="responsive-dialog-title"></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>{children}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirm} variant="contained" disabled={!confirmButtonEnabled} autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewDialog;
