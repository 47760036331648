import React from 'react';
import { Grid, Paper, Button, styled } from '@mui/material';
import { IconArrowLeft } from '@novozymes-digital/dezymer-icons';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  maxWidth: '920px',
  minHeight: '600px',
  margin: '20px',
  padding: '48px',
  flexGrow: 1,

  [theme.breakpoints.up('md')]: {
    maxWidth: '80%',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  paddingLeft: 0,

  [theme.breakpoints.up('lg')]: {
    position: 'absolute',
    right: 'calc(100% + 20px)',
    top: '44px',
    paddingLeft: '8px',
    paddingRight: '16px',
  },
}));

const StyledArrowBack = styled(IconArrowLeft)(() => ({
  marginRight: '8px',
}));

interface Props {
  onBack?: () => void;
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children, onBack }: Props) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <StyledPaper>
        {!!onBack && (
          <StyledButton variant="text" onClick={onBack}>
            <StyledArrowBack /> Back
          </StyledButton>
        )}
        {children}
      </StyledPaper>
    </Grid>
  );
};

export default Layout;
