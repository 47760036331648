import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(() => ({
  width: '14px',
  height: '14px',
  marginRight: '12px',
}));

export default function SendIcon(): JSX.Element {
  return (
    <StyledGrid container justifyContent="center" alignItems="center">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          d="M13.4147 1.11845C13.404 0.985163 13.3477 0.854847 13.2457 0.752886C13.0786 0.58578 12.8354 0.541254 12.6277 0.619307L0.973884 4.69813C0.748134 4.77714 0.59349 4.98596 0.58374 5.22494C0.57399 5.46391 0.71111 5.68463 0.929674 5.78177L5.97465 8.02398L8.21686 13.069C8.314 13.2875 8.53472 13.4246 8.7737 13.4149C9.01268 13.4051 9.22149 13.2505 9.3005 13.0248L13.3797 1.36984C13.4098 1.2892 13.4215 1.20322 13.4147 1.11845ZM10.6132 2.56042L2.74805 5.31323L6.28739 6.88627L10.6132 2.56042ZM7.11235 7.71122L11.4382 3.38533L8.6854 11.2506L7.11235 7.71122Z"
          fill="black"
        />
      </svg>
    </StyledGrid>
  );
}
