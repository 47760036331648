import React from 'react';
import clsx from 'clsx';

import {
  TableContainer,
  Table,
  TableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody,
  Grid,
  Box,
  styled,
} from '@mui/material';

import { colors } from './Chart';

const StyledBox = styled(Box)(() => ({
  width: '12px',
  height: '28px',
}));

const StyledBoxBaseline = styled(StyledBox)(() => ({
  position: 'relative',

  '&:after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    top: '1px',
    bottom: '1px',
    left: '5px',
    width: '2px',
    background: 'var(--nz-black-80)',
  },
}));

export const TableCell = styled(MuiTableCell)(() => ({
  borderBottom: 'none',
}));

const StyledTableCellLabelText = styled(TableCell)(() => ({
  fontWeight: 400,
  lineHeight: '16px',
}));

export const TableRow = styled(MuiTableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'var(--nz-bg-2)',
  },
}));

const StyledLabel = styled(Grid)(() => ({
  fontWeight: 400,
  lineHeight: '16px',
}));

const StyledCostBreackdownLabel = styled(Grid)(() => ({
  fontWeight: 500,
  lineHeight: '16px',
}));

interface Props {
  labels: Array<{ label: string; isBaseline: boolean; customColor?: string }>;
  children?: React.ReactNode;
  labelsOffset?: number;
  noLabelColors?: boolean;
  labelsOffsetText?: string[] | undefined;
}

const TableLayout: React.FunctionComponent<Props> = ({
  labels,
  labelsOffset,
  children,
  noLabelColors,
  labelsOffsetText,
}: Props) => {
  const emptyCells = labelsOffsetText || Array(labelsOffset).fill('', 0, labelsOffset);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {emptyCells.map((val, i) => (
              <StyledTableCellLabelText key={i}>{val}</StyledTableCellLabelText>
            ))}
            {labels.map(({ label, isBaseline, customColor }, index) =>
              label ? (
                <TableCell key={index}>
                  <Grid container alignItems="center" justifyContent="flex-start" spacing={1} wrap="nowrap">
                    {!noLabelColors && (
                      <Grid item>
                        {isBaseline ? (
                          <StyledBoxBaseline className={clsx()} borderRadius={8} />
                        ) : (
                          <StyledBox bgcolor={customColor || colors[index % 7][0]} borderRadius={8} />
                        )}
                      </Grid>
                    )}
                    {noLabelColors ? (
                      <StyledCostBreackdownLabel> {label} </StyledCostBreackdownLabel>
                    ) : (
                      <StyledLabel> {label} </StyledLabel>
                    )}
                  </Grid>
                </TableCell>
              ) : (
                <>
                  <TableCell />
                </>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableLayout;
