export default function CheckboxChecked(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="white" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="black" strokeOpacity="0.8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3528 4.65845C12.2592 4.55703 12.1318 4.5 11.999 4.5C11.8661 4.5 11.7387 4.55703 11.6451 4.65845L6.49822 10.2005L4.35438 7.88766C4.15893 7.6772 3.84204 7.6772 3.64659 7.88766C3.45114 8.09812 3.45114 8.43935 3.64659 8.64981L6.14637 11.3415C6.24005 11.443 6.36741 11.5 6.50026 11.5C6.63311 11.5 6.76048 11.443 6.85416 11.3415L12.3528 5.4206C12.447 5.31972 12.5 5.18257 12.5 5.03952C12.5 4.89647 12.447 4.75933 12.3528 4.65845Z"
        fill="black"
        fillOpacity="0.8"
      />
    </svg>
  );
}
