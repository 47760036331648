import React from 'react';
import { SignIn } from '../Auth/SignIn';
import { ResetPassword } from './ResetPassword';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/material';

const StyledBox = styled(Box)(() => ({
  flexGrow: 1,
  width: '500px',
  minHeight: '500px',
  backgroundColor: 'var(--nz-bg-1)',
  boxSizing: 'border-box',
}));

const StyledInsideBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  width: '400px',
  margin: 'auto',
  height: '100%',
  paddingTop: '30px',
}));

export const AuthTabs: React.FC = () => {
  const [authTab, setAuthTab] = React.useState('SignIn');

  const handleChange = (newValue: string) => {
    setAuthTab(newValue);
  };

  return (
    <StyledBox boxShadow={0}>
      <StyledInsideBox>
        {authTab === 'SignIn' && <Typography variant="h3">Sign in</Typography>}
        {authTab === 'SignIn' && <SignIn onClickResetPassword={() => handleChange('ResetPassword')} />}
        {authTab === 'ResetPassword' && <Typography variant="h3">Reset password</Typography>}
        {authTab === 'ResetPassword' && <ResetPassword onCancel={() => handleChange('SignIn')} />}
      </StyledInsideBox>
    </StyledBox>
  );
};
