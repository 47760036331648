export const SURFACTANTS_LABEL = 'Surfactants';
export const ENZYMES_LABEL = 'Enzymes';
export const BUILDERS_LABEL = 'Builders';
export const FILLERS_LABEL = 'Fillers';
export const OTHERS_LABEL = 'Others';

export const LAS_INGREDIENT_LABEL = 'LAS';
export const AES_INGREDIENT_LABEL = 'AES/SLES';
export const AEO_INGREDIENT_LABEL = 'AEO';
export const PROTEASE_INGREDIENT_LABEL = 'Protease';
export const AMYLASE_INGREDIENT_LABEL = 'Amylase';
export const LIPASE_INGREDIENT_LABEL = 'Lipase';
export const MANNANASE_INGREDIENT_LABEL = 'Mannanase';
export const PECTATE_LYASE_INGREDIENT_LABEL = 'Pectate Lyase';
export const CELLULASE_INGREDIENT_LABEL = 'Cellulase';
export const SODA_ASH_INGREDIENT_LABEL = 'Soda Ash';
export const SILICATE_INGREDIENT_LABEL = 'Silicate';
export const SODIUM_SULFATE_INGREDIENT_LABEL = 'Sodium Sulfate';
export const SODIUM_CHLORIDE_INGREDIENT_LABEL = 'Sodium Chloride';
export const DOLOMITE_INGREDIENT_LABEL = 'Dolomite';

export const MEDLEY_CORE_200L_INGREDIENT_LABEL = 'Medley Core 200 L';
export const MEDLEY_BOOST_300L_INGREDIENT_LABEL = 'Medley Boost 300 L';
export const MEDLEY_FLEX_300L_INGREDIENT_LABEL = 'Medley Flex 300 L';
export const MEDLEY_BRIGHT_200L_INGREDIENT_LABEL = 'Medley Bright 200 L';
export const MEDLEY_ADVANCE_300L_INGREDIENT_LABEL = 'Medley Advance 300 L';
export const MEDLEY_ESSENTIAL_200L_INGREDIENT_LABEL = 'Medley Essential 200 L';
export const MEDLEY_SELECT_300L_INGREDIENT_LABEL = 'Medley Select 300 L';
export const MEDLEY_PURE_300L_INGREDIENT_LABEL = 'Medley Pure 300 L';
export const MEDLEY_BRILLIANT_300L_INGREDIENT_LABEL = 'Medley Brilliant 300 L';
export const MEDLEY_OPTIWASH_1750S_INGREDIENT_LABEL = 'Medley Optiwash 1750S';
export const MEDLEY_OPTIWASH_3500S_INGREDIENT_LABEL = 'Medley Optiwash 3500S';
export const MEDLEY_OPTIWASH_400S_INGREDIENT_LABEL = 'Medley Optiwash 400S+';
export const MEDLEY_ADVANCE_200T_INGREDIENT_LABEL = 'Medley Advance 200T';
export const MEDLEY_BRILLIANT_400L_INGREDIENT_LABEL = 'Medley Brilliant 400 L';

export const CITRATE_INGREDIENT_LABEL = 'Citrate';
export const SOAP_INGREDIENT_LABEL = 'Soap';
export const FALLBACK_COLOR = '#292929';

export const CostBreakdownColors: Record<string, string> = {
  [SURFACTANTS_LABEL]: '#3E442B',
  [ENZYMES_LABEL]: '#8D909B',
  [BUILDERS_LABEL]: '#C9D5B5',
  [FILLERS_LABEL]: '#E3DBDB',
  [OTHERS_LABEL]: '#D6EEFF',
  [LAS_INGREDIENT_LABEL]: '#547AA5',
  [AES_INGREDIENT_LABEL]: '#50D8D7',
  [AEO_INGREDIENT_LABEL]: '#104F55',
  [PROTEASE_INGREDIENT_LABEL]: '#32746D',
  [AMYLASE_INGREDIENT_LABEL]: '#9EC5AB',
  [LIPASE_INGREDIENT_LABEL]: '#01200F',
  [MANNANASE_INGREDIENT_LABEL]: '#53A2BE',
  [CITRATE_INGREDIENT_LABEL]: '#176087',
  [SOAP_INGREDIENT_LABEL]: '#4C0827',
  [PECTATE_LYASE_INGREDIENT_LABEL]: '#99E1D9',
  [CELLULASE_INGREDIENT_LABEL]: '#5D576B',
  [SODA_ASH_INGREDIENT_LABEL]: '#136F63',
  [SILICATE_INGREDIENT_LABEL]: '#3E2F5B',
  [SODIUM_SULFATE_INGREDIENT_LABEL]: '#8A84E2',
  [SODIUM_CHLORIDE_INGREDIENT_LABEL]: '#AFAFDC',
  [DOLOMITE_INGREDIENT_LABEL]: '#B7D3F2',

  [MEDLEY_CORE_200L_INGREDIENT_LABEL]: '#0D0630',
  [MEDLEY_BOOST_300L_INGREDIENT_LABEL]: '#E6F9AF',
  [MEDLEY_FLEX_300L_INGREDIENT_LABEL]: '#9AB87A',
  [MEDLEY_BRIGHT_200L_INGREDIENT_LABEL]: '#A98743',
  [MEDLEY_ADVANCE_300L_INGREDIENT_LABEL]: '#F7C548',
  [MEDLEY_ESSENTIAL_200L_INGREDIENT_LABEL]: '#6A2E35',
  [MEDLEY_SELECT_300L_INGREDIENT_LABEL]: '#B7245C',
  [MEDLEY_PURE_300L_INGREDIENT_LABEL]: '#7C3238',
  [MEDLEY_BRILLIANT_300L_INGREDIENT_LABEL]: '#FCF6B1',
  [MEDLEY_OPTIWASH_1750S_INGREDIENT_LABEL]: '#eccbd9',
  [MEDLEY_OPTIWASH_3500S_INGREDIENT_LABEL]: '#e1eff6',
  [MEDLEY_OPTIWASH_400S_INGREDIENT_LABEL]: '#97d2fb',
  [MEDLEY_ADVANCE_200T_INGREDIENT_LABEL]: '#80ffe8',
  [MEDLEY_BRILLIANT_400L_INGREDIENT_LABEL]: '#FF9732',

  LAST_COL: 'rgb(179, 226, 184, 0.24)',
};
