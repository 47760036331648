export default function CollectionPinEmpty(): JSX.Element {
  return (
    <svg width="147" height="196" viewBox="0 0 147 196" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          opacity="0.4"
          d="M146.964 125.179C146.964 127.608 146 129.938 144.284 131.656C142.568 133.374 140.241 134.339 137.814 134.339H85.9627L85.9627 79.3819C85.9627 77.7624 85.32 76.2093 84.176 75.0641C83.032 73.919 81.4804 73.2756 79.8625 73.2756H67.6622C66.0444 73.2756 64.4928 73.919 63.3488 75.0641C62.2048 76.2093 61.5621 77.7624 61.5621 79.3819V134.339H9.71077C7.28398 134.339 4.95659 133.374 3.24059 131.656C1.52459 129.938 0.560547 127.608 0.560547 125.179C0.560547 105.815 14.8463 90.3809 33.3489 81.7748L38.004 36.6378H21.9111C19.4843 36.6378 17.1569 35.6728 15.4409 33.9551C13.7249 32.2373 12.7608 29.9076 12.7608 27.4784L12.7608 9.15945C12.7608 6.73021 13.7249 4.40047 15.4409 2.68274C17.1569 0.965011 19.4843 0 21.9111 0L125.614 0C128.04 0 130.368 0.965011 132.084 2.68274C133.8 4.40047 134.764 6.73021 134.764 9.15945V27.4784C134.764 29.9076 133.8 32.2373 132.084 33.9551C130.368 35.6728 128.04 36.6378 125.614 36.6378H109.521L114.176 81.7748C132.476 90.2893 146.964 105.647 146.964 125.179Z"
          fill="#C5DA00"
        />
        <path
          d="M85.9627 79.3817V174.029C85.9628 174.502 85.8532 174.969 85.6425 175.392L76.4922 193.711C76.2393 194.219 75.8498 194.646 75.3676 194.945C74.8854 195.244 74.3295 195.402 73.7624 195.402C73.1953 195.402 72.6395 195.244 72.1573 194.945C71.675 194.646 71.2856 194.219 71.0326 193.711L61.8824 175.392C61.6717 174.969 61.562 174.502 61.5621 174.029V79.3817C61.5621 77.7622 62.2048 76.209 63.3488 75.0639C64.4928 73.9187 66.0444 73.2754 67.6623 73.2754H79.8626C81.4804 73.2754 83.032 73.9187 84.176 75.0639C85.32 76.209 85.9627 77.7622 85.9627 79.3817Z"
          fill="#C5DA00"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="146.404" height="195.402" fill="white" transform="translate(0.560547)" />
        </clipPath>
      </defs>
    </svg>
  );
}
